@import '@/styles/mixins';
@import '@/styles/variables';


.BroadcastHeader {
    height: 90px;

    @include desktop {
        @include content-box($theme: 'dark', $radius: 40px, $padding: 6px);
    }
    
    @include mobile {
        height: 78px;
        padding: 4px;
    }
    
    @include tablet {
        @include content-box($theme: 'dark', $radius: 40px, $padding: 6px);
        height: 78px;
        padding: 4px;
    }
}

.BroadcastHeader__Swiper {
    & .swiper-wrapper {
        padding: 4px;
        width: max-content;
        border-radius: 32px;
        background-image: $box-gradient-black;
        box-shadow: inset 0px 0px 10px #ffffff1f;
    }
}

.BroadcastHeader__Custom-Slide {
    height: auto;
}

.BroadcastHeader__Inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    width: 100%;
    height: 100%;
}

.BroadcastHeader__Tooltip {
    flex-grow: 1;
    height: 100%;

    @include mobile {
        flex-shrink: 0;
    }
}

.BroadcastHeader__Btn {
    height: 100%;
    box-shadow: unset;
    background: unset;
    padding: 6px 12px;
    align-items: flex-start;

    @include mobile {
        max-width: 230px;
    }

    &--active {
        background-image: $box-gradient-green;
    }

    &:hover {
        box-shadow: unset !important;
    }
}

.BroadcastHeader__Title-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.BroadcastHeader__Subtitle {
    font-size: 16px;
    color: $text-gray;
    line-height: 16px;

    .BroadcastHeader__Btn--active &,
    .BroadcastHeader__Btn:hover & {
        opacity: 0.8;
        color: $text-white;
    }

    @include mobile {
        font-size: 14px;
        line-height: 18px;
    }

    @include tablet {
        font-size: 14px;
        line-height: 16px;
    }
}

.BroadcastHeader__Title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 21px;

    @include mobile {
        font-size: 14px;
        line-height: 18px;
    }

    @include tablet {
        font-size: 14px;
        line-height: 18px;
    }
}

.BroadcastHeader__Custom-Slide {
    flex-grow: 1;

    @include mobile {
        width: fit-content !important;
    }

    &:last-of-type {
        margin-right: unset !important;
    }
}