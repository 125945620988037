@import '@/styles/mixins';
@import '@/styles/variables';

.AccommodationChoice__Inputs {
    display: flex;
    gap: 12px;
    margin: 8px auto 0px auto;
}

.AccommodationChoice__Option {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 122px;
    height: 40px;
    border-radius: 12px;
    color: $text-white;
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
    background-clip: padding-box;
    border: 3px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;

    &>input[type='radio'] {
        appearance: none;
    }

    &::before {
        content: "";
        position: absolute;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
        border-radius: inherit;
        background-color: transparent;
        z-index: -1;
    }

    &--active {
        border-color: transparent;
        background-color: #080808;

        &::before {
            background-image: $box-gradient-green;
        }
    }
}

.AccommodationChoice__Note {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 1;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    color: $text-gray;
    text-align: center;
    white-space: break-spaces;

    @include mobile {
        font-size: 12px;
    }
}

.AccommodationChoice__Tooltip {
    white-space: break-spaces;
    text-align: center;
    margin-top: auto;
}

.AccommodationChoice__Btn {
    height: 64px;

    @include mobile {
        height: 56px;
    }
}