@import '@/styles/mixins';
@import '@/styles/variables';

.CookieNotificator {
  position: fixed;
  right: 24px;
  bottom: 24px;
  background-color: rgba(8, 8, 8, 0.94);
  padding: 20px;
  border-radius: 20px;
  color: #fff;
  max-width: 405px;
  z-index: 100;

  @include mobile {
    right: unset;
    left: 8px;
    bottom: 8px;
    width: calc(100% - 16px);
    z-index: 10;
  }
}

.CookieNotificator__Title {
  font-family: $ff-semiBold;
  font-size: 20px;
  line-height: 26px;
  margin: 0 0 8px;
}

.CookieNotificator__Text,
.CookieNotificator__Link {
  font-family: $ff-regular;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
}

.CookieNotificator__Text {
  opacity: .6;
  margin-bottom: 20px;
}