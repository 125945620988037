@import '@/styles/mixins';
@import '@/styles/variables';

.DropdownMenu {
    width: 303px;
    padding: 14px 20px;
    border-radius: 20px;
    position: absolute;
    top: calc(100% + 25px);
    right: 0;
    background-color: rgba(8, 8, 8, 0.94);
    z-index: 3;
}

.DropdownMenu__Inner {
    display: flex;
    flex-direction: column;
}

.DropdownMenu__Item {
    display: flex;
    user-select: none;

    &:last-child {
        border-top: 1px solid rgba(217, 217, 217, .12)
    }
}

.DropdownMenu__Link {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    font-family: $ff-semiBold;
    text-transform: uppercase;
    color: $text-white;
    text-decoration: none;
    white-space: nowrap;
    opacity: 0.4;

    &.active {
        opacity: 1;
    }
}