@import '@/styles/mixins';
@import '@/styles/variables';

.UserBlocked {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 390px;

    @include mobile {
        width: 100%;
    }
}

.UserBlocked__Title {
    font-size: 24px;
    font-family: $ff-semiBold;
}

.UserBlocked__Text {
    font-size: 14px;
    line-height: 20px;
    color: $text-gray;
    text-align: center;
}

.UserBlocked__Icon-container {
    position: relative;
    width: 80px;
    height: 80px;
    margin-bottom: 30px;
    z-index: 1;
}

.UserBlocked__Icon-shadow,
.UserBlocked__Icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.UserBlocked__Icon-shadow {
    width: 200px;
    z-index: -1;
}