@import '@/styles/mixins';
@import '@/styles/variables';

.WarningMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    @include mobile {
        gap: 52px;
    }
}

.WarningMessage__Header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
}

.WarningMessage__Title {
    font-size: 24px;
    font-family: $ff-semiBold;
}

.WarningMessage__Text {
    font-size: 14px;
    line-height: 20px;
}

.WarningMessage__Btns {
    display: flex;
    align-items: baseline;
    gap: 36px;

    @include mobile {
        flex-direction: column;
        align-items: center;
        gap: 12px;
        width: 100%;
    }
}

.WarningMessage__Cancel {
    border: unset;
    outline: unset;
    background-color: unset;
    padding-bottom: 2px;
    border-bottom: 1px solid $gray-3;

    @include mobile {
        padding: unset;
        border: unset;
    }
}

.WarningMessage__Delete {
    height: 60px;
    padding: 16px 36px;

    @include mobile {
        height: 56px;
        width: 100%;
        padding: 14px 40px;
        order: -1;
    }
}