@import '@/styles/mixins';
@import '@/styles/variables';

.MessageCard {
    display: flex;
    align-items: flex-end;
    gap: 6px;
    @include fadeIn-animation(0.5s);

    &--current-user {
        justify-content: flex-end;
    }

    &--active {
        z-index: 2;
    }
}

.MessageCard__Moderation {
    display: flex;
    align-self: flex-end;
    padding-right: 48px;
    width: 280px;
}

.MessageCard__Main {
    display: flex;
    align-items: flex-end;
    gap: 6px;

    .MessageCard--current-user & {
        justify-content: flex-end;
    }
}

.MessageCard__Avatar {
    width: 28px;
    height: 28px;
    flex-shrink: 0;

    & .Avatar__Initial {
        font-size: 18px;
    }

    .MessageCard--current-user & {
        order: 2;
    }
}

.MessageCard__Body {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px;
    width: fit-content;
    min-width: 150px;
    border-radius: 16px;
    background-color: #F7F7F7;


    .MessageCard--current-user & {
        background-image: linear-gradient(to right, rgba(38, 189, 237, 0.17) 0%, rgba(49, 222, 182, 0.17) 48.78%, rgba(0, 235, 94, 0.17) 130%);
    }
}

.MessageCard__Text {
    font-size: 14px;
    color: $text-black;
    white-space: break-spaces;
    word-break: break-all;
}

.MessageCard__Tooltip {
    cursor: pointer;

    &::before {
        font-size: 14px;
    }

    &--right {
        &>.Tooltip__Body::after {
            right: calc(100% - 6px);
        }
    }

    &--left {
        &>.Tooltip__Body::after {
            left: calc(100% - 6px);
        }
    }
}

.MessageCard__Controls {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: fit-content;
    gap: 4px;
    
    .MessageCard--current-user & {
        width: unset;
        order: -1;
    }
}

.MessageCard__Controls-btn {
    display: none;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: unset;
    outline: none;
    background-image: $box-gradient-green;
    cursor: pointer;

    .MessageCard:hover & {
        display: flex;
    }

    &--gray {
        background: #B4B4B4;
    }

    &:hover {
        opacity: 0.7;
    }
}

.MessageCard__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.MessageCard__Delete {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    display: flex;
    align-items: center;
    border-radius: 12px;
    width: 150px;
    height: 33px;
    overflow: hidden;
}

.MessageCard__Delete-btn {
    padding: 8px 10px 8px 12px;
    height: 100%;
    font-size: 14px;
    flex-grow: 1;
    border: unset;
    outline: unset;
    background: unset;
    background-color: $white;
    cursor: pointer;
    user-select: none;

    &--delete {
        padding: 8px 12px 8px 10px;
        color: #F4454A;
    }

    &:first-of-type {
        border-right: 1px solid #F0F0F0;
    }

    &:hover {
        background-color: rgba(244, 244, 244, 0.85);
    }
}

.MessageCard__Icon {
    flex-shrink: 0;
}

.MessageCard__Popup {
    &>.Popup__Box {
        width: 428px;
        height: 300px;
        border-radius: 32px;
        padding: 70px 55px;

        @include mobile {
            width: 100%;
            height: fit-content;
            border-radius: 24px 24px 0 0;
            padding: 80px 24px 24px 24px;
        }
    }
}

