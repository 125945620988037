@import '@/styles/mixins';

.Share {
  position: relative;
  padding: 60px 0 100px;

  @include mobile {
    padding: 0px 0 64px;
  }

  @include tablet {
    padding: 0px 0 100px;
  }
}

.Share__Headline {
  margin: 0 0 60px;
}

.Share__Items {
  display: flex;
  flex-wrap: wrap;
  max-width: 832px;
  margin-left: auto;
  margin-right: auto;

  @include mobile {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(95px, 1fr));
    gap: 12px;
  }

  @include tablet {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 32px;
    max-width: 603px;
  }
}

.Share__Item {
  width: calc(100% / 3);
  padding: 16px;
  position: relative;
  z-index: 1;

  @include mobile {
    width: unset;
    padding: unset;
  }

  @include tablet {
    width: unset;
    padding: unset;
  }
}

.Share__Container {
  position: relative;
}

.Share__Decoration1,
.Share__Decoration2,
.Share__Decoration3,
.Share__Decoration4 {
  position: absolute;
  pointer-events: none;
  z-index: 2;
}

.Share__Decoration1 {
  left: calc(50vw - 605px);
  top: -34px;

  @include mobile {
    width: 120px;
    left: -24px;
    top: 40px;
    rotate: 357deg;
  }

  @include tablet {
    width: 200px;
    left: -24px;
    top: 40px;
    rotate: 357deg;
  }
}

.Share__Decoration2 {
  left: calc(50vw + 225px);
  top: 60px;

  @include mobile {
    left: unset;
    width: 125px;
    right: -45px;
    top: 120px;
  }

  @include tablet {
    left: unset;
    width: 215px;
    right: -40px;
    top: 105px;
  }
}

.Share__Decoration3 {
  bottom: 0;
  right: 0;

  @include mobile {
    display: none;
  }

  @include tablet {
    display: none;
  }
}

.Share__Decoration4 {
  bottom: 0;
  left: 0;

  @include mobile {
    display: none;
  }

  @include tablet {
    display: none;
  }
}

.Share__Title {
  opacity: 0;
  will-change: opacity;
  transition: 1s opacity;

  &.Animate {
    opacity: 1;
  }
}