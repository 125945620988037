@mixin mobile {
    @media (max-width: 575px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 576px) and (max-width: 991px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin custom-screen($min, $max) {
    @media (min-width: $min) and (max-width: $max) {
        @content;
    }
}

@mixin layer($bg-Color) {
    background-color: $bg-Color;
    border-radius: 48px;
    box-shadow: 0 0 64px 0 hsla(0, 0%, 100%, .24);
    margin-left: 12px;
    margin-right: 12px;
    overflow: hidden;

    @include mobile {
        border-radius: 32px;
    }
}

@mixin text-colored($color) {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: $color;
}

@mixin gradient-text-bg {
    content: "";
    display: inline-block;
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% - 25px);
    left: -10px;
    top: 25px;
    border-radius: 24px;
    background-image: linear-gradient(133deg, #26bded, #31deb6 48.78%, rgba(0, 235, 94, 0.99));
    box-shadow: inset 0 2px 6px 0 hsla(0, 0%, 100%, 0.6), inset -2px 0 6px 0 hsla(0, 0%, 100%, 0.6);
    transform: rotate(358deg);
    z-index: -1;
}

@mixin content-box($theme: 'dark', $radius: 48px, $padding: 48px) {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $radius;
    padding: $padding;

    @if $theme =='dark' {
        background: radial-gradient(105.47% 105.47% at 70.67% 25.78%, rgba(84, 94, 102, 0) 0%, rgba(84, 94, 102, 0.32) 100%);
        box-shadow: 6px 0px 20px 0px rgba(255, 255, 255, 0.2) inset, 0px 6px 6px 0px rgba(255, 255, 255, 0.02) inset, -6px 0px 6px 0px rgba(255, 255, 255, 0.04) inset, 0px -6px 12px 0px rgba(255, 255, 255, 0.06) inset;
    }

    @else if $theme =='light' {
        background-color: #fff;
        box-shadow: 6px 0px 20px 0px rgba(2, 41, 68, 0.40) inset, 0px 6px 8px 0px rgba(2, 41, 68, 0.08) inset, -6px 0px 8px 0px rgba(2, 41, 68, 0.12) inset, 0px -6px 12px 0px rgba(2, 41, 68, 0.32) inset;
    }

    @else if $theme =='light-green' {
        background: radial-gradient(135.28% 116.82% at 94.25% -18.22%, hsla(0, 0%, 100%, .02) 0, rgba(49, 222, 182, .07) 100%);
        box-shadow: inset 6px 0 24px 0 rgba(49, 222, 182, .12), inset 0 6px 8px 0 rgba(49, 222, 182, .02), inset -6px 0 8px 0 rgba(49, 222, 182, .04), inset 0 -6px 8px 0 rgba(49, 222, 182, .08);
    }

    @else if $theme =='gradient-green' {
        background: linear-gradient(110deg, #26bded, #31deb6 48.78%, rgba(0, 235, 94, .99));
        box-shadow: inset 8px -10px 30px 20px #ffffff52, inset 8px -10px 20px 5px #ffffff52;
    }

    @else if $theme =='light-red' {
        background: radial-gradient(135.28% 116.82% at 94.25% -18.22%, hsla(0, 0%, 100%, .02) 0, rgba(191, 18, 22, .06) 100%);
        box-shadow: inset 6px 0 24px 0 rgba(191, 18, 22, .12), inset 0 6px 8px 0 rgba(191, 18, 22, .02), inset -6px 0 8px 0 rgba(191, 18, 22, .04), inset 0 -6px 8px 0 rgba(191, 18, 22, .08);
    }
}

@mixin fadeIn-animation($duration: 0.5s) {
    animation: fadeIn $duration 1 alternate forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@mixin fadeIn-fromTop-animation($duration: 0.5s) {
    animation: fadeIn-fromTop $duration 1 alternate forwards;
}

@keyframes fadeIn-fromTop {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@mixin fadeIn-fromBottom-animation($duration: 0.5s) {
    animation: fadeIn-fromBottom $duration 1 alternate forwards;
}

@keyframes fadeIn-fromBottom {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@mixin popIn-animation($duration: 0.5s, $delay: 0s) {
    animation: popIn $duration 1 $delay alternate forwards;
}

@keyframes popIn {
    from {
        opacity: 0;
        transform: scale(0.90);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@mixin pulse-animation($duration: 0.5s, $delay: 0s) {
    animation: heart-pulse $duration 1 $delay alternate forwards;
}

@keyframes heart-pulse {
    0% {
        transform: scale(0.9); 
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

@mixin custom-scrollbar {
    scrollbar-color: rgba(8, 8, 8, 0.8) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(8, 8, 8, 0.3);
        border-radius: 50px;
    }
}

@mixin custom-scrollbar-horizontal {
    scrollbar-color: rgb(168 168 168 / 50%) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        height: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgb(168 168 168 / 50%) ;
        border-radius: 50px;
    }
}