@import '@/styles/mixins';

.Divider {
  display: flex;
  justify-content: space-between;
}

.Divider__Circle {
  border: 1px solid #ddd;
  display: inline-flex;
  gap: 41px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  opacity: .2;

  @include mobile {
    width: 12px;
    height: 12px;
  }

  .Divider--dark & {
    background: var(--dark-item-radial, radial-gradient(91.18% 91.18% at 50.00% 47.45%, rgba(2, 27, 47, 0.00) 0%, rgba(2, 27, 47, 0.48) 100%));
  }

  .Divider--light & {
    background: var(--radial-test, radial-gradient(108.33% 108.33% at 50.00% 47.45%, rgba(84, 94, 102, 0.00) 11.98%, rgba(84, 94, 102, 0.48) 100%));
  }
}