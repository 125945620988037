@import '@/styles/mixins';
@import '@/styles/variables';

.MessageInfoIcons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.MessageInfoIcons__Likes {
    display: flex;
    align-items: center;
    gap: 3px;
    width: fit-content;
    border: unset;
    outline: none;
    padding: 4px;
    border-radius: 16px;
    background-color: $white;
    color: $text-green;
    font-size: 12px;
    cursor: pointer;
    transition: 0.1s;

    &--isLiked {
        background-color: $green-0;
        color: $text-white;
    }

    &--disabled {
        cursor: unset;
        pointer-events: none;
    }

    &:active {
        transform: scale(0.9);
    }
}
