@import '@/styles/mixins';
@import '@/styles/variables';


.ActivityInformation__text {
    font-size: 20px;
    font-family: $ff-semiBold;
    margin-bottom: 16px;
}

.ActivityInformation__Checkboxes {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.ActivityInformation__Checkbox {
    color: $text-gray;
    gap: 8px;

    & .Checkbox__Box {
        background-color: $white;

        &::before {
            background-color: #101010;
        }
    }

    & .Checkbox__Description {
        color: $text-white;
    }

    & svg>path {
        fill: $black-1;
    }

    & .Checkbox__Link {
        color: $text-white;
    }
}

.ActivityInformation__inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 52px;
    margin-top: 24px;
}

.ActivityInformation__input {
    @include fadeIn-animation($duration: 0.3s)
}

.ActivityInformation__Tooltip {
    white-space: break-spaces;
    text-align: center;
    margin-top: auto;
}

.ActivityInformation__Btn {
    height: 64px;

    @include mobile {
        height: 56px;
    }
}