@import '@/styles/mixins';
@import '@/styles/variables';

.VipAccount__Header-promo-code,
.VipAccount__Header {
    margin-top: 50px;
    margin-bottom: 20px;
}

.VipAccount__Header-promo-code {
    margin-top: 00px;

    .AuthorizationFormHeader__Logo {
        margin: unset;
    }

    .AuthorizationFormHeader__Title {
        font-size: 27px;

        @include mobile {
            font-size: 22px;
        }
    }

    .AuthorizationFormHeader__Subtitle {
        font-size: 14px;
    }

    .AuthorizationFormHeader__Logo-icon {
        width: 120px;

        &>img {
            width: 100%;
        }
    }
}

.VipAccount__Form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.VipAccount__Accommodation-note {
    font-size: 14px;
    color: rgba(255, 127, 0, 1);
    margin-bottom: 20px;
    text-align: center;
}