@import '@/styles/mixins';
@import '@/styles/variables';

.MenuMobile__Title {
    font-size: 32px;
    line-height: 36px;
    font-family: $ff-bold;
    letter-spacing: -1px;
    color: $text-black;
    margin-bottom: 24px;
}

.MenuMobile__Nav-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
    padding-bottom: 24px;
    margin-bottom: 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    @include mobile {
        display: flex;
        flex-direction: column;
    }
}

.MenuMobile__Link {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 78px;
    flex-grow: 1;
    border-radius: 20px;
    padding: 16px;
    text-decoration: none;
    font-size: 14px;
    font-family: $ff-semiBold;
    color: $text-black;
    background-clip: padding-box;
    background-image: linear-gradient(45deg, rgba(250, 250, 250, 1) 55%, #efefef);

    &::before {
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        background-image: unset;
        border-radius: inherit;
        z-index: -1;
    }
    
    &.active {
        & .MenuMobile__Link-arrow {
            opacity: 0.4;
        }

        &::before {
            background-image: $box-gradient-green;
        }
    }

    @include mobile {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 18px;
        border-radius: 16px;
        padding: 12px;
    }
}

.MenuMobile__Link-body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
        width: 100%;
    }

    @include tablet {
        order: -1;
    }
}

.MenuMobile__Link-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $white;
}

.MenuMobile__Link-icon {
    @include mobile {
        width: 32px;
        height: 100%;
    }
}

.MenuMobile__Social-title {
    color: $text-black;
    font-family: $ff-semiBold;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    opacity: 0.4;
}