@import '@/styles/mixins';
@import '@/styles/variables';

.VideoPlayer {
    position: relative;

    @include mobile {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    @include tablet {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}

.VideoPlayer__Player {
    border-radius: 24px;
    overflow: hidden;
}

.VideoPlayer__Iframe {
    & > iframe {
        width: 100%;
        height: 100%;
        border: none;
        aspect-ratio: 16 / 9;
    }
}

.VideoPlayer__Note {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    width: 100%;

    @include mobile {
        position: static;
    }

    @include tablet {
        position: static;
    }
}