@import '@/styles/mixins';
@import '@/styles/variables';

.PromoCode {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    overflow: hidden;
}

.PromoCode__Links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 34px;
}

.PromoCode__Checkbox {
    color: $text-gray;
    gap: 8px;

    & .Checkbox__Box:before {
        background-color: #101010;
    }

    & svg>path {
        fill: $black-1;
    }

    & .Checkbox__Link {
        color: $text-white;
    }
}

.PromoCode__Reset-password-link {
    padding-bottom: 2px;
    border-bottom: 1px solid rgb(207, 207, 207, 0.3);
    text-decoration: none;
    color: $text-white;
    font-size: 14px;
    font-family: $ff-semiBold;

    &:hover {
        opacity: 0.7;
    }
}

.PromoCode__Tooltip {
    white-space: break-spaces;
    text-align: center;
    margin-top: auto;
}

.PromoCode__Btn {
    height: 64px;

    @include mobile {
        height: 56px;
    }
}