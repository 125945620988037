@import '@/styles/mixins';
@import '@/styles/variables';

.SessionHeader {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
}

.SessionHeader__Info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    .SessionHeader--vertical & {
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
    }

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 38px;
    }

    @include tablet {
        flex-direction: column;
        align-items: flex-start;
        gap: 38px;
    }
}

.SessionHeader__Subtitle {
    font-size: 20px;
    font-family: $ff-semiBold;
    color: $text-gray-light;

    &--gradient {
        @include text-colored($text-gradient-green);
        background-image: linear-gradient(to right, #26BDED 0%, #31DEB6 6%, rgba(0, 235, 94, 0.99) 130%);
    }
}

.SessionHeader__Title {
    font-size: 32px !important;
    line-height: 40px !important;
    font-family: $ff-semiBold;
    color: $text-black;
    text-align: left;

    .SessionHeader--vertical & {
        font-size: 28px !important;
        line-height: 32px !important;

        @include mobile {
            font-size: 24px !important;
            line-height: 30px !important;
        }
    }

    @include mobile {
        font-size: 24px !important;
        line-height: 30px !important;
    }

    @include tablet {
        font-size: 28px !important;
        line-height: 32px !important;
    }
}

.SessionHeader__Title-list {
    padding-left: 30px;
}

.SessionHeader__Reference {
    color: #12e47d;
    margin-bottom: 5px;
    font-size: 24px !important;
    line-height: 28px !important;
    font-family: $ff-semiBold;
    text-align: left;
}

.SessionHeader__Link {
    position: relative;
    gap: 8px;
    border-radius: unset;
    background: unset;
    box-shadow: unset;
    width: fit-content;
    padding: 0 0 2px 0;
    font-size: 24px;
    font-family: $ff-bold;
    @include text-colored($text-gradient-green);

    @include mobile {
        font-size: 18px;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 115px;
        height: 2px;
        @include content-box($theme: 'gradient-green', $radius: 0px, $padding: 0px)
    }
}

.SessionHeader__Title-wrapper {
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}