@import '@/styles/mixins';
@import '@/styles/variables';

.FormBadge {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid rgba(223, 223, 223, 0.22);
    box-shadow: inset 1px -4px 14px rgba(225, 225, 225, 0.1), inset 0px 0px 9px 2px rgba(225, 225, 225, 0.1);
    font-size: 14px;

    @include mobile {
        top: 16px;
    }
}