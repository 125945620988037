@import '@/styles/mixins';
@import '@/styles/variables';


.ImageInput__InpuUploadHidden {
  visibility: hidden;
  opacity: 0.05;
  position: absolute;
  appearance: none;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.ImageInput__SmallTextRules {
  text-align: center;
  font-size: 12px;
  color: $text-gray;
  margin-top: 10px;
}

.ImageInput__Block {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.ImageInput__Btn {
  background-color: $white;
  border-radius: 12px;
  border: 2px solid $text-gray-light;
  display: flex;
  align-items: center;
  padding: 9px 24px 9px 20px;
  font-weight: 600;
  cursor: pointer;

  @include mobile {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.ImageInput__CameraIcon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
}
