@import '@/styles/mixins';
@import '@/styles/variables';

.Quote {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 16px;
    margin: 0 100px;
    font-size: 26px;
    line-height: 32px;
    color: $text-black;

    @include mobile {
        margin: 0;
        gap: 16px;
    }

    @include tablet {
        margin: 0;
        gap: 20px;
    }
}

.Quote__Before {
    position: absolute;
    left: 0;
    bottom: calc(100% - 20px);

    @include mobile {
        bottom: calc(100% - 30px);
        width: 32px;
    }

    @include tablet {
        bottom: calc(100% - 30px);
        width: 40px;
    }
}

.Quote__After {
    position: absolute;
    top: calc(100%);
    right: 0;
    transform: rotate(180deg);

    @include mobile {
        top: calc(100% - 20px);
        width: 32px;
    }

    @include tablet {
        top: calc(100% - 20px);
        width: 40px;
    }
}

.Quote__Text {
    white-space: pre-wrap;
    text-align: right;
    font-weight: 500;
    line-height: 36px;
    opacity: 0.9;

    @include mobile {
        font-size: 14px;
        line-height: 22px;
        white-space: normal;
    }

    @include tablet {
        font-size: 18px;
        line-height: 28px;
    }
}

.Quote__author {
    font-size: 26px;
    color: #080808;
    text-align: right;
    font-style: italic;
    max-width: 500px;
    margin-left: auto;
    opacity: 0.9;
    font-weight: 500;

    @include mobile {
        font-size: 14px;
        line-height: 22px;
    }

    @include tablet {
        font-size: 18px;
        line-height: 28px;
    }
}