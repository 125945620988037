@import '@/styles/mixins';
@import '@/styles/variables';


.TransportInformation__text {
    font-size: 20px;
    font-family: $ff-semiBold;
    margin-bottom: 16px;
}

.TransportInformation__Radio-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.TransportInformation__Radio {
    color: $text-gray;
    gap: 8px;
}

.TransportInformation__Fields {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 52px;
    margin-top: 24px;
}

.TransportInformation__Input {
    @include fadeIn-animation($duration: 0.3s)
}

.TransportInformation__Tooltip {
    white-space: break-spaces;
    text-align: center;
    margin-top: auto;
}

.TransportInformation__Btn {
    height: 64px;

    @include mobile {
        height: 56px;
    }
}