@import '@/styles/mixins';
@import '@/styles/variables';

.WaitingScreen {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%;
    background-color: #05233A;
    overflow: hidden;
    z-index: 1;
}

.WaitingScreen__Decoration02,
.WaitingScreen__Decoration01 {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -1;
}

.WaitingScreen__Decoration01 {
    left: 0;
}

.WaitingScreen__Decoration02 {
    right: 0;
}

.WaitingScreen__Body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.WaitingScreen__Subtitle {
    font-size: 20px;
    font-family: $ff-semiBold;
    color: rgb(255, 255, 255, 0.4);

    @include mobile {
        font-size: 16px;
    }
}

.WaitingScreen__Title {
    font-family: $ff-semiBold;

    &--gray {
        background-image: unset;
        color: rgb(255, 255, 255, 0.4);
        font-size: 45px;

        @include mobile {
            font-size: 24px;
        }
    }

    &--gradient {
        font-size: 50px;
        padding-right: 8px;
        @include text-colored($text-gradient-green);

        @include mobile {
            font-size: 32px;
        }
    }
}