@import '@/styles/mixins';
@import '@/styles/variables';

.Events {
  @include layer(#fff);
  text-align: center;
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  z-index: 1;

  // &:before {
  //   content: '';
  //   display: block;
  //   width: 80px;
  //   height: 80px;
  //   background: url("./icons/chevron-down.svg") no-repeat center center;
  //   position: absolute;
  //   left: 50%;
  //   top: 0;
  //   transform: translateX(-50%);
  // }

  @include mobile {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  @include tablet {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.Events__Scene,
.Events__Headline {
  display: block;
  margin: 0 0 80px 0;
  opacity: 0;
  will-change: opacity;
  transition: .5s opacity;

  @include mobile {
    margin: unset;
  }

  &.Animate {
    opacity: 1;
  }

  &--1 {
    margin-bottom: 40px;
    opacity: 1;
  }
}

.Events__Scene {
  margin-bottom: 60px;

  @include mobile {
    margin-bottom: 32px;
  }

  @include tablet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.Events__Headline {
  @include mobile {
    margin-bottom: 16px;
  }

  @include tablet {
    margin-bottom: 32px;
  }
}

.Events__Scene-text {
  @include tablet {
    max-width: 650px;
  }
}

.Events__Shadow {
  position: absolute;
  pointer-events: none;

  &--1 {
    right: 20px;
    top: 0;

    @include mobile {
      right: unset;
      left: -50px;
      width: 550px;
    }

    @include tablet {
      left: 0;
      right: unset;
      opacity: 0.6;
    }
  }

  &--2 {
    left: 0px;
    top: 200px;

    @include mobile {
      left: -140px;
      top: 110px;
      opacity: 0.6;
      width: 620px;
    }

    @include tablet {
      right: 0;
      left: unset;
      top: 60px;
      opacity: 0.7;
    }
  }
}

.Event__container-mobile {
  padding: 8px;
}


.Events__Section {
  position: relative;

  &--Widget {
    padding: 120px 0 0;

    @include mobile {
      padding: 48px 0 0;
    }

    @include tablet {
      padding: 80px 0 0;
    }
  }

  &--Speakers {
    padding: 80px 0 0;

    @include mobile {
      padding: 50px 0 0;
    }
  }
}