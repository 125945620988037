@import '@/styles/mixins';
@import '@/styles/variables';

.PhotoArchive {
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
    z-index: 1;

    @include mobile {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @include tablet {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.PhotoArchive__container {
    position: relative;

    @include mobile {
        padding: 0 8px;
    }

    @include tablet {
        padding: 0 12px;
    }
}

.PhotoArchive__Decoration-01 {
    position: absolute;
    top: -330px;
    right: 0;
    z-index: -1;

    @include mobile {
        top: -80px;
        width: 530px;
        height: 190px;
        opacity: 0.7;
    }
}

.PhotoArchive__Decoration-02 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 460px;
    z-index: -1;

    @include mobile {
        height: 200px;
        opacity: 0.5;
    }
}

.PhotoArchive__Headline {
    margin-bottom: 80px;
    font-size: 86px !important;
    line-height: 96px !important;

    @include mobile {
        margin-bottom: 32px;
        font-size: 30px !important;
        line-height: 40px !important;
    }

    @include tablet {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 52px;
        font-size: 64px !important;
        line-height: 70px !important;
    }
}

.PhotoArchive__Slider-Container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    max-width: min(155vh, 96vw);
}

.PhotoArchive__Swiper-Thumbs,
.PhotoArchive__Swiper {
    width: 1025px;

    @include mobile {
        width: 100%;
    }

    @include tablet {
        width: 608px;
    }
}

.PhotoArchive__Swiper {
    border-radius: 24px;
    aspect-ratio: 16 / 9;

    @include mobile {
        border-radius: 16px;
    }
}

.PhotoArchive__Slide-Custom {
    height: auto;
    cursor: pointer;
}

.PhotoArchive__Slide-Thumb {
    height: 130px;
    margin-top: 12px;
    border-radius: 24px;
    opacity: 0.6;
    overflow: hidden;
    cursor: pointer;

    @include mobile {
        height: 42px;
        margin-top: 4px;
        border-radius: 6px;
    }

    @include tablet {
        height: 77px;
    }

    &.swiper-slide-thumb-active {
        opacity: 1;
    }
}

.PhotoArchive__Slider-Btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 8px;
}

.PhotoArchive__Hide-Btn {
    display: none;
}

.PhotoArchive__Slider-btn-white {
    color: #fff;
}