@import '@/styles/mixins';
@import '@/styles/variables';

.LinkSent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
}

.LinkSent__Count-down {
    color: $text-gray;
    font-size: 14px;
    font-family: $ff-semiBold;
}

.LinkSent__Send-again {
    border: none;
    background: none;
    font-size: 14px;
    font-family: $ff-semiBold;
    text-decoration: none;
    color: $text-white;
    padding-bottom: 2px;
    border-bottom: 1px solid $white;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}