// colors
$white: #fff;
$black: #080808;
$black-1: rgb(8, 8, 8, 0.94);
$black-2: #1B1B1C;
$gray-0: rgba(255, 255, 255, 0.2);
$gray-1: rgb(207, 207, 207, 0.3);
$gray-2: rgba(250, 250, 250, 1);
$gray-3: #DFDFDF;
$gray-4: #f4f4f4;
$gray-5: #EEEEEE;
$green-0: #22A19A;
$background: #021B2F;
$main-color: #021B2F;

// fonts
$headline-dark: #080808;
$headline-light: #fff;
$text-black: #080808;
$text-white: #fff;
$text-gray: #717171;
$text-gray-light: #B4B4B4;
$text-green: #22A19A;
$text-red: #F1161C;


// family font
$ff-base: "SBSansDisplay";
$ff-thin: "SBSansDisplay-Thin";
$ff-light: "SBSansDisplay-Light";
$ff-regular: "SBSansDisplay-Regular";
$ff-semiBold: "SBSansDisplay-SemiBold";
$ff-bold: "SBSansDisplay-Bold";

// gradient
$text-gradient-green: linear-gradient(to right, #26BDED 0%, #31DEB6 48.78%, rgba(0, 235, 94, 0.99) 130%);
$text-gradient-white: linear-gradient(to bottom, rgb(255 255 255 / 60%), #fff);
$box-gradient-green: linear-gradient(to right, #26BDED 0%, #31DEB6 48.78%, rgba(0, 235, 94, 0.99) 130%);
$box-gradient-light-green: radial-gradient(111.94% 111.98% at 99.97% -0.09%, rgba(49, 222, 182, 0.02) 0%, rgba(49, 222, 182, 0.07) 100%);
$box-gradient-black: radial-gradient(105.47% 105.47% at 70.67% 25.78%, rgba(84, 94, 102, 0) 0%, rgba(84, 94, 102, 0.32) 100%);
$label-gradient-black: radial-gradient(rgba(84, 94, 102, 0.2), rgba(84, 94, 102, 0.4));