@import '@/styles/mixins';
@import '@/styles/variables';

.Award {
    position: relative;
    margin-bottom: 120px;
    z-index: 1;

    @include mobile {
        margin-bottom: 56px;
    }

    @include tablet {
        margin-bottom: 100px;
    }
}

.Award__Green-Light {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(70px);
    z-index: -1;

    @include mobile {
        top: 0px;
        left: 0px;
        width: 100%;
        transform: unset;
        filter: unset;
    }
}

.Award__Title {
    margin-bottom: 32px;
    font-size: 96px;
    line-height: 100px;

    @include mobile {
        font-size: 32px !important;
        line-height: 34px !important;
        margin-bottom: 14px;
    }

    @include tablet {
        font-size: 64px !important;
        line-height: 70px !important;
        margin-bottom: 20px;
    }

    @include custom-screen($min: 576px, $max: 670px) {
        font-size: 56px !important;
        line-height: 60px !important;
    }
}

.Award__Subtitle {
    margin-bottom: 80px;
    text-align: center;
    font-family: $ff-semiBold;
    font-size: 20px;
    line-height: 28px;
    opacity: 0.6;

    @include mobile {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 40px;
    }

    @include tablet {
        font-size: 18px;
        line-height: 26px;
        max-width: 500px;
        margin: 0 auto 60px auto;
    }
}

.Award__Widgets {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: space-between;
    gap: 32px;

    @include mobile {
        gap: 14px;
    }

    @include tablet {
        padding: 16px;
        gap: 20px;
    }
}

.Award__Widget {
    @include content-box('dark', 48px, 48px);
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    @include mobile {
        padding: 32px;
        border-radius: 32px;
    }

    @include tablet {
        padding: 32px;
    }
}

.Award__Widget-text {
    font-family: $ff-regular;
    font-size: 20px;
    line-height: 28px;

    @include mobile {
        font-size: 16px;
        line-height: 20px;
    }

    @include tablet {
        font-size: 16px;
        line-height: 20px;
    }
}

.Award__Widget-icon {
    @include mobile {
        width: 80px;
        height: 80px;
    }

    @include tablet {
        width: 80px;
        height: 80px;
    }
}