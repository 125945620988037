@import '@/styles/mixins';
@import '@/styles/variables';

.CreatePassword__Inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 40px;
}

.CreatePassword__Btn {
    height: 64px;

    @include mobile {
        height: 56px;
    }
}

.CreatePassword__Tooltip {
    margin-top: auto;
}