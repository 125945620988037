@import '@/styles/mixins';
@import '@/styles/variables';

.NewPassword {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
}

.NewPassword__Btn {
    height: 64px;

    @include mobile {
        height: 56px;
    }
}

.NewPassword__Tooltip {
    margin-top: auto;
}