@import '@/styles/mixins';

.TitlePageAdmin {
  margin: 0;
  margin-bottom: 32px;
  text-align: left;
  font-size: 35px;

  @include tablet {
    display: none;
  }

  @include mobile {
    display: none;
  }


  &--mobile {
    display: none;

    @include tablet {
      text-align: left;
      display: block;
    }

    @include mobile {
      margin-bottom: 40px;
      text-align: left;
      display: block;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }
  }

}