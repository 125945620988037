.CommenChatModeration {
    padding: 100px 0;
}

.CommenChatModeration__Container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
}

.CommenChatModeration__Header {
    margin: 0 -16px 16px -16px;
}