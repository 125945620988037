@import '@/styles/mixins';
@import '@/styles/variables';

.Experts__Title {
    margin-bottom: 80px;

    @include mobile {
        font-size: 32px !important;
        line-height: 34px !important;
        margin-bottom: 32px;
    }

    @include tablet {
        font-size: 60px !important;
        line-height: 70px !important;
        margin-bottom: 60px;
    }
}

.Experts__Container {
    @include mobile {
        padding: unset;
    }
}

.Experts__cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    grid-auto-rows: 518px;
    gap: 32px;

    @include tablet {
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
        grid-auto-rows: 493px;
        gap: 20px;
    }
}

.Experts__Button {
    margin: 90px auto 0;
    z-index: 2;

    @include tablet {
        margin: 40px auto 0;
    }
}