@import '@/styles/mixins';
@import '@/styles/variables';

.DateLabel {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 16px;
  overflow: hidden;
  background: $label-gradient-black;

  @include mobile {
    width: fit-content;
    height: 40px;
    padding: 10px 12px;
    border-radius: 12px;
  }
}

.DateLabel__Text {
  font-family: $ff-semiBold;
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  padding-left: 4px;

  @include mobile {
    font-size: 14px;
    line-height: 24px;
  }

  @include tablet {
    font-size: 20px;
    line-height: 24px;
  }
}

.DateLabel__icon {
  @include mobile {
    width: 24px;
    height: 24px;
  }
}