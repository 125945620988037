@import '@/styles/mixins';
@import '@/styles/variables';


.LiveChat__Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include tablet {
        min-height: 470px;
    }
}

.LiveChat__Not-started {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-color: $white;
    border-radius: 0 0 24px 24px;
    color: $text-gray;
    font-size: 14px;
    text-align: center;
    line-height: 18px;
    white-space: break-spaces;

    &--forModerators {
        min-height: 535px;
    }
}

.LiveChat__Collapse {
    position: relative;

    &--collapsible {
        height: 50px;
        border-radius: 20px;
        overflow: hidden;
        transition: height 0.3s;
    }

    @include desktop {
        display: flex;
        height: 100% !important;
        position: unset;
        height: unset;
        border-radius: unset;
        overflow: unset;
        transition: unset;
    }
}