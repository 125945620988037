@import '@/styles/mixins';
@import '@/styles/variables';

.TariffsPlans {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .szh-accordion__item-btn {
        border: none;
        outline: none;
        background: none;
        width: 100%;
    }

    .szh-accordion__item-content {
        transition: height 0.50s cubic-bezier(0, 0, 0, 1);
    }
}

.TariffsPlans__Dark,
.TariffsPlans__Light {
    border-radius: 26px;
    padding: 24px 0px;
    border: 3px solid transparent;

    @include mobile {
        padding: 12px 0px;
        border-radius: 20px;
    }
}

.TariffsPlans__Light {
    position: relative;
    background-color: rgb(250, 250, 250);
    color: #080808;
    background-clip: padding-box;
    border-color: #F0F0F0;

    &--selected {
        border-color: #31DEB6;
    }

    &::before {
        content: "";
        position: absolute;
        top: -2px;
        bottom: -2px;
        left: -2px;
        right: -2px;
        border-radius: inherit;
        background-image: $box-gradient-green;
        z-index: -1;
    }
}

.TariffsPlans__Dark {
    background-color: $black-1;
    color: $text-white;

    &--selected {
        border-color: #31DEB6;
    }
}
