@import '@/styles/mixins';
@import '@/styles/variables';

.OnCampusProgram__Multiple_sessions {
    padding: 12px 0;
    border-radius: 48px;
    margin-bottom: 12px;
    background-color: #FAFAFA;
    overflow: hidden;

    @include mobile {
        border-radius: 36px;
    }
}

.OnCampusProgram__Practice {
    margin:12px 12px 0 12px;
}