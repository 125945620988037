@import '@/styles/mixins';
@import '@/styles/variables';

.StageCard {
    position: relative;
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 32px;

    &:not(:last-of-type) {
        border-right: 4px solid;
        border-image: radial-gradient(rgba(255, 255, 255, 0.2), transparent);
        border-image-slice: 1;
        padding-right: 32px;

        @include mobile {
            border-right: unset;
            padding-right: unset;
        }
    
        @include tablet {
            padding-right: 10px;
            border-right-width: 3px;
        }
    }
}

.StageCard__Number {
    @include text-colored(linear-gradient(to bottom, rgba(255, 255, 255, 0.2), transparent 80%));
    position: absolute;
    top: -95px;
    right: 0px;
    padding: 0 20px;
    font-size: 156px;
    line-height: 164px;
    font-style: italic;
    font-family: $ff-bold;
    z-index: -1;

    @include mobile {
        font-size: 80px;
        top: -50px;
        right: -20px;
    }

    @include tablet {
        font-size: 100px;
    }
}

.StageCard__Body {
    &--top {
        border-bottom: 4px solid;
        border-image: radial-gradient(rgb(255 255 255 / 20%), transparent);
        border-image-slice: 1;

        @include tablet {
            border-bottom-width: 3px;
        }
    }
}

.StageCard__Subtext {
    font-family: $ff-semiBold;
    font-size: 16px;
    line-height: 24px;
    color: rgb(255 255 255 / 40%);
    text-transform: uppercase;

    @include mobile {
        gap: 8px;
        font-size: 14px;
        line-height: 20px;
    }

    @include tablet {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        font-size: 14px;
        line-height: 20px;
    }
}

.StageCard__Subtext-gradient {
    font-family: $ff-semiBold;
    font-size: inherit;
    line-height: inherit;
    margin-left: 10px;

    &::after {
        z-index: -1;
    }

    @include tablet {
        margin-left: unset;
    }

    &--finished {
        filter: grayscale(1);
    }
}

.StageCard__Text {
    margin-top: 12px;
    font-family: $ff-semiBold;
    font-size: 26px;
    line-height: 34px;
    color: $text-white;
    white-space: break-spaces;

    @include mobile {
        font-size: 18px;
        line-height: 26px;
        white-space: unset;
    }

    @include tablet {
        font-size: 18px;
        line-height: 26px;
    }
}