@import '@/styles/mixins';
@import '@/styles/variables';

.RegistrationPrevBtn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    top: 16px;
    left: 16px;
    @include fadeIn-animation($duration: 0.5s);

    @include mobile {
        gap: 12px;
    }
}

.RegistrationPrevBtn__Btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid gray;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        border-color: $white;
    }

    &:active {
        scale: 0.98;
    }

    @include mobile {
        width: 32px;
        height: 32px;

        &>svg {
            width: 14px;
        }
    }
}

.RegistrationPrevBtn__Text {
    font-family: $ff-semiBold;

    @include mobile {
        display: none;
    }
}