.FullTimePlanInfo {
  display: flex;
  gap: 10px;
  padding: 12px;
  background: #21BA72;
  border-radius: 20px;
}

.FullTimePlanInfo__Icon {
  display: flex;
  flex-shrink: 0;
}

.FullTimePlanInfo__Text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  min-width: 0;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}
