@import '@/styles/mixins';
@import '@/styles/variables';

.Testimonial-Card {
    display: grid;
    grid-template-columns: 1fr auto;
    height: 480px;

    @include mobile {
        grid-template-columns: unset;
        grid-template-rows: auto auto;
    }

    @include tablet {
        height: 410px;
    }
}

.Testimonial-Card__img-container {
    border-radius: 32px;
    transform: translateY(66px);
    width: 315px;
    height: 410px;
    box-shadow: inset 0px 0px 15px 5px rgb(255 255 255 / 40%);
    overflow: hidden;

    @include mobile {
        width: 100%;
        height: 290px;
        transform: translateY(0px);
    }

    @include tablet {
        width: 230px;
        height: 350px;
        transform: translateY(60px);
    }
}

.Testimonial-Card__img {
    mix-blend-mode: soft-light;
    border-radius: inherit;
}

.Testimonial-Card__body {
    @include content-box($theme: 'light-green', $radius: 48px, $padding: 48px);
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 416px;
    padding: 48px 48px 48px 100px;
    transform: translateX(-48px);
    z-index: -1;

    @include mobile {
        padding: 18px;
        border-radius: 28px;
        transform: translateX(0px) translateY(-40px);
        height: 260px;
        padding-top: 60px;
    }

    @include tablet {
        padding: 32px 24px 32px 80px;
        border-radius: 40px;
        height: 374px;
    }

    @include custom-screen(992px, 1200px) {
        padding: 32px 24px 32px 80px;
    }
}

.Testimonial-Card__company,
.Testimonial-Card__author,
.Testimonial-Card__text {
    font-family: $ff-regular;
    font-size: 20px;
    line-height: 26px;
    color: $text-black;

    @include tablet {
        font-size: 16px;
        line-height: 22px;
    }
}

.Testimonial-Card__text {
    @include mobile {
        font-size: 12px;
        line-height: 16px;
    }
}

.Testimonial-Card__company,
.Testimonial-Card__author {
    @include mobile {
        font-size: 14px;
        line-height: 20px;
    }
}

.Testimonial-Card__author {
    font-family: $ff-bold;
}

.Testimonial-Card__company {
    @include text-colored($text-gradient-green);
}