@import '@/styles/mixins';
@import '@/styles/variables';

.Terms {
    position: relative;
    overflow: visible;
    @include mobile {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 56px;
    }

    @include tablet {
        margin-bottom: 100px;
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.Terms__Wrapper {
    position: relative;

    @include mobile {
        padding: 0 8px;
    }
}

.Terms__Trophy {
    position: absolute;
    top: 0;
    right: -25px;
    pointer-events: none;
    @include mobile {
        display: none;
    }

    @include custom-screen($min: 992px, $max: 1200px) {
        right: -140px;
    }

    @include tablet {
        top: -80px;
        right: 0px;
        width: 220px;
    }
}

.Terms__Green-Light {
    position: absolute;
    top: 0;
    left: 0;

    @include mobile {
        width: 450px;
        right: 0;
        left: unset;
        transform: rotateY(180deg);
    }
}

.Terms__Red-Light {
    position: absolute;
    bottom: 0;
    right: 0;

    @include mobile {
        display: none;
    }

    @include tablet {
        display: none;
    }
}

.Terms__Title {
    mix-blend-mode: multiply;
    margin-bottom: 55px;
    white-space: break-spaces;

    @include mobile {
        font-size: 32px !important;
        line-height: 34px !important;
        margin-bottom: 40px;
        white-space: unset;
    }

    @include tablet {
        font-size: 64px !important;
        line-height: 74px !important;
        margin-bottom: 60px;
    }
}

.Terms__Title-gradient {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;

    &::after {
        bottom: -8px;
        top: 6px;
        border-radius: 24px;
        z-index: -1;

        @include mobile {
            border-radius: 10px;
            bottom: 0px;
        }
    }
}

.Terms__Info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 32px;

    @include mobile {
        gap: 14px;
    }
}

.Terms__Accept {
    @include content-box('light-green', 48px, 48px);
    padding-bottom: 35px;
    @include mobile {
        border-radius: 28px;
        padding: 32px 24px;
    }

    @include tablet {
        border-radius: 40px;
        padding: 32px 24px;
    }
}

.Terms__Reject {
    @include content-box('light-red', 48px, 48px);

    @include mobile {
        border-radius: 28px;
        padding: 32px 24px;
    }

    @include tablet {
        border-radius: 40px;
        padding: 32px 24px;
    }
}

.Terms__Accept,
.Terms__Reject {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;

    @include mobile {
        gap: 24px;
    }
}

.Terms__Text {
    margin: unset;
    font-family: $ff-semiBold;
    font-size: 16px;
    line-height: 26px;
    text-transform: uppercase;
    color: $text-black;
    opacity: 0.4;

    @include mobile {
        font-size: 14px;
        line-height: 20px;
    }

    @include tablet {
        font-size: 14px;
        line-height: 20px;
    }
}

.Terms__List {
    list-style: none;
    margin: unset;
    padding: unset;
}

.Terms__Сlause {
    display: grid;
    grid-template-columns: 32px 1fr;
    gap: 12px;
    font-family: $ff-regular;
    font-size: 20px;
    line-height: 26px;
    color: $text-black;
    margin-bottom: 20px;

    @include mobile {
        font-size: 16px;
        line-height: 26px;

        &>svg {
            width: 28px;
            height: 28px;
        }
    }

    @include tablet {
        font-size: 18px;
        line-height: 22px;
    }
}