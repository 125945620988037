
.NotFound__Title {
    text-align: center;
    font-size: 48px;
}
.NotFound__Text {
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
}
.NotFound__Container {
    padding-top: 100px;
    padding-bottom: 100px;
}
.NotFound__Button {
    max-width: 400px;
    font-size: 18px;
    margin: 0 auto;
}