@import '@/styles/mixins';
@import '@/styles/variables';

.SessionSpeaker__container {
    flex-shrink: 0;
    
    &--moderator {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 28px;
        margin-top: 28px;
        width: 100%;
        border-top: 1px solid #F0F0F0;
    }
}

.SessionSpeaker {
    display: grid;
    grid-template-columns: 60px 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }

    &--big {
        grid-template-columns: 90px 1fr;
    }
}

.SessionSpeaker__Popup {
    & .Popup__CloseBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fffffff0;
        border-radius: 50%;

        @include mobile {
            width: 40px;
            height: 40px;
            top: 16px;
            right: 16px;
        }
    }

    & .Popup__Box {
        @include mobile {
            left: 8px;
            right: 8px;
            width: calc(100% - 16px);
            padding: 64px 16px 14px 16px;
            border-radius: 24px;
        }

        @include tablet {
            padding: 80px 40px 32px 40px;
        }
    }
}

.SessionSpeaker__Avatar-container {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;

    .SessionSpeaker--big & {
        width: 90px;
        height: 90px;
    }
}

.SessionSpeaker__Avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.SessionSpeaker__Speaker-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.SessionSpeaker__Speaker-name {
    font-size: 18px;
    font-family: $ff-semiBold;
    color: $text-black;
    text-transform: capitalize;
}

.SessionSpeaker__Speaker-title {
    font-size: 18px;
    color: $text-gray;
}

.SessionSpeaker__BackwordBtn {
    position: absolute;
    top: 50px;
    left: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0px 0 20px #d2d2d294;
    border: unset;
    outline: unset;
    cursor: pointer;

    &:hover {
        &>svg {
            opacity: 0.6;
        }
    }

    @include mobile {
        top: 28px;
        left: 25px;
        background-color: unset;
        box-shadow: unset;
        width: unset;
        height: unset;
        font-size: 14px;
    }

    @include tablet {
        top: 45px;
        left: 40px;
        background-color: unset;
        box-shadow: unset;
        width: unset;
        height: unset;
    }
}

.SessionSpeaker__Moderator {
    text-transform: capitalize;
}