@import '@/styles/mixins';

.Main__Layer {
    @include layer(#fff);
    margin-bottom: 120px;

    @include mobile {
        margin-bottom: 56px;
    }

    @include tablet {
        margin-bottom: 100px;
    }
}

.Main__Broadcast {
    padding-top: 50px;

    @include mobile {
        margin-bottom: 25px;
        margin-top: -100px;
        padding-top: unset;
    }

    @include tablet {
        margin-bottom: unset;
        margin-top: 50px;
    }
}