@import '@/styles/mixins';

.Advantage {
  padding: 48px;
  border-radius: 48px;

  text-align: left;
  height: 100%;

  &--light {
    background: radial-gradient(135.28% 116.82% at 94.25% -18.22%, rgba(255, 255, 255, 0.02) 0%, rgba(49, 222, 182, 0.07) 100%);
    box-shadow: 6px 0 24px 0 rgba(49, 222, 182, 0.12) inset, 0 6px 8px 0 rgba(49, 222, 182, 0.02) inset, -6px 0px 8px 0px rgba(49, 222, 182, 0.04) inset, 0px -6px 8px 0px rgba(49, 222, 182, 0.08) inset;
  }

  &--dark {
    background: radial-gradient(169.31% 171.92% at 100.00% -0.00%, rgba(84, 94, 102, 0.00) 0%, rgba(84, 94, 102, 0.32) 100%);
    box-shadow: 6px 0 28px 0 rgba(255, 255, 255, 0.08) inset, 0 6px 8px 0 rgba(255, 255, 255, 0.02) inset, -6px 0px 8px 0px rgba(255, 255, 255, 0.02) inset, 0px -6px 8px 0px rgba(255, 255, 255, 0.08) inset;
    backdrop-filter: blur(48px);
    color: #ffffff;
  }

  @include mobile {
    padding: 24px;
  }

  @include tablet {
    padding: 32px;
  }
}

.Advantage__Icon {
  margin: 0 0 48px;

  @include mobile {
    margin: 0 0 24px;

    &>svg {
      width: 80px;
      height: 80px;
    }
  }

  @include tablet {
    &>svg {
      width: 96px;
      height: 96px;
    }
  }
}