@import '@/styles/mixins';
@import '@/styles/variables';

.AccessGateway {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    min-height: 100vh;
    overflow: hidden;
    z-index: 1;

    @include mobile {
        padding: 16px 8px;
    }

    @include tablet {
        align-items: flex-end;
        padding: 16px 16px 0 16px;
    }
}

.AccessGateway__Decoration01 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
}

.AccessGateway__Decoration02 {
    position: absolute;
    top: 0px;
    right: 0;
    width: 500px;
    z-index: -1;

    @include mobile {
        width: 300px;
    }

    @include tablet {
        width: 300px;
    }
}

.AccessGateway__Decoration03 {
    position: absolute;
    top: 0;
    left: 0;
    width: 760px;
    z-index: -1;

    @include mobile {
        width: 300px;
    }

    @include tablet {
        width: 600px;
    }
}

.AccessGateway__Container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $black-1;
    border-radius: 20px;
    padding: 32px;
    width: 460px;
    min-height: 620px;
    z-index: -1;

    @include mobile {
        width: 100%;
        border-radius: 26px;
        padding: 24px 16px;
        justify-content: space-between;
    }

    @include tablet {
        min-height: 884px;
        border-radius: 32px 32px 0 0;
    }
}

.AccessGateway__Close-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid gray;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        border-color: $white;
    }

    &:active {
        scale: 0.98;
    }

    @include mobile {
        width: 32px;
        height: 32px;

        &>svg {
            width: 12px;
        }
    }
}