@import '@/styles/mixins';

.Container {
  width: 100%;
  max-width: 1220px;
  padding: 0 20px;
  margin: 0 auto;

  @include mobile {
    padding: 0 16px;
  }
}