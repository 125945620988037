.HeartIcon {
    width: 14px;
    height: 13px;
    background-image: url(./assets/like-gray.svg);
    background-repeat: no-repeat;
    background-size: contain;
    transition: 0.2s;

    &--haslikes {
        background-image: url(./assets/like-red.svg);
    }
}