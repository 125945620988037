@import '@/styles/mixins';
@import '@/styles/variables';

.Poster {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 48px;
  overflow: hidden;
  background: radial-gradient(207.03% 136.11% at 100.00% -0.00%, rgba(84, 94, 102, 0.00) 0%, rgba(84, 94, 102, 0.48) 100%);
  cursor: pointer;

  @include mobile {
    border-radius: 20px;
  }
}

.Poster__Image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  &>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    @include fadeIn-animation(0.8s);
  }
}

.Poster__Title {
  text-align: left;
  margin: 0 0 12px 0;

  @include mobile {
    margin: 0 0 4px 0;
  }

  @include tablet {
    margin: 0 0 8px 0;
  }
}

.Poster__Content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 80px;
  box-shadow: 12px 0px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 12px 12px 0px rgba(255, 255, 255, 0.04) inset, -12px 0px 12px 0px rgba(255, 255, 255, 0.08) inset, 0px -12px 12px 0px rgba(255, 255, 255, 0.12) inset;
  background: linear-gradient(180deg, rgba(8, 8, 8, 0.00) 0%, #080808 100%);
  z-index: 1;

  @include mobile {
    padding: 20px;
  }

  @include tablet {
    padding: 20px;
  }
}

.Poster__Content-separated {
  margin-top: 20px;

  @include tablet {
    margin-top: 32px;
  }
}

.Poster__Btn {
  @include content-box($theme: 'gradient-green', $radius: 50%, $padding: 0px);
  width: 204px;
  height: 204px;

  @include mobile {
    width: 72px;
    height: 72px;
  }

  @include tablet {
    width: 120px;
    height: 120px;
  }

  svg {
    font-size: 20px;
  }
}

.Poster__Btn-Wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.Poster__Text {
  color: rgba(255, 255, 255, .4);
  max-width: 560px;
  padding-right: 40px;

  @include mobile {
    padding-right: unset;
    line-height: 22px;
  }
}

.Poster__Link {
  font-family: $ff-semiBold;
  font-size: 20px;
  opacity: 0.88;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  @include text-colored($text-gradient-green);

  @include mobile {
    font-size: 14px;
  }

  svg {
    margin-left: 10px;
  }
}