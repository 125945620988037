@import '@/styles/mixins';
@import '@/styles/variables';

.Broadcast {
    position: relative;
    padding-top: 120px;
    margin-bottom: 100px;
    z-index: 2;

    @include mobile {
        padding-top: 80px;
        margin-bottom: 150px;
    }

    @include tablet {
        padding-top: 100px;
    }
}

.Broadcast__Title {
    margin-bottom: 52px;
    font-size: 80px !important;

    @include mobile {
        font-size: 32px !important;
        margin-bottom: 16px;
    }

    @include tablet {
        font-size: 60px !important;
        margin-bottom: 16px;
    }
}

.Broadcast__BackwordBtn {
    margin-bottom: 20px;
}

.Broadcast__Wrapper {
    display: grid;
    grid-template-columns: 3fr 360px;
    gap: 8px;

    @include mobile {
        display: flex;
        flex-direction: column;
    }

    @include tablet {
        display: flex;
        flex-direction: column;
    }
}

.Broadcast__Col {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.Broadcast__Blocked-Popup {
    &>.Popup__Box {
        border-radius: 32px;
        padding: 70px 55px;

        @include mobile {
            width: 100%;
            border-radius: 24px 24px 0 0;
            padding: 48px 24px;
        }
    }
}

.Broadcast__Header {
    margin: 0 -16px 16px -16px;
}