@import '@/styles/mixins';

.ShareItem {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 204px;
  height: 204px;
  border-radius: 48px;
  box-shadow: 6px 0px 28px 0px rgba(255, 255, 255, 0.08) inset, 0px 6px 8px 0px rgba(255, 255, 255, 0.02) inset, -6px 0px 8px 0px rgba(255, 255, 255, 0.02) inset, 0px -6px 8px 0px rgba(255, 255, 255, 0.08) inset;
  cursor: pointer;
  will-change: box-shadow;
  transition: box-shadow .3s;

  &:hover {
    border-radius: 48px;
    box-shadow: 6px 0px 80px 0px rgba(255, 255, 255, 0.32) inset, 0px 6px 8px 0px rgba(255, 255, 255, 0.02) inset, -6px 0px 8px 0px rgba(255, 255, 255, 0.02) inset, 0px -6px 8px 0px rgba(255, 255, 255, 0.08) inset;
    backdrop-filter: blur(48px);

  }

  @include mobile {
    min-width: unset;
    height: 104px;
    border-radius: 28px;
  }

  @include tablet {
    min-width: unset;
    height: 148px;
    border-radius: 48px;
  }
}