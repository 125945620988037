@import '@/styles/mixins';
@import '@/styles/variables';

.ProgramHeading {
    display: flex;
    flex-direction: column;
    margin-bottom: 47px;
    margin-top: 120px;
    gap: 50px;

    @include mobile {
        align-items: center;
        margin-bottom: 20px;
        margin-top: 60px;
        gap: 30px;
    }

    @include tablet {
        align-items: center;
        margin-bottom: 32px;
        margin-top: 100px;
        gap: 50px;
    }
}

.ProgramHeading__Title {
    position: relative;
}

.ProgramHeading__Subtitle {
    font-size: 32px;
    font-family: $ff-semiBold;
    @include text-colored($text-gradient-green);

    @include mobile {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        white-space: break-spaces;
    }

    @include tablet {
        font-size: 28px;
        text-align: center;
    }
}

.ProgramHeading__Download-link {
    position: absolute;
    left: 0;
    top: calc(100% + 24px);
    background: unset;
    box-shadow: unset;
    font-size: 24px;
    width: fit-content;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #717171;
    border-radius: unset;

    @include mobile {
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        font-size: 18px;
    }

    @include tablet {
        left: 50%;
        transform: translateX(-50%);
    }
}

.ProgramHeading__Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    gap: 24px;
    z-index: 1;

    @include mobile {
        flex-direction: column;
        align-items: center;
        // gap: 100px;
        gap: 40px;
    }

    @include tablet {
        flex-direction: column;
        align-items: center;
        // gap: 100px;
        gap: 40px;
    }
}

.ProgramHeading__Btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.ProgramHeading__Btn {
    height: 74px;
    width: 185px;
    font-size: 32px;
    font-family: $ff-semiBold;

    @include mobile {
        font-size: 20px;
        height: 58px;
        width: 160px;
    }

    &--not-active {
        background-color: #021b2f;

        & .Button__Text {
            @include text-colored($text-gradient-green);
        }
    }

    &:hover {
        color: unset;
        opacity: 1;
    }

    &:active:enabled {
        scale: unset;
    }
}