@import '@/styles/mixins';
@import '@/styles/variables';

.FeatureCard {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 234px;
    height: 160px;
    gap: 16px;
    padding: 16px;
    border-radius: 20px;


    &--light {
        background-color: $white;
    }

    &--dark {
        background-color: rgba(250, 250, 250, 0.06);
    }
}

.FeatureCard__Header {
    display: flex;
    align-items: center;
    gap: 13px;
}

.FeatureCard__Title {
    font-size: 10px;
    font-family: $ff-semiBold;
    text-transform: uppercase;

    &--gray {
        color: $text-gray-light;
    }

    &--colored {
        @include text-colored($text-gradient-green);
    }
}

.FeatureCard__Text {
    font-size: 14px;
    line-height: 20px;
    white-space: break-spaces;

    .FeatureCard--light & {
        color: $text-black;
    }

    .FeatureCard--drak & {
        color: rgba(240, 240, 240, 1);
    }
}