@import '@/styles/mixins';
@import '@/styles/variables';

.Nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    gap: 60px;

    @include mobile {
        flex-grow: unset;
    }

    @include tablet {
        gap: 12px;
        flex-grow: unset;
    }
}

.Nav__Items {
    display: flex;
    align-items: center;
    gap: 24px;
    height: 100%;
    list-style: none;
}

.Nav__Item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    transform: translateY(-100px);
    opacity: 0;

    &:first-child {
        transition: .5s 0s;
    }

    &:nth-child(2) {
        transition: .5s .1s;
    }

    .Header--Animate & {
        transform: translateY(0);
        opacity: 1;
    }
}

.Nav__Link {
    display: flex;
    align-items: center;
    height: 100%;
    font-family: $ff-semiBold;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    color: rgba(#fff, 60%);
    border-bottom: 2px solid transparent;
    white-space: nowrap;
    transition: 0.3s;

    &.active, &:hover {
        border-bottom-color: white;
        color: #fff;
    }
}

.Nav__Socials {
    position: relative;
    transform: translateY(-100px);
    opacity: 0;
    transition: .5s .3s;

    .Header--Animate & {
        transform: translateY(0);
        opacity: 1;
    }
}

.Nav__Menu {
    position: relative;
    transform: translateY(-100px);
    opacity: 0;
    transition: .5s .5s;

    @include mobile {
        transition: .5s .3s;
    }

    .Header--Animate & {
        transform: translateY(0);
        opacity: 1;
    }
}

.Nav__btns {
    display: flex;
    align-items: center;
    gap: 24px;

    @include mobile {
        gap: 8px;
    }

    @include tablet {
        gap: 12px;
    }
}