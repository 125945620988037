@import '@/styles/mixins';
@import '@/styles/variables';

.AccommodationInfo__Wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;

    @include mobile {
        gap: 8px;
    }
}

.AccommodationInfo__Text {
    border-top: 1px dashed rgba(255, 255, 255, 0.1);
    margin-top: 24px;
    padding-top: 24px;
    font-size: 16px;
    line-height: 20px;
    color: #F0F0F0;
    white-space: break-spaces;
}

.AccommodationInfo__Subtext {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: $text-gray;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
    margin-bottom: 24px;
    padding-bottom: 24px;

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
}

.AccommodationInfo__Link {
    text-decoration: none;
    font-size: 12px;
    color: $text-white;
    padding-bottom: 3px;
    border-bottom: 1px solid gray;

    &:hover {
        opacity: 0.6;
    }
}