@import '@/styles/mixins';

.SkeletonImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    visibility: hidden;
    
    &--active {
        @include fadeIn-animation(1s);
        visibility: visible;
    }
}