@import '@/styles/mixins';
@import '@/styles/variables';

.PasswordRulesWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &:hover .PasswordRules {
        visibility: visible;
    }
}

.PasswordRules {
    position: absolute;
    left: calc(100% + 16px);
    top: 24px;
    padding: 20px;
    background-color: $black-2;
    border-radius: 20px;
    width: max-content;
    visibility: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 20px;
        left: -20px;
        border: 10px solid;
        border-color: transparent $black-2 transparent transparent;

    }

    @include mobile {
        top: 0;
        right: 0;
        left: unset;
        visibility: visible;
        background-color: unset;
        padding: unset;

        &::before {
            display: none;
        }
    }

    @include tablet {
        position: unset;
        visibility: visible;
        background-color: unset;
        padding: unset;
        margin-top: 12px;
        cursor: pointer;

        &::before {
            display: none;
        }
    }
}

.PasswordRules__Title {
    display: flex;
    align-items: baseline;
    gap: 8px;
    font-size: 14px;
    font-family: $ff-semiBold;
    margin-bottom: 8px;

    @include mobile {
        font-size: 12px;
    }
}

.PasswordRules__List {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 16px;

    @include mobile {
        background-color: $black-2;
        border-radius: 16px;
        padding: 12px 12px 12px 24px;
    }

    @include tablet {
        background-color: $black-2;
        border-radius: 16px;
        padding: 8px 16px 8px 32px;
    }
}

.PasswordRules__Item {
    position: relative;
    font-size: 14px;
    list-style: none;

    &::before {
        content: "";
        position: absolute;
        border-radius: 50%;
        border: 3px solid #6CD89F;
        left: -16px;
        top: 50%;
        transform: translateY(-50%);

        @include mobile {
            border-width: 2px;
            left: -12px;
        }

        @include tablet {
            border-width: 2px;
        }
    }

    @include mobile {
        font-size: 12px;
    }
}

.PasswordRules__List-small-screen {
    display: none;
    @include fadeIn-animation($duration: 0.5s);

    &--active {
        display: flex;

        @include mobile {
            position: absolute;
            bottom: calc(100% + 12px);
            right: 0;
            width: 225px;
            z-index: 10;
        }
    }
}

.PasswordRules__Close-icon {
    display: none;

    @include mobile {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 12;
    }
}

.PasswordRules__Title-icon {
    transition: 0.3s;

    &--active {
        transform: rotate(180deg);
    }

    @include mobile {
        display: none;
    }
}
