@import '@/styles/mixins';
@import '@/styles/variables';

.Criteria {
    @include layer(#fff);
    position: relative;
    margin-bottom: 120px;
    padding-top: 120px;
    padding-bottom: 120px;
    z-index: 2;

    @include mobile {
        padding-top: 56px;
        padding-bottom: 56px;
        margin-bottom: 56px;
    }

    @include tablet {
        margin-bottom: 80px;
        padding-top: 80px;
        padding-bottom: 100px;
    }
}

.Criteria__Container {
    @include mobile {
        padding: 0 8px;
    }
}

.Criteria__Decoration01 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;

    @include mobile {
        left: unset;
        right: 0;
        width: 570px;
    }
}

.Criteria__Decoration02 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: -1;

    @include mobile {
        display: none;
    }

    @include tablet {
        width: unset;
        left: 0;
        right: unset;
    }
}

.Criteria__Decoration03 {
    position: absolute;
    top: 190px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;

    @include mobile {
        top: 120px;
        width: 600px;
        opacity: 0.5;
    }
}

.Criteria__Divider {
    margin-top: 96px;
    margin-bottom: 96px;

    @include mobile {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    @include tablet {
        margin-top: 80px;
        margin-bottom: 80px;
    }
}