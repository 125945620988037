@import '@/styles/mixins';
@import '@/styles/variables';


.SessionCard {
    padding: 48px;
    border-radius: 48px;
    background-color: $white;
    margin-bottom: 12px;
    flex-grow: 1;

    &--vertical {
        display: flex;
        flex-direction: column;
        padding: 32px;
        height: 100%;
    }

    @include mobile {
        padding: 24px;
        border-radius: 36px;
    }

    @include tablet {
        padding: 36px;
        border-radius: 40px;
    }
}

.SessionCard__Status-Finished {
    opacity: 0.5;
    pointer-events: none;
}

.SessionCard__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .SessionCard--vertical & {
        align-items: flex-start;
    }

    @include mobile {
        align-items: flex-start;
    }
}

.SessionCard__Badges {
    display: flex;
    align-items: center;
    gap: 12px;

    .SessionCard--vertical & {
        flex-direction: column;
        align-items: flex-start;
        max-width: 188px;

        @include mobile {
            max-width: 170px;
        }
    }

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        max-width: 170px;
    }
}

.SessionCard__Place-badge,
.SessionCard__Time-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    min-height: 48px;
    width: fit-content;
    border-radius: 48px;
    background-image: radial-gradient(rgb(255 255 255 / 10%) 38%, rgb(125 131 136 / 16%));
    font-size: 18px;
    color: #373737;

    @include mobile {
        font-size: 16px !important;
        min-height: 39px;
    }
}

.SessionCard__Place-badge {
    background-image: linear-gradient(188deg, rgb(227 96 2 / 81%), rgb(255, 204, 0) 66%);
    color: $text-white;
}

.SessionCard__Place-badge,
.SessionCard__Badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    border-radius: 32px;
    color: $text-white;
    font-size: 18px;

    @include mobile {
        font-size: 16px !important;
        border-radius: 22px;
    }

    &::first-letter {
        text-transform: capitalize;
    }

    &--purple {
        background-image: linear-gradient(200deg, rgba(255, 60, 138, 1), rgba(255, 129, 242, 1));
    }

    &--green {
        background-image: linear-gradient(200deg, rgba(34, 161, 154, 1), rgba(106, 234, 199, 1));
    }

    &--blue {
        background-image: linear-gradient(200deg, rgba(1, 135, 206, 1), rgba(155, 207, 254, 1));
    }

    &--grey {
        background-image: linear-gradient(200deg, #808080, #bdbdbd)
    }
}

.SessionCard__Btn {
    padding: unset;
    border-radius: unset;
    background: unset;
    box-shadow: unset !important;
    overflow: unset;

    & .Button__Icon {

        @include mobile {
            width: 44px;
            height: 44px;
        }
    }

    &:hover {
        scale: 1.05;
    }

    &--finished {
        @include mobile {
            display: none;
        }
    }
}

.SessionCard__Media-Btns {
    display: flex;
    gap: 16px;
    padding-top: 70px;
    margin-top: auto;

    .SessionCard--vertical & {
        flex-direction: column;
    }

    @include mobile {
        flex-direction: column;
        margin-top: auto;
        padding-top: 32px;
    }

    @include tablet {
        flex-direction: row !important;
        margin-top: auto;
        padding-top: 40px;
    }
}