@import '@/styles/mixins';
@import '@/styles/variables';

.StreamTooltip {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    max-width: 400px;
    white-space: normal;
    color: #F0F0F0;
}