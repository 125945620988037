@import '@/styles/mixins';
@import '@/styles/variables';

.PlanFeatures__swiper-slide {
    width: fit-content;
}

.PlanFeatures__Slider-Wrapper {
    position: relative;
}

.PlanFeatures__Title {
    font-size: 18px;
    font-family: $ff-semiBold;
    padding: 0px 24px 24px 24px;

    @include mobile {
        font-size: 16px;
        padding: 0px 16px 24px 16px;
    }

    .PlanFeatures--light & {
        color: $text-black;
    }

    .PlanFeatures--dark & {
        color: $text-white;
    }
}

.PlanFeatures__Slider-prev,
.PlanFeatures__Slider-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    user-select: none;
    cursor: pointer;

    .PlanFeatures--light & {
        background-color: $white;
        box-shadow: 0px 3px 20px #8080804d;
    }

    .PlanFeatures--dark & {
        background-color: rgba(255, 255, 255, 0.16);
    }

    &.swiper-button-disabled {
        display: none;
    }
}

.PlanFeatures__Slider-next {
    right: 24px;
}

.PlanFeatures__Slider-prev {
    &>svg {
        scale: -1;
    }

    left: 24px;
}
