@import '@/styles/mixins';
@import '@/styles/variables';

.InputWarnings__NotAuth {
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 10px;
    padding: 12px 16px;
    color: $text-gray;
    font-size: 14px;
    line-height: 18px;
}

.InputWarnings__NotAuth-Text {
    max-width: 265px;

    @include tablet {
        max-width: unset;
    }
}

.InputWarnings__NotAuth-link {
    position: relative;
    text-decoration: none;
    @include text-colored($text-gradient-green);
    padding-bottom: 2px;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        border-radius: 20px;
        background-image: $box-gradient-green;
    }
}

.InputWarnings__Blocked {
    font-size: 14px;
    color: $text-gray;
}