@import '@/styles/mixins';
@import '@/styles/variables';

.VideoArchive {
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
    z-index: 2;

    @include mobile {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @include tablet {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.VideoArchive__container {
    position: relative;

    @include mobile {
        padding: 0 8px;
    }

    @include tablet {
        padding: 0 12px;
    }
}

.VideoArchive__Decoration-01 {
    position: absolute;
    top: -135px;
    right: -60px;
    z-index: -1;

    @include mobile {
        top: -12px;
        right: 0px;
        width: 125px;
        rotate: 335deg;
    }

    @include tablet {
        top: -65px;
        right: 0px;
        width: 240px;
    }
}

.VideoArchive__Decoration-02 {
    position: absolute;
    bottom: -410px;
    left: -330px;
    z-index: -1;

    @include mobile {
        display: none;
    }

    @include tablet {
        top: 285px;
        left: -160px;
        width: 500px;
    }
}

.VideoArchive__Headline {
    margin-bottom: 80px;
    font-size: 86px !important;
    line-height: 96px !important;

    @include mobile {
        margin-bottom: 32px;
        font-size: 30px !important;
        line-height: 40px !important;
    }

    @include tablet {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 52px;
        font-size: 64px !important;
        line-height: 70px !important;
    }
}

.VideoArchive__Slider-Container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.VideoArchive__Swiper {
    border-radius: 24px;
    width: 1025px;
    height: 577px;

    @include mobile {
        width: 100%;
        height: 184px;
        border-radius: 16px;
    }

    @include tablet {
        width: 608px;
        height: 340px;
    }
}

.VideoArchive__Slide-Custom {
    height: auto;
}

.VideoArchive__Slider-Pagination {
    margin-top: 24px;
    text-align: center;

    @include mobile {
        margin-top: 6px;
    }

    &>.swiper-pagination-bullet {
        height: 10px;
        width: 10px;
        background: rgba(8, 8, 8, 0.5);
        margin: 0px 6px !important;

        @include mobile {
            height: 4px;
            width: 4px;
            margin: 0px 3px !important;
        }
    }

    &>.swiper-pagination-bullet-active {
        height: 12px;
        width: 12px;
        background: linear-gradient(103.48deg, #26BDED -4.75%, #31DEB6 46.35%, rgba(0, 235, 94, 0.9932) 100%);

        @include mobile {
            height: 6px;
            width: 6px;
        }
    }
}

.VideoArchive__Slider-Btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}

.VideoArchive__Hide-Btn {
    display: none;
}