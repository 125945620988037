@import '@/styles/mixins';
@import '@/styles/variables';

.PlanPriceTag {
    display: flex;
    align-items: center;
    gap: 12px;
}

.PlanPriceTag__Price {
    position: relative;
    font-size: 30px;
    font-weight: 500;

    @include mobile {
        font-size: 26px;
    }
}

.PlanPriceTag__Price-discount {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 6px;
    bottom: 100%;
    left: 0;
    font-size: 14px;
    text-decoration: line-through;
    white-space: nowrap;
}

.PlanPriceTag__Price-note {
    font-size: 12px;
    line-height: 16px;
    color: $text-gray;
    white-space: break-spaces;
    padding-left: 12px;
    border-left: 1px solid rgb(125 125 125 / 25%);
}

.PlanPriceTag__Free {
    font-size: 30px;
    font-family: $ff-semiBold;
    @include text-colored($text-gradient-green);

    @include mobile {
        font-size: 26px;
    }
}
