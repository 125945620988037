@import '@/styles/mixins';
@import '@/styles/variables';

.TabsNavigation {
  position: relative;
  padding-top: 20px;
  padding-bottom: 16px;
  margin-bottom: 8px;
  display: none;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 120%;
    height: 100%;
    display: block;
  }

  @include tablet {
    display: block;
    padding-left: 24px;
    padding-right: 0px;
  }

  @include mobile {
    display: block;
    overflow-x: scroll;
    padding-bottom: 0px;
    margin-bottom: 0;
  }

  &::-webkit-scrollbar {
    opacity: 0;
  }
}

.TabsNavigation__List {
  position: relative;
  display: flex;
  list-style: none;
  padding: 0;
  width: 610px;

  @include mobile {
    width: 610px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.TabsNavigation__Link {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 16px;
  border-radius: 12px;
  font-size: 14px;
  font-family: $ff-semiBold;
  line-height: 20px;
  text-decoration: none;
  color: $black;
  background-color: transparent;
  border: none;

  &.active {
    background-color: rgba(33, 186, 114, 0.06);
  }

  &--Logout {
    opacity: .4;
  }

  &--disabled {
    opacity: .25;
  }

  &--no-event {
    pointer-events: none;
  }

  @include mobile {
    font-size: 12px;
    font-family: $ff-semiBold;
    line-height: 20px;
    padding-left: 14px;
    padding-right: 14px;
  }

}

.TabsNavigation__Item {
  display: flex;
  align-items: center;
}