@import '@/styles/mixins';
@import '@/styles/variables';


.TicketPayment__Inputs {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 20px;
    background-color: rgba(250, 250, 250, 0.06);
    padding: 20px 24px;
}

.TicketPayment__Input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3px;

    &:first-of-type {
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    }
}

.TicketPayment__Radio {
    color: $text-white;
    gap: 8px;

    .Radio__Box--active::before {
        background-color: #161718;
    }
}

.TicketPayment__Note {
    font-size: 14px;
    line-height: 20px;
    color: $text-gray;
    padding-left: 28px;
}

.TicketPayment__Tooltip {
    white-space: break-spaces;
    text-align: center;
    margin-top: auto;
}

.TicketPayment__Btn {
    height: 64px;

    @include mobile {
        height: 56px;
    }
}

.TicketPayment__Price {
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 24px;
}