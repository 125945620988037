@import '@/styles/mixins';
@import '@/styles/variables';

.AvatarInput {
  display: flex;
  align-items: center;
  gap: 24px;

  @include mobile {
    flex-direction: column;
  }
}

.AvatarInput__PreviewWrap {
  position: relative;
}

.AvatarInput__Reset {
  position: absolute;
  right: -6px;
  bottom: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background-color: $black;
  border: 5px solid $white;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color .2s ease;

  &:hover {
    background-color: #E1484C;
  }
}

.AvatarInput__Control {
  margin-top: 25px;

  @include mobile {
    margin-top: 0;
  }
}
