@import '@/styles/mixins';
@import '@/styles/variables';


.Textarea {
  position: relative;

  textarea {
    padding: 16px 20px;
    border-radius: 20px;
    display: block;
    width: 100%;
    height: 160px;
    resize: none;
    border: 1px solid #DFDFDF;
    outline: none;
    background-color: #FAFAFA;
  }

  textarea::placeholder {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  textarea:focus {
    border: 1px solid #B4B4B4;
  }

  &--Error {
    textarea {
      border: 1px solid red;
      background-color: #fdf5f5;
    }
  }

  &--Disabled {
    textarea {
      opacity: .5;
    }
  }
}

.Textarea__ErrMsg {
  position: absolute;
  left: 0;
  bottom: 0;
}

.Textarea__Label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $text-gray;
}

.Textarea__WrapBlock {
  position: relative;
}

.Textarea__Length-Message {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: $text-gray;
  position: absolute;
  bottom: 12px;
  right: 14px;
}
