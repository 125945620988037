@import '@/styles/mixins';
@import '@/styles/variables';

.FromSber__Stepper {
    gap: 56px;
    width: 280px;

    ::after {
        width: 40px !important;
    }
}

.FromSber__Stepper-icon {
    opacity: 0;
    @include popIn-animation($duration: 0.5s, $delay: 0.2s)
}

.FromSber__Warning {
    width: 325px;
    padding: 16px;
    border-radius: 16px;
    margin: 0px auto 20px auto;
    background-color: rgba(55, 55, 55, 0.7);
    text-align: center;
    font-size:14px;
    line-height: 20px;
    white-space: break-spaces;

    @include tablet {
        width: 400px;
        white-space: unset;
    }
}

.FromSber__Header {
    margin-top: 50px;
    margin-bottom: 20px ;
}

.FromSber__Form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}