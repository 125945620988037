@import '@/styles/mixins';
@import '@/styles/variables';

.MessageHeader {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 8px;
}

.MessageHeader__Name {
    font-size: 14px;
    font-family: $ff-semiBold;
    color: $text-green;
    white-space: nowrap;
}

.MessageHeader__Time {
    font-size: 14px;
    color: $text-gray;
}