@import '@/styles/mixins';
@import '@/styles/variables';

.ProfileBtn {
    display: flex;
    align-items: center;
    gap: 15px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

.ProfileBtn__Avatar-container {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 4px solid rgb(255, 255, 255, 0.2);
    overflow: hidden;
    pointer-events: none;

    @include mobile {
        height: 40px;
        width: 40px;
        border-width: 3px;
    }
}

.ProfileBtn__Avatar {
    height: 100%;
    width: 100%;
    object-fit: cover;
    @include fadeIn-animation($duration: 0.5s);

    & .Avatar__Initial {
        font-size: 24px;
        line-height: 24px;

        @include mobile {
            font-size: 18px;
            line-height: 18px;
        }
    }

    &--dropdown-menu {

        & .Avatar__Initial {
            font-size: 28px;
            line-height: 28px;

            @include mobile {
                font-size: 28px;
                line-height: 28px;
            }

            @include tablet {
                font-size: 36px;
                line-height: 36px;
            }
        }
    }
}

.ProfileBtn__Icon {
    transition: 0.3s;
    pointer-events: none;

    .ProfileBtn--active & {
        transform: rotate(180deg);
    }

    @include mobile {
        display: none;
    }
}


.ProfileBtn__Dropdown {
    position: absolute;
    top: 90px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 300px;
    padding: 20px 8px;
    border-radius: 20px;
    background-color: $black-1;
    @include fadeIn-fromTop-animation($duration: 0.5s);

    @include mobile {
        width: unset;
        top: 70px;
        left: 8px;
        right: 8px;
        background-color: $white;
        padding: 20px 16px;
        border-radius: 26px;
        box-shadow: 0 0 15px -5px #000;
    }

    @include tablet {
        width: unset;
        left: 8px;
        right: 8px;
        background-color: $white;
        padding: 32px 24px;
        border-radius: 40px;
        box-shadow: 0 0 15px -5px #000;
    }
}

.ProfileBtn__Dropdown-top {
    display: none;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;

    @include mobile {
        display: flex;
        margin-bottom: 20px;
    }

    @include tablet {
        display: flex;
    }
}

.ProfileBtn__Dropdown-top-text {
    font-size: 32px;
    font-family: $ff-semiBold;
    color: $text-black;

    @include mobile {
        font-size: 20px;
    }
}

.ProfileBtn__Dropdown-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    @include mobile {
        margin-bottom: 28px;
        gap: 8px;
    }

    @include tablet {
        flex-direction: row;
        gap: 24px;
        margin-bottom: 28px;
    }
}

.ProfileBtn__Dropdown-avatar-container {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 4px solid rgb(255, 255, 255, 0.2);
    overflow: hidden;

    @include mobile {
        width: 96px;
        height: 96px;
    }

    @include tablet {
        width: 112px;
        height: 112px;
    }
}

.ProfileBtn__Dropdown-username {
    text-transform: capitalize;
    font-size: 20px;
    font-family: $ff-semiBold;
    color: $text-white;

    @include mobile {
        font-size: 20px;
        color: $text-black;
    }

    @include tablet {
        font-size: 24px;
        color: $text-black;
    }
}

.ProfileBtn__Dropdown-list {
    padding: unset;
    margin: unset;
    display: flex;
    flex-direction: column;
    gap: 4px;

    @include mobile {
        gap: 12px;
    }

    @include tablet {
        gap: 12px;
    }
}

.ProfileBtn__Dropdown-item {
    list-style: none;
}

.ProfileBtn__Dropdown-btn,
.ProfileBtn__Dropdown-link {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 60px;

    &:hover {
        background-color: rgba(84, 94, 102, 0.2);
    }

    @include mobile {
        padding: 6px 8px;

        &:hover {
            background-color: rgba(33, 186, 114, 0.06);
        }
    }

    @include tablet {
        padding: 16px;

        &:hover {
            background-color: rgba(33, 186, 114, 0.06);
        }
    }
}

.ProfileBtn__Dropdown-link {
    text-decoration: none;
    font-size: 14px;
    color: $text-white;

    @include mobile {
        color: $text-black;
        font-size: 14px;
        font-family: $ff-semiBold;
    }

    @include tablet {
        color: $text-black;
        font-size: 16px;
        font-family: $ff-semiBold;
    }

    &--disabled {
        opacity: 0.6;
        cursor: unset;
    }
}

.ProfileBtn__Dropdown-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;

    &>svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.ProfileBtn__Dropdown-arrow-right {
    display: none;
    margin-left: auto;

    @include tablet {
        display: block;
    }
}

.ProfileBtn__Dropdown-btn {
    border: none;
    outline: none;
    background-color: transparent;
    justify-items: center;
    cursor: pointer;
}

.ProfileBtn__Dropdown-btn-text {
    justify-self: flex-start;
    font-size: 14px;
    color: $text-gray;

    @include tablet {
        font-size: 16px;
    }
}

.ProfileBtn__Dropdown-divider {
    width: 100%;
    padding: 0 12px;

    &>span {
        display: block;
        width: 100%;
        border-bottom: 1px solid rgb(217, 217, 217, 0.12);

        @include tablet {
            border-color: rgba(240, 240, 240, 1);
        }
    }
}