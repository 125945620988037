@import '@/styles/mixins';
@import '@/styles/variables';

.Typography {
  font-family: $ff-regular;
  margin: 0;

  &--IsBold {
    font-family: $ff-semiBold;
  }

  &--xsm {
    font-size: 14px;
    line-height: 20px;
  }

  &--sm {
    font-size: 20px;
    line-height: 28px;
  }

  &--md {
    font-size: 26px;
    line-height: 32px;

    @include mobile {
      font-size: 16px;
      line-height: 26px;
    }

    @include tablet {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &--lg {
    font-size: 36px;
    line-height: 44px;

    @include mobile {
      font-size: 16px;
      line-height: 26px;
    }

    @include tablet {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &--xlg {
    font-size: 40px;
    line-height: 48px;

    @include mobile {
      font-size: 32px;
      line-height: 36px;
    }
  }

  &--grey {
    color: rgba(8, 8, 8, 0.4);
  }

  &--dark {
    color: rgba(0, 0, 0, 1);
  }

  &--light {
    color: #fff;
  }

  &--Gradient {
    position: relative;
    display: inline-block;
    color: #fff;
    padding: 5px 7px;

    span {
      position: relative;
      z-index: 1;
    }

    &::after {
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 0;
      display: inline-block;
      border-radius: 8px;
      background-image: linear-gradient(133deg, #26BDED 0%, #31DEB6 48.78%, rgba(0, 235, 94, 0.99) 100%);
      box-shadow: 0 2px 6px 0 rgba(255, 255, 255, 0.60) inset, -2px 0px 6px 0px rgba(255, 255, 255, 0.60) inset;
      transform: rotate(-0.8deg)
    }
  }
}