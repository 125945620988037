@import '@/styles/mixins';
@import '@/styles/variables';

.PlanPrice {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0px 24px;

    @include mobile {
        padding: 0px 16px;

    }
}

.PlanPrice__Container {
    .TariffsPlans__Light & {
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px dashed rgba(255, 255, 255, 0.1);
        width: 100%;
        border-top-color: rgba(223, 223, 223, 1);
    }
}

.PlanPrice__Online {
    display: flex;
    gap: 24px;
}

.PlanPrice__OnlineInfo {
    display: flex;
    flex-grow: 1;
    min-width: 0;
    flex-direction: column;
    justify-content: center;
}

.PlanPrice__OnlineBtn {
    font-size: 16px;
    width: 353px;
    height: 64px;
    background: rgba(8, 8, 8, 0.08);
    color: rgb($black, 0.4);
    box-shadow: none;
}
