@import '@/styles/mixins';
@import '@/styles/variables';

.Logo {
    display: inline-flex;
    align-items: center;
    max-width: 340px;
    padding-top: 25px;
    padding-bottom: 25px;
    will-change: transform;
    transform: translateY(-100px);
    opacity: 0;
    transition: .5s;

    .Logo__icon {
        max-width: 100%;
    }

    @include mobile {
        display: block;
        padding: unset;
    }

    @include tablet {
        width: 262px;
    }

    .Header--Animate & {
        transform: translateY(0);
        opacity: 1;
    }
}