@import '@/styles/mixins';
@import '@/styles/variables';

.ChangePassword {
  @include tablet {
    width: 100%;
    background-color: $white;
    border-radius: 32px 32px 0 0;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 32px;
  }

  @include mobile {
    width: 100%;
    background-color: $white;
    border-radius: 20px 20px 0 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 20px;

    .ChangePassword__WrapBlock {
      padding-bottom: 0;

    }

  }
}

.ChangePassword__WrapBlock {
  background-color: transparent;
  margin-bottom: 32px;

  @include tablet {
    margin-bottom: 8px;
  }

  @include mobile {
    margin-bottom: 12px;
  }
}

.ChangePassword__Input {

  .RegistrationInput__Input {
    background: #FAFAFA;
    color: $black;

    &:not(.RegistrationInput__Input--Error) {
      border: 1px solid $gray-3;
    }


    &[disabled] {
      background: #FAFAFA;
      opacity: 1;
      color: $text-gray-light;
    }

    &::placeholder {
      color: #B4B4B4;
      font-weight: 400;
    }
  }
}

.ChangePassword__BlockInputs {
  max-width: 380px;

  @include tablet {
    max-width: 420px;

  }

  @include mobile {
    max-width: 100%;
  }
}

.ChangePassword__InputText {
  margin-bottom: 16px;
  background: #FAFAFA;
  border: 1px solid $gray-3;
  color: $black;

  &::placeholder {
    color: #B4B4B4;
    font-weight: 400;
  }

  @include tablet {
    max-width: 420px;
  }

}

.ChangePassword__RulesList {
  color: $white;

  .PasswordRules__Title {
    color: $white;
  }

  .PasswordRules::before {
    left: -19px;
  }

  @include tablet {
    .PasswordRules {
      position: absolute;
      left: auto;
      right: 0px;
      top: -12px;
    }

    .PasswordRules__Title {
      color: $black;
      border-bottom: 1px solid #B4B4B4;
      padding-bottom: 2px;
      font-size: 12px;

      svg {
        display: none;
      }
    }

    .PasswordRules__List {
      position: absolute;
      width: 225px;
      bottom: 35px;
      padding-top: 12px;
      right: 0;
      padding-left: 24px;
      padding-right: 12px;
      padding-bottom: 12px;

    }

    .PasswordRules__Item::before {
      left: -13px;
    }

    .PasswordRules__Item {
      font-size: 12px;
    }

    .PasswordRules__Close-icon {
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;
    }
  }

  @include mobile {
    .PasswordRules__Title {
      color: $black;
    }

    .PasswordRules__List-small-screen--active {
      bottom: calc(100% + 5px);
    }

    .PasswordRules__Title {
      border-bottom: 1px solid #B4B4B4;
    }
  }
}

.ChangePassword__RowButtons {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column;
  }
}

.ChangePassword__ButtonOpenModal {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $black;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 24px;
  border-bottom: 1px solid #CFCFCF;
  padding-bottom: 2px;

  @include mobile {
    margin-left: 0;
  }
}

.ChangePassword__ButtonEdit {
  filter: none;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-right: 44px;
  padding-left: 44px;

  &[disabled] {
    opacity: 0.34;
  }

  @include mobile {
    width: 100%;
    margin-bottom: 16px;
  }
}


.ChangePassword__Tooltip {
  @include mobile {
    width: 100%;
  }
}

.ChangePassword__InputWrap {
  position: relative;
  margin-bottom: 16px;

  .RegistrationInput__Label {
    font-weight: 400;
  }
}

.ChangePassword__ErrorMessage {
  position: absolute;
  bottom: 8px;
}
