@import "@/styles/_variables.scss";
@import "@/styles/_mixins.scss";

.Winners {
    position: relative;
    padding-bottom: 120px;
    @include tablet {
        padding-bottom: 100px;
    }
    @include mobile {
        padding-bottom: 60px;
    }
}

.Winners__Title {
    margin-bottom: 60px;
    text-align: center;
    font-family: $ff-semiBold;
    font-size: 100px;
    font-style: normal;
  
    line-height: 98px; 
}

h2.Winners__Title {
    font-size: 100px;
    @include tablet {
        font-size: 64px;
    }
    @include mobile {
        font-size: 32px;
        margin-bottom: 32px;
    }
}
.Winners__List {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    @include tablet {
        grid-template-columns: repeat(2, 1fr);
    }
    @include mobile {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 12px;
    }
}

.Winners__Dekor {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    &--Left {
        background-image: url(./assets/green-liquid.png);
        width: 900px;
        height: 1300px;
        left: calc(50% - 800px);
        top: -200px;
        @include tablet {
            left: calc(50% - 400px);
            width: 600px;
            height: 800px;
            top: -100px;
        }
        @include mobile {
            left: calc(50% - 250px);
            width: 400px;
            height: 650px;
            top: -200px;
        }
    }
    &--Right {
        background-image: url(./assets/blue-liquid.png);
        width: 800px;
        height: 1200px;
        right: calc(50% - 700px);
        bottom: -200px;
    }

    @include tablet {
     
    }
    @include mobile {
        
    }
}