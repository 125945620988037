@import '@/styles/mixins';
@import '@/styles/variables';

.PaidPlanForm__Radio-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 54px;

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
    }
}

.PaidPlanForm__Radio {
    color: $text-white;

    .Radio__Box--active::before {
        background-color: #1a1a1a;
    }
}

.PaidPlanForm__Title {
    font-size: 18px;
    font-family: $ff-semiBold;
    padding-bottom: 24px;

    @include mobile {
        font-size: 16px;
    }

    .PlanFeatures--light & {
        color: $text-black;
    }

    .PlanFeatures--dark & {
        color: $text-white;
    }
}

.PaidPlanForm__Price-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        height: 110px;
        gap: 20px;
    }
}

.PaidPlanForm__Notes {
    display: grid;
    grid-template-columns: 32px 1fr;
    gap: 10px;
    padding: 12px;
    margin-bottom: 55px;
    margin-top: -38px;
    border-radius: 20px;
    background-color: #373737;
    color: #F0F0F0;
    font-size: 14px;
    line-height: 20px;
    white-space: break-spaces;

    &--finsihed {
        margin-bottom: 16px;
        background-color: transparent;
        align-items: center;
        opacity: 0.7;
        line-height: 16px;
    }

    @include mobile {
        font-size: 12px;
        line-height: 17px;
        white-space: unset;
    }

    @include tablet {
        white-space: unset;
    }
}

.PaidPlanForm__Btn {
    width: 353px;
    height: 64px;

    &.Button--disabled {
        background-color: rgba(255, 255, 255, 0.15);
        background-image: unset;
        box-shadow: unset;
    }

    @include mobile {
        width: 100%;
        height: 56px;
    }

    @include tablet {
        width: 329px;
    }
}

.PaidPlanForm__Tooltip {
    z-index: 10;
    
    @include mobile {
        width: 100%;
    }
}

.PaidPlanForm__Tag {
    @include mobile {
        order: -1;
    }
}