@import '@/styles/mixins';
@import '@/styles/variables';

.OnlineHub {
    margin-bottom: 80px;
    @include mobile {
        margin-bottom: 60px;
    }
}

.OnlineHub__Title {
    margin-top: 0;
    margin-bottom: 20px;
    font-family: $ff-semiBold;
    font-size: 100px;
    font-style: normal;
    line-height: 98px;
    color: $white;

    @include tablet {
        font-size: 64px;
        line-height: normal;
    }

    @include mobile {
        font-size: 32px;
        line-height: normal;
        margin-bottom: 12px;
    }
}

.OnlineHub__Container {
    position: relative;
    &::before,
    &::after {
        content: "";
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;
        z-index: 0;
    } 

    &::before {
        background-image: url(./assets/decor-circle-left.svg);
        width: 250px;
        height: 320px;
        top: 250px;
        left: -150px;
    }
    &::after {
        background-image: url(./assets/decor-circle-right.svg);
        width: 500px;
        height: 502px;
        top: -150px;
        right: -250px;
    }

    @include mobile {
        &::before {
            display: none;
        }
        &::after {
            top: -30px;
            width: 270px;
            height: 270px;
            right: -60px;
        }
    }
}

.OnlineHub__SubTitle {
    max-width: 823px;
    margin: 0 auto;
    margin-bottom: 60px;
    text-align: center;
    font-size: 20px;
    font-family: $ff-regular;
    line-height: 28px;
    color: rgba($white, .6);

    @include tablet {
        margin-bottom: 40px;
        max-width: 395px;
        font-size: 18px;
        line-height: 26px;
    }

    @include mobile {
        max-width: 320px;
        margin-bottom: 32px;
        font-size: 16px;
        font-style: normal;
        line-height: 22px;
       
    }
}


.OnlineHub__Text {
    position: relative;
    font-size: 64px;
    font-style: normal;
    font-family: $ff-semiBold;
    line-height: 84px;
    text-align: center;
    margin-bottom: 60px;

    &::before {
        content: "";
        position: absolute;
        width: 1600px;
        height: 1000px;
        background-image: url(./assets/green-fog.svg);
        background-position: center;
        background-repeat: no-repeat;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }

    @include tablet {
        max-width: 728px;
        margin: 0 auto;
        margin-bottom: 40px;
        font-size: 48px;
        font-style: normal;
        font-family: $ff-semiBold;
        line-height: 62px;

    }

    @include mobile {
        font-size: 28px;
        line-height: 36px;
        margin: 0 auto;
        max-width: 100%;
        margin-bottom: 30px;
    }
}


.OnlineHub__TextIntro {
    font-size: 64px;
    font-style: normal;
    font-family: $ff-semiBold;
    line-height: 84px;

    span {
        position: relative;
    }

    &::after {
        border-radius: 24px;
        width: 105%;
        left: 50%;
        transform: rotate(-0.8deg) translateX(-50%);
    }

    @include tablet {
        font-size: 48px;
        font-style: normal;
        font-family: $ff-semiBold;
        line-height: 62px;

    }

    @include mobile {
        font-size: 28px;
        line-height: 36px;

        &::after {
            border-radius: 10px;
        }
    }
}

.OnlineHub__WrapButton {
    display: flex;
    justify-content: center;
}

.OnlineHub__SubTitleDekorLine {
    position: relative;
    display: inline-block;
    line-height: normal;

    &::after {
        content: "";
        position: relative;
        display: block;
        width: 100%;
        height: 9px;
        bottom: 0px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(./assets/line-subtitile.svg);
    }

    @include mobile {

        &::after {
            height: 5px;
        }

        &--FirstLine::after {
            background-image: url(./assets/line-subtitile-mobile-first.svg);
        }
        &--Second::after {
            background-image: url(./assets/line-subtitile-mobile-second.svg);
        }
    }
}

.OnlineHub__TextGradient {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(103deg, #26BDED -4.75%, #31DEB6 46.35%, rgba(0, 235, 94, 0.99) 100%)
}

.OnlineHub__Link {
    font-family: $ff-semiBold;
    position: relative;
    z-index: 2;

    @include mobile {
        padding-top: 14px;
        padding-bottom: 14px;
        font-size: 16px;
        line-height: 26px;
        width: 100%;

        svg {
            width: 24px;
            height: 24px;
        }
    }

}