@import '@/styles/mixins';
@import '@/styles/variables';

.Certificates {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 20px 16px;

    @include mobile {
        background-color: $white;
        border-radius: 26px 26px 0 0;
        min-height: 454px;
        gap: 24px;
    }

    @include tablet {
        background-color: $white;
        border-radius: 26px 26px 0 0;
        min-height: 454px;
    }
}

.Certificates__Header {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.Certificates__Title {
    font-size: 36px;
    font-family: $ff-semiBold;

    @include mobile {
        font-size: 20px;
    }
}

.Certificates__Text {
    @include mobile {
        font-size: 12px;
    }

    @include tablet {
        font-size: 14px;
    }
}