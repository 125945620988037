@import '@/styles/mixins';
@import '@/styles/variables';

.ChatHeader {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 50px;
    padding: 16px 20px 0 20px;
    border-radius: 24px 24px 0 0;
    transition: background-color 0.3s;
    background-color: $white;

    &--expanded {
        background-color: $gray-4;
    }

    &--for-moderators {
        height: 68px;
        padding: 20px 24px;
    }
}

.ChatHeader__Section {
    position: relative;
    color: $text-gray;
    padding-bottom: 14px;
    list-style: none;
    cursor: pointer;

    .ChatHeader--for-moderators & {
        font-size: 24px;
        padding: unset;

        @include mobile {
            font-size: 14px;
            max-width: 70px;
            line-height: 16px;
        }
    }

    &--active {
        font-family: $ff-semiBold;
        color: $text-black;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            border-radius: 24px;
            background-image: $box-gradient-green;

            .ChatHeader--for-moderators & {
                display: none;
            }
        }
    }

    &:hover {
        @include desktop {
            opacity: 0.6;
        }
    }
}

.ChatHeader__Collapsed-Tite {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 20px;
    font-family: $ff-semiBold;
    color: $text-black;
}

.ChatHeader__Collapsed-Icon {
    position: absolute;
    top: 26px;
    right: 20px;
    transform: translateY(-50%);
    font-size: 20px;

    &>svg {
        transition: 0.3s;
    }

    .ChatHeader--expanded & {
        &>svg {
            transform: rotate(180deg);
        }
    }

    @include desktop {
        display: none !important;
    }
}

.ChatHeader__Stream-Num {
    font-size: 24px;
    font-family: $ff-semiBold;
    color: $text-black;

    @include mobile {
        font-size: 14px;
    }
}

.ChatHeader__Question-Sum {
    font-size: 16px;
    color: $text-gray;

    @include mobile {
        display: none;
    }
}

.ChatHeader__Filter {
    padding-bottom: 14px;
}

.ChatHeader__Filter-Moderators {
    margin-left: unset;
}

.ChatHeader__Clear-Btn {
    border: none;
    background-color: $white;
    border-radius: 8px;
    padding: 6px 12px;
    color: $text-black;
    box-shadow: 0px 2px 10px #c6c6c6;
    cursor: pointer;
    transition: transform 0.3s;

    &:active {
        transform: scale(0.95);
    }

    @include mobile {
        font-size: 14px;
    }
}

.ChatHeader__Moderators-Controls {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-left: auto;
}

.ChatHeader__Popup {
    &>.Popup__Box {
        width: 428px;
        height: 300px;
        border-radius: 32px;
        padding: 70px 55px;

        @include mobile {
            width: 100%;
            height: fit-content;
            border-radius: 24px 24px 0 0;
            padding: 80px 24px 24px 24px;
        }
    }
}