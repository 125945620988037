@import '@/styles/mixins';
@import '@/styles/variables';

.ChatBox__Wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $white;
    flex-grow: 1;
    z-index: 1;

    &--for-moderators {
        border-radius: 0 0 24px 24px;
    }
}

.ChatBox__Pin {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 65px;
    z-index: 1;
}

.ChatBox__Container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 4px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.3s;

    @include custom-scrollbar;
}

.ChatBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 6px;
    flex-grow: 1;
    padding: 16px;
    padding-top: 80px;

    &--empty {
        align-items: center;
        justify-content: center;
    }

    .ChatBox__Wrapper--for-moderators & {
        padding-bottom: 24px;
    }
}

.ChatBox__Empty {
    color: $text-gray;
    font-size: 14px;
}

.ChatBox__ScrollBtn {
    position: absolute;
    left: 50%;
    bottom: 12px;
    transform: translateX(-50%);
}