@import '@/styles/mixins';
@import '@/styles/variables';

.Tariffs {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include tablet {
        gap: 12px;
        padding: 40px 20px 20px;
        background-color: #fff;
        border-radius: 40px;
    }

    @include mobile {
        gap: 12px;
        padding: 20px 20px;
        background-color: #fff;
        border-radius: 26px;
    }
}

.Tariffs__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    @include mobile {
        margin-bottom: 13px;
    }
}

.Tariffs__Title {
    font-size: 36px;
    font-family: $ff-semiBold;
    color: $text-black;

    @include mobile {
        font-size: 20px;
    }

    @include tablet {
        font-size: 32px;
    }
}

.Tariffs__Title-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #E6E6E6;
    cursor: pointer;
    visibility: hidden;
    user-select: none;

    @include mobile {
        visibility: visible;
        user-select: unset;
        width: 32px;
        height: 32px;

        &>svg {
            width: 12px;
        }
    }

    @include tablet {
        visibility: visible;
        user-select: unset;
    }
}

.Tariffs__StatusWarn {
    display: flex;
    gap: 15px;
}

.Tariffs__StatusWarnContent {
    flex-grow: 1;
    min-width: 0;
}

.Tariffs__StatusWarnAction {
    flex-shrink: 0;
}

.Tariffs__StatusWarnBtn {
    height: 40px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
    background: #fff;
    border: none;
    appearance: none;
    border-radius: 8px;
    cursor: pointer;
}
