@import '@/styles/mixins';
@import '@/styles/variables';

.VideoPlayer {
    position: relative;
    width: 100%;
    height: 100%;
}

.VideoPlayer__Video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}