@import '@/styles/mixins';
@import '@/styles/variables';

.ExpertCard {
    position: relative;
    border-radius: 32px;
    background-image: linear-gradient(to right, rgba(38, 189, 237, 0.12), rgba(49, 222, 182, 0.12), rgba(0, 235, 94, 0.12));
    overflow: hidden;
    @include fadeIn-animation;

    @include mobile {
        height: 436px;
        min-width: 240px;
    }
}

.ExpertCard__img-container {
    width: 100%;
    height: 100%;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: $text-gradient-green;
        opacity: 0;
        transition: 0.3s;

        .ExpertCard:hover & {
            opacity: 0.4;
        }
    }
}

.ExpertCard__img {
    @include fadeIn-animation($duration: 0.8s);
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.ExpertCard__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    bottom: 8px;
    left: 8px;
    right: 8px;
    height: 96px;
    border-radius: 24px;
    padding: 25px;
    background-color: #fff;
    transition: 0.5s;

    @include mobile {
        height: 90px;
        justify-content: center;
        padding: 20px 16px;
    }

    &:hover {
        height: 296px;

        @include mobile {
            height: 90px;
        }

        &>.ExpertCard__text {
            display: block;
        }


    }
}

.ExpertCard__SurName,
.ExpertCard__FirstName {
    font-family: $ff-semiBold;
    font-size: 20px;
    line-height: 24px;
    color: $text-black;
    text-transform: uppercase;
    transition: 0.3s;

    @include mobile {
        font-size: 18px;
        line-height: 26px;
    }

    @include tablet {
        font-size: 18px;
        line-height: 26px;
    }
}

.ExpertCard__SurName {
    @include text-colored($text-gradient-green);

    &--mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
    }
}

.ExpertCard__text {
    display: none;
    font-family: $ff-regular;
    font-size: 16px;
    line-height: 24px;
    color: $text-black;
    margin: 8px 0 0 0;
    @include fadeIn-animation(0.6s);

    @include mobile {
        font-size: 16px;
        line-height: 22px;
        margin-top: 8px;
        opacity: 0.6;
    }

    &--mobile {
        display: block;
        animation: unset;
    }
}