@import '@/styles/mixins';
@import '@/styles/variables';

.Radio {
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgb(8, 8, 8);

  @include mobile {
    display: grid;
    grid-template-columns: auto 1fr;
  }
}

.Radio__Input {
  display: none;
}

.Radio__Box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid rgba(180, 180, 180, 1);
  transition: 0.2s ease transform, 1s background, 0.2s ease box-shadow;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 50%;
    width: 16px;
    height: 16px;
    background-color: transparent;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    transition: 0.2s ease width, 0.2s ease height;
  }

  &:hover {
    background: linear-gradient(103.48deg, #26BDED -4.75%, #31DEB6 46.35%, rgba(0, 235, 94, 0.9932) 100%);
    border-color: transparent;
    scale: 1.1;

    &::before {
      width: 10px;
      height: 10px;
      background-color: #080808;
    }
  }

  .Radio--Error & {
    background: linear-gradient(rgba(191, 18, 22, 0.95), rgba(191, 18, 22, 1), rgba(243, 69, 73, 0.99))
  }

  &--active {
    background: linear-gradient(103.48deg, #26BDED -4.75%, #31DEB6 46.35%, rgba(0, 235, 94, 0.9932) 100%);
    border-color: transparent;
    scale: 1.1;

    &::before {
      width: 10px;
      height: 10px;
      background-color: #07090a;
    }
  }
}

.Radio__Description {
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  cursor: pointer;

  &--active {
    color: $text-white;
  }

  @include mobile {
    display: unset;
    padding-left: unset;
    line-height: 20px;
  }
}
