@import '@/styles/mixins';
@import '@/styles/variables';

.FromEducation__Warning {
    display: flex;
    gap: 16px;
    padding: 16px;
    border-radius: 20px;
    margin-bottom: 20px;
    background-color: rgba(38, 42, 45, 0.80);
    color: $text-gray;
    font-size: 14px;
    line-height: 20px;
    white-space: break-spaces;

    @include tablet {
        width: 400px;
        white-space: unset;
    }
}

.FromEducation__WarningContent {
    min-width: 0;
    flex-grow: 1;
}

.FromEducation__Warning-links-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}

.FromEducation__Warning-link {
    text-decoration: none;
    color: $text-white;
    font-family: $ff-semiBold;
    padding-bottom: 2px;
    border-bottom: 1px solid rgba(207, 207, 207, 0.3);
}

.FromEducation__Header-promo-code,
.FromEducation__Header {
    margin-top: 50px;
    margin-bottom: 20px;
}

.FromEducation__Header-promo-code {
    margin-top: 00px;

    .AuthorizationFormHeader__Logo {
        margin: unset;
    }

    .AuthorizationFormHeader__Title {
        font-size: 27px;

        @include mobile {
            font-size: 23px;
        }
    }

    .AuthorizationFormHeader__Subtitle {
        font-size: 14px;
    }

    .AuthorizationFormHeader__Logo-icon {
        width: 120px;

        &>img {
            width: 100%;
        }
    }
}

.FromEducation__Form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.FromEducation__Accommodation-note {
    font-size: 14px;
    color: rgba(255, 127, 0, 1);
    margin-bottom: 20px;
    text-align: center;
}