@import '@/styles/mixins';
@import '@/styles/variables';

.ButtonRefreshPassword {
  margin-top: auto;
  padding-top: 20px;
  padding-bottom: 20px;

  @include tablet {
    max-width: 233px;
    margin: auto auto 0;
    font-size: 16px;
    width: 100%;
  }
}

.PopupRefreshPassword__Title {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 144px;

  @include tablet {
    font-size: 24px;
  }

  @include mobile {
    font-size: 24px;
  }
}

.PopupRefreshPassword__Text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  max-width: 347px;
  margin: 0 auto;
  color: #717171;

  @include tablet {
    color: $black;
    max-width: 328px;
  }
}