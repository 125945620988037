@import '@/styles/mixins';
@import '@/styles/variables';

.SpeakerPopupContent__Wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-right: 10px;
    overflow-y: auto;
    height: 100%;

    @include custom-scrollbar;
}

.SpeakerPopupContent__Header {
    display: grid;
    grid-template-columns: 290px 1fr;
    gap: 32px;
    align-items: center;
    justify-content: space-between;

    @include mobile {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

.SpeakerPopupContent__img-container {
    width: 290px;
    height: 330px;
    border-radius: 28px;
    background-image: linear-gradient(to right, rgba(38, 189, 237, 0.12), rgba(49, 222, 182, 0.12), rgba(0, 235, 94, 0.12));
    overflow: hidden;

    @include mobile {
        width: 210px;
        height: 250px;
    }
}

.SpeakerPopupContent__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.SpeakerPopupContent__Name {
    font-size: 40px;
    font-family: $ff-semiBold;

    @include mobile {
        font-size: 28px;
        text-align: center;
    }
}

.SpeakerPopupContent__Text {
    font-size: 20px;
    color: $text-gray;
    margin-top: 12px;

    @include mobile {
        font-size: 16px;
        text-align: center;
    }
}

.SpeakerPopupContent__Details {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 20px;
    background-color: #FAFAFA;
    font-size: 20px;
    line-height: 28px;
    color: $text-black;
    border-radius: 20px;

    & ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    & li {
        list-style-type: disc;
        list-style-position: inside;
        padding-left: 15px;
    }

    @include mobile {
        font-size: 16px;
        padding: 16px;
    }
}

.SpeakerPopupContent__Sessions-title {
    color: $text-gray-light;
    font-size: 24px;
    font-family: $ff-semiBold;
    margin-bottom: 16px;

    @include mobile {
        font-size: 16px;
    }
}