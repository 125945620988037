@import '@/styles/mixins';
@import '@/styles/variables';

.ChatInput__Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 1px solid #EEEEEE;
    background-color: $white;
    border-radius: 0 0 24px 24px;
    transition: 0.3s;

    &--focused {
        background-color: #FAFAFA;
    }

    &--error {
        background-color: rgba(254, 243, 243, 1);

        &>.ChatInput__Form {
            padding-bottom: 0px;
        }
    }

    &--disabled {
        filter: brightness(0.95);
    }
}

.ChatInput__Form {
    position: relative;
    padding: 12px 4px 12px 0px;
    flex-grow: 1;
}

.ChatInput {
    outline: unset;
    border: unset;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    padding-left: 20px;
    padding-right: 40px;
    background-color: transparent;
    resize: none;
    overflow-x: hidden;

    @include custom-scrollbar;

    &::placeholder {
        color: $text-gray;
    }
}

.ChatInput__Btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: unset;
    background: unset;
    bottom: 16px;
    right: 20px;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
}

.ChatInput__Error {
    font-size: 12px;
    color: #F1161C;
    text-align: right;
    padding-bottom: 12px;
    padding-right: 40px;
}