@import '@/styles/variables';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  font-family: $ff-base, 'SBSansDisplay-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
}

html {
  // to remove a defect when tapping on mobile devices
  -webkit-tap-highlight-color: transparent;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
