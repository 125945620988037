@import '@/styles/mixins';
@import '@/styles/variables';

.Events__List {
    list-style: none;
    margin: 0;
    padding: 0;

    @include mobile {
        margin-top: 30px;
    }

    h3 {
        text-align: left;
    }
}

.Events__Decoration1,
.Events__Decoration2 {
    position: absolute;
    z-index: 0;
    pointer-events: none;
}

.Events__Decoration1 {
    left: calc(50% - 668px);
    top: -105px;

    @include mobile {
        left: -40px;
        top: -63px;
        width: 150px;
    }

    @include tablet {
        left: -70px;
        top: -85px;
        width: 275px;
    }
}

.Events__Decoration2 {
    right: -30px;
    top: 187px;

    @include mobile {
        right: -80px;
        top: 75px;
        width: 190px;
    }

    @include tablet {
        right: -110px;
        top: 145px;
        width: 300px;
    }
}

.Events__Item {
    display: grid;
    grid-template-columns: 65px 1fr;
    gap: 24px;
    margin: 0 0 80px;
    opacity: 0;
    will-change: transform;
    transform: translate(0, 100px);
    transition: transform .5s;

    @include mobile {
        margin: 0 0 24px;
        grid-template-columns: 30px 1fr;
        gap: 12px;
    }

    @include tablet {
        margin: 0 0 40px;
    }

    &:last-child {
        margin: 0;
    }

    &.Animate {
        opacity: 1;
        transform: translate(0, 0);
    }

    &--1 {
        opacity: 0;
    }

    &--2 {
        opacity: 0;
    }

    &--3 {
        opacity: 0;
    }

    &--4 {
        opacity: 0;
    }
}

.Events__List-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 78px;
    padding: 0 2px;

    @include mobile {
        height: 36px;
    }

    &>svg {
        width: 100%;
    }
}