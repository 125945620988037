@import '@/styles/mixins';
@import '@/styles/variables';

.LiveChatFallback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 12px;
    background-color: $white;
    border-radius: 24px;
    height: 100% !important;
    color: $text-black;
    text-align: center;

    @include mobile {
        height: 500px !important;
    }

    @include tablet {
        height: 490px !important;
    }
}