@import '@/styles/mixins';
@import '@/styles/variables';

.PhoneInput {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.PhoneInput__Input {
    height: 64px;
    width: 100%;
    padding: 22px 24px;
    border-radius: 48px;
    font-size: 14px;
    line-height: 24px;
    color: $text-white;
    border: 1px solid transparent;
    outline: none;

    appearance: none;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background: radial-gradient(108.33% 108.33% at 50.00% 47.45%, rgba(84, 94, 102, 0.00) 0%, rgba(84, 94, 102, 0.24) 100%);
    box-shadow: 6px 0 28px 0 rgba(255, 255, 255, 0.08) inset, 0 6px 8px 0 rgba(255, 255, 255, 0.02) inset, -6px 0px 8px 0px rgba(255, 255, 255, 0.02) inset, 0px -6px 8px 0px rgba(255, 255, 255, 0.08) inset;
    backdrop-filter: blur(48px);

    &::placeholder {
        color: $text-gray;
    }

    &:hover:enabled,
    &:focus:enabled {
        border-color: #DFDFDF;
        background-color: rgb(255, 255, 255, 0.02);
    }

    &:disabled,
    &[readonly] {
        opacity: 0.6;
    }

    &--Error {
        border-color: rgba(191, 18, 22, 0.95);
        background-color: rgba(223, 51, 55, 0.05);
    }

    @include mobile {
        height: 56px;
    }
}

.PhoneInput__Label {
    color: $text-gray;
    font-size: 14px;
    font-weight: 700;

    &::first-letter {
        text-transform: capitalize;
    }
}
