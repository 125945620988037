@import '@/styles/mixins';
@import '@/styles/variables';

.SessionMedia__Wrapper {
    position: relative;
}

.SessionMedia {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: fit-content;
    padding: 8px 20px;
    border-radius: 48px;
    height: 52px;
    border: none;
    color: $text-black;
    font-size: 20px;
    font-family: $ff-semiBold;
    white-space: nowrap;
    background: radial-gradient(111.94% 111.98% at 99.97% -0.09%, rgb(49 222 182 / 12%) 0%, rgb(49 222 182 / 12%) 100%);
    cursor: pointer;
    appearance: none;
    box-shadow: none;

    &:hover {
        opacity: 0.7;
    }

    &:active {
        transform: scale(0.98);
    }

    @include mobile {
        font-size: 16px;

        &>svg {
            width: 20px;
            height: 20px;
        }
    }
}

.SessionMedia__Dropdown {
    position: absolute;
    top: calc(100% + 8px);
    left: -10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 20px;
    background-color: rgba(8, 8, 8, 0.94);
    z-index: 10;

    @include mobile {
        left: 0;
        width: fit-content;
    }

    &--to-top {
        top: unset;
        bottom: calc(100% + 8px);
        @include fadeIn-fromBottom-animation($duration: 0.5s);
    }

    @include fadeIn-fromTop-animation($duration: 0.5s);
}

.SessionMedia__Dropdown-Title {
    font-size: 14px;
    font-family: $ff-semiBold;
    color: $white;

    @include mobile {
        font-size: 12px;
    }
}

.SessionMedia__Dropdown-Menu {
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style: none;

    @include mobile {
        font-size: 12px;
    }
}

.SessionMedia__Dropdown-Menu-Link {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 6px;
    border-bottom: 1px solid #717171;
    color: $text-white;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    text-decoration: none;
}

.SessionMedia__Popup {
    & .Popup__Box {
        aspect-ratio: 16 / 9;
        width: 100%;
        max-width: 1200px;
        border-radius: 20px;
        padding: 50px;
        background-color: transparent;

        @include mobile {
            padding: 20px;
        }

        @include tablet {
            padding: 20px;
        }
    }

    & .Popup__CloseBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50px;
        border-radius: 50%;
        background-color: $white;

        @include mobile {
            top: -10px;
            right: 20px;
            width: 25px;
            height: 25px;
        }

        @include tablet {
            top: -10px;
            right: 20px;
            width: 25px;
            height: 25px;
        }

        & path {
            fill: $black;
        }

        & rect {
            fill: $white;
        }
    }
}

.SessionMedia__Iframe {
    border-radius: 20px;
    overflow: hidden;

    @include mobile {
        border-radius: 8px;
    }
}