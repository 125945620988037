@import '@/styles/mixins';
@import '@/styles/variables';

.Intro {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: $ff-semiBold;
  color: #ffffff;
  padding-top: 200px;
  margin-bottom: 165px;
  z-index: 1;

  @include mobile {
    padding-top: 80px;
    margin-bottom: 60px;
  }

  @include tablet {
    padding-top: 100px;
    margin-bottom: 120px;
  }
}

.Intro__Decoration01 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.Intro__Decoration02 {
  position: absolute;
  top: 0px;
  right: 0;
  width: 500px;
  z-index: -1;

  @include mobile {
    width: 300px;
  }

  @include tablet {
    width: 300px;
  }
}

.Intro__Decoration03 {
  position: absolute;
  top: 0;
  left: 0;
  width: 760px;
  z-index: -1;

  @include mobile {
    width: 300px;
  }

  @include tablet {
    width: 600px;
  }
}

.Intro__Inner {
  position: relative;
  width: 100%;

  @include mobile {
    padding-top: 75px;
  }

  @include tablet {
    padding-top: 80px;
  }
}

.Intro__DateLabel {
  margin-bottom: 6vh;
  opacity: 0;
  will-change: opacity;
  transition: all 1s .5s;

  .Intro--Animate & {
    opacity: 1;
  }

  @include mobile {
    margin-top: 35px;
    margin-bottom: 12px;
  }

  @include tablet {
    display: inline-flex;
    align-items: center;
    height: 48px;
    margin-top: 8px;
    margin-bottom: 56px;
  }
}

.Intro__Content {
  max-width: 700px;
  position: relative;
  z-index: 2;
}

.Intro__Title {
  font-size: 100px;
  line-height: 98px;
  letter-spacing: -1px;
  margin: 0 0 3vh 0;

  @include mobile {
    font-size: 48px;
    line-height: 49px;
  }

  @include tablet {
    font-size: 86px;
    line-height: 75px;
    margin: 0 0 20px 0;
  }
}

.Intro__Gradient {
  @include text-colored($text-gradient-green)
}

.Intro__Icon {
  position: absolute;
  right: 3px;
  bottom: -7px;
  z-index: 1;
  max-width: 617px;
  opacity: 0;
  will-change: transform, opacity;
  transform: scale(.8);
  transition: transform 1s .2s ease, opacity 1s .2s ease;

  @include mobile {
    top: 0px;
    right: 0px;
    bottom: unset;
  }

  @include tablet {
    top: 50px;
    right: 0px;
    max-width: 400px;
  }

  .Intro--Animate & {
    opacity: 1;
    transform: scale(1);
  }
}

.Intro__Description {
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 6vh;
  opacity: 0;
  transition: opacity 1s .5s;
  color: $text-gray-light;

  @include mobile {
    font-size: 14px;
    line-height: 22px;
  }

  @include tablet {
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 32px;
  }

  .Intro--Animate & {
    opacity: 1;
  }
}

.Intro__Wrapper {
  position: relative;
}

.Intro__Button {
  opacity: 0;
  line-height: 0;
  width: fit-content;
  padding: 25px 48px;
  transition: opacity 1s .5s, box-shadow .5s;

  .Intro--Animate & {
    opacity: 1;
  }

  @include mobile {
    gap: 8px;
    height: 56px;
    width: 274px;
    font-size: 16px;
    line-height: 26px;
  }

  @include tablet {
    gap: 12px;
    height: 76px;
    padding: 20px 40px;
    font-size: 18px;
    line-height: 26px;
  }

  & .Button__Icon {
    @include mobile {
      padding-right: unset;
    }

    @include tablet {
      padding-right: unset;
    }
  }
}


.Intro__Animated-Wrapper {
  display: inline-block;
  overflow: hidden;
  overflow: clip;
  margin: -0.2em;
  padding: 0.2em;

  &--1 {
    .Intro__SubTitle {
      transition: transform .5s 0s;
    }
  }

  &--2 {
    .Intro__SubTitle {
      transition: transform .5s .1s;
    }
  }

  &--3 {
    .Intro__SubTitle {
      transition: transform .5s .2s;
    }
  }
}

.Intro__SubTitle {
  display: inline-block;
  margin: -0.2em;
  padding: 0.2em;
  transform: translate(0, 100%);

  .Intro--Animate & {
    transform: none;
  }
}

.Intro__SubTitle-firstWord {
  @include tablet {
    margin-bottom: -6px;
  }
}

.Intro__HashLink {
  text-decoration: none;
}