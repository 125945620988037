@import '@/styles/mixins';
@import '@/styles/variables';

.AuthorizationBtn {
    position: relative;

    @include mobile {
        border-radius: 50%;
        border: 3px solid rgb(255, 255, 255, 0.2);
    }
}

.AuthorizationBtn__Button {
    height: 48px;
    padding: 12px 20px;
    font-weight: 600;
    line-height: unset;
    transform: translateY(-100px);
    opacity: 0;
    transition: transform .5s .6s;

    @include mobile {
        padding: unset;
        gap: unset;
        height: 34px;
        width: 34px;
        border-radius: 50%;
    }

    .Header--Animate & {
        transform: translateY(0);
        opacity: 1;
    }
}

.AuthorizationBtn__Links {
    width: 220px;

    & .DropdownMenu__Link {
        opacity: 1;
        text-transform: lowercase;

        &::first-letter {
            text-transform: capitalize;
        }
    }
}