@import '@/styles/mixins';
@import '@/styles/variables';

.ProgramSlider {
    position: relative;
    @include mobile {
        margin-bottom: 60px;
    }
}

.ProgramSlider__Slider-Btns {
    position: absolute;
    right: 16px;
    top: calc(100% + 24px);
    display: none;
    align-items: center;
    gap: 20px;
    z-index: 1;

    @include mobile {
        display: flex;
        top: calc(100% + 14px);
        gap: 16px;
    }
}

.ProgramSlider__Slider-Next,
.ProgramSlider__Slider-Prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    border: 1px solid #C3CFCC;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }

    @include mobile {
        width: 32px;
        height: 32px;

        & svg {
            width: 13px;
        }

        &:hover {
            opacity: unset;
        }
    }

    &.swiper-button-disabled {
        opacity: 0.5;
        user-select: none;
        cursor: unset;
    }
}

.ProgramSlider__Slider-Prev {
    & svg {
        transform: rotate(180deg);
    }
}

.ProgramSlider__MultipleSessions {
    width: 377px;

    @include mobile {
        width: 300px;
    }
    
    @include tablet {
        width: 626px;
    }
}

.ProgramSlider__Slider {
    width: fit-content !important;
    height: auto;
}

.ProgramSlider__TextInfo {
    font-size: 16px;
    position: absolute;
    right: 130px;
    bottom: -40px;
    display: none;
    color: $black;
    @include mobile {
        display: block;
    }
}