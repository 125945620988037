@import '@/styles/mixins';
@import '@/styles/variables';

.ExpandBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #CFCFCF;
    background-color: unset;
    cursor: pointer;

    @include mobile {
        width: 26px;
        height: 26px;

        & > svg {
            width: 11px;
        }
    }
}