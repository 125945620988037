@import '@/styles/mixins';
@import '@/styles/variables';

.Header {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;

  &--Scroll {
    position: fixed;
  }
}

.Header__Container {
  position: relative;
}

.Header__Wrapper {
  transform: translateY(0);
  border-radius: 0;
  will-change: background, border-radius;
  transition: background .5s, border-radius .5s;

  .Header--Scroll & {
    background: radial-gradient(698.17% 323.49% at 101.43% -226.53%, rgba(0, 135, 205, 0.32) 0%, rgba(0, 135, 205, 0.00) 100%), radial-gradient(695.48% 331.14% at -0.95% -251.02%, rgba(33, 160, 56, 0.32) 0%, rgba(33, 160, 56, 0.00) 100%), rgba(8, 8, 8, 0.98);
  }
}

.Header__Inner {
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  gap: 58px;
  width: 100%;
  border-bottom: 1px solid rgba(#fff, 8%);

  @include mobile {
    -webkit-align-items:center;
    align-items: center;
    height: 64px;
    padding-top: unset;
    padding-bottom: unset;
    border-bottom-color: transparent;
  }

  @include tablet {
    border-bottom-color: transparent;
  }

  @include custom-screen(992px, 1080px) {
    gap: 28px;
  }
}