@import "@/styles/mixins";
@import "@/styles/variables";

.EvaluateProjects {
    position: relative;
}

.EvaluateProjects__Title {
    margin-bottom: 80px;
    white-space: break-spaces;
    position: relative;
    @include mobile {
        font-size: 32px !important;
        line-height: 34px !important;
        margin-bottom: 32px;
        white-space: unset;
    }

    @include tablet {
        font-size: 60px !important;
        line-height: 70px !important;
        margin-bottom: 60px;
    }

    @include custom-screen($min: 992px, $max: 1200px) {
        font-size: 80px !important;
        line-height: 90px !important;
    }
}

.EvaluateProjects__List {
    list-style: none;
    padding-left: 80px;

    @include mobile {
        padding-left: 40px;
    }

    @include tablet {
        margin-bottom: 60px;
    }
}

.EvaluateProjects__List-Item {
    @include text-colored($text-gradient-green);
    position: relative;
    margin-bottom: 60px;
    font-size: 70px;
    line-height: 78px;
    font-weight: 500;

    &::before {
        content: "—";
        position: absolute;
        left: -80px;
        font-size: 70px;
        font-family: $ff-semiBold;
        @include text-colored($text-gradient-green);

        @include mobile {
            left: -40px;
            font-size: 35px;
        }
    }

    &--1 {
        opacity: 1;
    }

    &--2 {
        opacity: 0.6;
    }

    &--3 {
        opacity: 0.4;
    }

    &--4 {
        opacity: 0.2;
    }

    &--5 {
        opacity: 0.1;
    }

    @include mobile {
        margin-bottom: 24px;
        font-size: 28px;
        line-height: 32px;
    }

    @include tablet {
        margin-bottom: 40px;
        font-size: 56px;
        line-height: 64px;
    }
}

.EvaluateProjects__Decoration04 {
    position: absolute;
    top: 0px;
    left: -121px;
    pointer-events: none;
    @include mobile {
        top: 30px;
        left: -60px;
        width: 110px;
    }

    @include tablet {
        top: 20px;
        left: -45px;
        width: 145px;
    }
}

.EvaluateProjects__Decoration05 {
    position: absolute;
    top: 245px;
    right: 0;
    pointer-events: none;
    @include mobile {
        top: 50px;
        right: -80px;
        width: 170px;
    }

    @include tablet {
        top: 100px;
        right: -90px;
        width: 255px;
    }

    @include custom-screen($min: 992px, $max: 1200px) {
        top: 175px;
        right: -90px;
        width: 400px;
    }
}
.EvaluateProjects__Decoration06 {
    position: absolute;
    top: -250px;
    width: 1678px;
    left: 50%;
    transform: translateX(-59%);
    z-index: 0;
    pointer-events: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.EvaluateProjects__Action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;

    @include mobile {
        gap: 20px;
    }
}

.EvaluateProjects__Button {
    height: 86px;
    width: 415px;
    font-family: $ff-bold;
    font-size: 20px;
    z-index: 2;

    @include mobile {
        height: 56px;
        width: 100%;
        font-size: 16px;

        & > .Button__Icon {
            width: 24px;
            height: 24px;
        }
    }
}

.EvaluateProjects__Action-text {
    text-align: center;
    font-family: $ff-regular;
    font-size: 20px;
    line-height: 26px;
    color: $text-black;
    opacity: 0.4;

    @include mobile {
        font-size: 13px;
        line-height: 22px;
    }

    @include tablet {
        font-size: 18px;
        line-height: 20px;
    }
}
