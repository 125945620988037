@import "@/styles/_variables.scss";
@import "@/styles/_mixins.scss";

.ModalWinner__OverflowHidden {
    overflow: hidden !important;
}

.ModalWinner {
    width: 100%;
    height: 100%;  
    position: fixed;
    left: 0;
    top: 0;
    overflow-x: hidden;
    background: #021b2f61;
    cursor: pointer;
    overflow-y: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    @include mobile {
        padding-bottom: 0;
    }
}
.ModalWinner__Container {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
    outline:none;
    @include mobile {
        padding-bottom: 0;
    }
}

.ModalWinner__Content  {
    max-width: 752px;
    width: 100%;
    border-radius: 48px;
    background-color: #fff;
    z-index: 1;
    opacity: 1;
    transform: scale(0);
    transition: transform .3s ease;
    padding: 40px;
    cursor: auto;

    
    @include mobile {
        transform: translateY(100%);
        align-self: end;
        padding: 32px;
        border-radius: 24px 24px 0px 0px;
    }
    @include tablet {
        max-width: 631px;
    }

}

.ModalWinner_CLoseBtn {
    position: absolute;
    right: 28px;
    top: 28px;
    width: 52px;
    height: 52px;
    background-image: url(./icons/close-btn.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    font-size: 0;
    background-color: transparent;
    border: none;
    @include mobile {
        width: 32px;
        height: 32px;
        right: 16px;
        top: 16px;
    }
}

.ModalWinner.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ModalWinner.ReactModal__Overlay--after-open {
    opacity: 1;
    .ModalWinner__Content {
        transform: scale(1);
        @include mobile {
            transform:translateY(0);
        }
    }
}

.ModalWinner.ReactModal__Overlay--before-close {
    opacity: 0;
    .ModalWinner__Content {
        transform: scale(0);
        @include mobile {
            transform:translateY(100%);
        }
    }
}

.ModalWinner__Name {
    margin-bottom: 32px;
    font-size: 36px;
    font-family: $ff-semiBold;
    @include mobile {
        margin-bottom: 24px;
        font-size: 24px;
    }
}

.ModalWinner__Image {
    max-width: 203px;
    img {
        max-width: 100%;
        height: auto;
    }
    @include mobile {
        max-width: 132px;
    }
}

.ModalWinner__Info {
    margin-bottom: 40px;
    border-radius: 20px;
    padding: 20px;
    background-color: $gray-2;
    font-size: 20px;
    line-height: normal;

    @include tablet {
        font-size: 18px;
   
    }
    @include mobile {
        font-size: 16px;
        margin-bottom: 32px;
    }
}

.ModalWinner__Link {
    max-width: 294px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;

    @include mobile {
        font-size: 16px;
        max-width: 257px;
    }
}
.ModalWinner__Mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
}