@import '@/styles/mixins';
@import '@/styles/variables';

.ByEmail__Header {
    & .AuthorizationFormHeader__Subtitle {
        font-size: 14px;
        white-space: break-spaces;

        @include mobile {
            white-space: unset;
            max-width: 250px
        }
    }
}

.ByEmail__Inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 160px;
}

.ByEmail__Btn {
    height: 64px;

    @include mobile {
        height: 56px;
    }
}

.ByEmail__Form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}