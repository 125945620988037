@import '@/styles/mixins';
@import '@/styles/variables';

.Events__Widgets {
    position: relative;
    padding: 120px 0;
    height: 1020px;

    @include mobile {
        padding: 16px 0 48px 0px;
        height: unset;

        & .Widget__Inner {
            display: grid;
            justify-items: center;
            align-items: start;
            height: unset;
        }
    }

    @include tablet {
        padding: 0px 0 48px 0px;
        height: unset;

        & .Widget__Inner {
            display: grid;
            justify-items: center;
            align-items: start;
            gap: 8px;
            height: unset;
        }
    }

    @include custom-screen($min: 992px, $max: 1220px) {
        width: 960px;
        height: 900px;
        margin-left: auto;
        margin-right: auto;
    }
}

.Events__Widget {
    position: absolute;
    transform: translate(0, 100px);
    opacity: 0;
    transition: .5s opacity, .5s transform;

    @include mobile {
        position: unset;
        height: 330px;
        transform: unset;
        opacity: 1;
    }

    @include tablet {
        aspect-ratio: 0.80 / 1;
        position: unset;
        transform: unset;
        opacity: 1;
    }

    .Animate & {
        opacity: 1;
        transform: translate(0, 0);
    }

    &--1 {
        left: 0;
        top: 0;

        @include custom-screen($min: 992px, $max: 1220px) {
            width: 340px !important;
            height: 340px !important;
            left: 0;
            top: 55px;
        }

        &.move {
            transform: translate(-40px, -50px);
        }
    }

    &--2 {
        left: 250px;
        top: 380px;
        transition: 1s opacity, 1s .2s transform;

        @include custom-screen($min: 992px, $max: 1220px) {
            width: 450px !important;
            height: 450px !important;
            left: 160px;
            top: 350px;
        }

        &.move {
            transform: translate(30px, 50px);
        }
    }

    &--3 {
        left: 462px;
        top: 0;
        transition: 1s opacity, 1s .4s transform;

        @include custom-screen($min: 992px, $max: 1220px) {
            width: 360px !important;
            height: 360px !important;
            left: 355px;
            top: 0;
        }

        &.move {
            transform: translate(30px, -50px);
        }
    }

    &--4 {
        right: 0;
        top: 250px;
        transition: 1s opacity, 1s .6s transform;

        @include custom-screen($min: 992px, $max: 1220px) {
            width: 340px !important;
            height: 340px !important;
            right: 10px;
            top: 290px;
        }

        &.move {
            transform: translate(60px, 10px);
        }
    }
}