@import '@/styles/mixins';
@import '@/styles/variables';

.Toastify__toast-container {
  width: initial;

  @include mobile {
    width: 100%;
    padding: 0 8px;
    bottom: 8px;
  }

  @include tablet {
    padding: 0 8px;
    bottom: 8px;
  }
}

.Toastify__toast-body {
  font-family: $ff-semiBold;
  padding: 0;
  align-items: center;
}

.Toastify__toast {
  background-color: rgba(8, 8, 8, 0.94);
  border-radius: 20px;
}

.Toastify__toast-theme--Toastify-Custome {
  padding: 20px;
  margin-bottom: 8px;

  .Toastify__toast-icon {
    width: 40px;
    height: 40px;
    margin-inline-end: 16px;
  }

  &.Toastify__toast--warning {
    width: 697px;

    @include mobile {
      width: 100%;
    }

    @include tablet {
      max-width: 100%;
    }
  }

  &.Toastify__toast--error {
    width: 560px;

    @include mobile {
      width: 100%;
    }

    @include tablet {
      max-width: 100%;
    }
  }

  &.Toastify__toast--success {
    width: 605px;

    @include mobile {
      width: 100%;
    }

    @include tablet {
      max-width: 100%;
    }
  }

  &.Toastify__toast--info {
    width: 372px;

    @include mobile {
      width: 100%;
    }

    @include tablet {
      max-width: 100%;
    }
  }

  &.Toastify__toast--warn-info {
    max-width: 605px;
    width: 100%;
    font-size: 20px;

    @include mobile {
      width: 100%;
      font-size: 16px;
    }

    @include tablet {
      max-width: 100%;
      font-size: 16px;
    }
  }
}

.Toastify__close-button--Toastify-Custome {
  align-self: center;
  margin-left: 10px;

  @include mobile {
    align-self: flex-start;
  }

  svg {
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
    height: 26px;
    width: 26px;
  }
}

.WarnIcon {
  width: 40px;
  height: 40px;

  img {
    max-width: 100%;
    width: 100%;
    display: block;
  }
}