@import '@/styles/mixins';
@import '@/styles/variables';

.ToStartBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 38px;
    width: 115px;
    border-radius: 20px;
    padding: 4px;
    background-color: $white;
    box-shadow: 0 0 12px #cbcbcb;
    border: unset;
    outline: unset;
    font-size: 14px;
    cursor: pointer; 
    transition: box-shadow 0.3s;

    &:hover {
        box-shadow: 0 0 20px #aaaaaa;
    }
}

.ToStartBtn__Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-image: $box-gradient-green;
}