@import '@/styles/mixins';
@import '@/styles/variables';

.SberInformation__inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 52px;
    margin-top: 24px;
}

.SberInformation__input {
    @include fadeIn-animation($duration: 0.3s)
}

.SberInformation__Tooltip {
    white-space: break-spaces;
    text-align: center;
    margin-top: auto;
}

.SberInformation__Btn {
    height: 64px;

    @include mobile {
        height: 56px;
    }
}