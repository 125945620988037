@import '@/styles/mixins';
@import '@/styles/variables';


.ChatFilter {
    position: relative;
    margin-left: auto;

    @include mobile {
        margin-left: unset;
    }

    @include tablet {
        margin-left: unset;
    }
}

.ChatFilter__Btn {
    display: flex;
    align-items: center;
    gap: 6px;
    border: unset;
    background-color: unset;
    outline: unset;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
}

.ChatFilter__Menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    top: calc(100% + 4px);
    right: 0;
    padding: 8px;
    min-width: 195px;
    border-radius: 20px;
    background-color: $gray-4;
    z-index: 10;
    height: 0px;
    transition: 0.3s;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    
    &--active {
        opacity: 1;
        height: 129px;
        box-shadow: 0px 0px 9px #00000042;
        pointer-events: unset;
    }

    &--open-to-right {
        right: unset;
        left: 0;
    }
}

.ChatFilter__Menu-Item {
    position: relative;
    padding: 8px 12px;
    border-radius: 60px;
    color: $text-black;
    font-size: 14px;
    list-style: none;
    cursor: pointer;

    &:hover,

    &--active {
        background-color: rgba(190, 190, 190, 0.25);
    }

    &--active {
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 12px;
            width: 13px;
            height: 10px;
            transform: translateY(-50%);
            background-image: url(./assets/check-icon.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}