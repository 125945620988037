@import '@/styles/mixins';
@import '@/styles/variables';

.Headline {
  font-family: $ff-semiBold;
  text-align: center;

  &--Gradient {
    @include text-colored($text-gradient-green)
  }

  &--light {
    color: $text-white;
  }

  &--dark {
    color: $text-black;
  }
}

h2.Headline {
  font-size: 96px;
  line-height: 100px;

  @include mobile {
    font-size: 48px;
    line-height: 52px;
  }

  @include tablet {
    font-size: 86px;
    line-height: 75px;
  }
}

h3.Headline {
  font-size: 70px;
  line-height: 78px;

  @include mobile {
    font-size: 32px;
    line-height: 36px;
  }

  @include tablet {
    font-size: 56px;
    line-height: 64px;
  }
}

h4.Headline {
  font-size: 56px;
  line-height: 64px;
  letter-spacing: -0.56px;

  @include mobile {
    font-size: 24px;
    line-height: 32px;
  }

  @include tablet {
    font-size: 40px;
    line-height: 46px;
  }
}

h6.Headline {
  font-size: 20px;
  line-height: 24px;
}