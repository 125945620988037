@import '@/styles/mixins';
@import '@/styles/variables';

.Intro-Competition {
    position: relative;
    padding-top: 164px;
    margin-bottom: 48px;
    z-index: 1;
    @include fadeIn-animation($duration: 0.7s);

    @include mobile {
        padding-top: 100px;
        margin-bottom: 34px;
    }

    @include tablet {
        padding-top: 145px;
    }
}

.Intro-Competition__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Intro-Competition__Green-Light {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.Intro-Competition__Catch-Phrase {
    @include text-colored($text-gradient-green);
    font-size: 26px;
    line-height: 34px;
    font-family: $ff-semiBold;
    text-transform: uppercase;

    @include mobile {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 12px;
    }
}

.Intro-Competition__Title {
    display: block;
    text-align: center;
    margin: unset;
    font-size: 96px;
    line-height: 100px;
    font-family: $ff-semiBold;
    text-shadow: 1px 10px 20px #ffffff21;

    @include mobile {
        font-size: 38px;
        line-height: 42px;
        white-space: break-spaces;
    }

    @include tablet {
        font-size: 50px;
        line-height: 62px;
    }

    @include custom-screen($min: 992px, $max: 1200px) {
        font-size: 70px;
        line-height: 90px;
    }

    &--gradient-white {
        @include text-colored($text-gradient-white);
    }

    &--gradient-green {
        @include text-colored($text-gradient-green)
    }

    &--closed {
        font-family: $ff-bold;
        font-size: 48px;
        line-height: 62px;

        @include mobile {
            font-size: 24px;
            line-height: 42px;
        }

        @include tablet {
            font-size: 32px;
            line-height: 54px;
        }
    }
}

.Intro-Competition__Description {
    text-align: center;
    margin-top: 24px;
    font-family: $ff-semiBold;
    font-size: 20px;
    line-height: 28px;
    white-space: break-spaces;
    color: #6a7983;
    @include mobile {
        font-size: 14px;
        line-height: 22px;
        margin-top: 12px;
        white-space: unset;
        max-width: 250px;
    }

    @include tablet {
        font-size: 18px;
        line-height: 26px;
    }

    @include custom-screen($min: 576px, $max: 670px) {
        font-size: 16px;
      
    }
}

.Intro-Competition__Underline {
    &::after {
        background-image: url(./icons/dekor-line.svg);
        height: 24%;
        bottom: -20%;
    }
}