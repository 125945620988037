@import "@/styles/_variables.scss";
@import "@/styles/_mixins.scss";

.WinnerCard {
    position: relative;
    padding: 24px;
    padding-right: 20px;
    padding-bottom: 14px;
    background-color: $white;
    border-radius: 20px;
    text-decoration: none;
    display: block;
    overflow: hidden;
    @include mobile {
        padding: 20px;
    }
}
.WinnerCard__Name {
    color: $black;
    font-size: 24px;
    margin-bottom: 12px;
}
.WinnerCard__Text {
    color: $black;
    max-height: 52px;
    font-size: 18px;
    line-height: 144.444%;
    font-style: normal;
    font-weight: 400;
    overflow: hidden;
    margin-bottom: 20px;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
    @include mobile {
        font-size: 16px;
        line-height: 21px;
    }
}

.WinnerCard__Logo {
    max-width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    img {
        max-width: 100%;
        height: auto;
    }
    &--wideLogo {
        max-width:140px;
    }
}

.WinnerCard__TopImage {
    max-width: 370px;
    img {
        max-width: 100%;
        height: auto;
    }
}
.WinnerCard__Topinfo {
    color: $white;
    background: $text-gradient-green;
    font-size: 16px;
    line-height: 26px;
    font-family: $ff-semiBold;
    top: 140px;
    left:77px;
    position: absolute;
    padding: 4px 12px;
    border-radius: 20px;
}

.WinnerCard__Top {
    position: absolute;
    right: -140px;
    top: -100px;
    z-index: 0;
    pointer-events: none;
}

.WinnerCard__Link {
    @include mobile {
        svg {
            width: 44px;
            height: 44px;
        }
    }
}