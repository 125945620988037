@import '@/styles/mixins';
@import '@/styles/variables';

.Events__Box {
    @include tablet {
        padding: unset;
        margin-bottom: 80px;
    }
}

.Events__Box-Title {
    transform: translateY(100px);
    opacity: 0;
    transition: .5s opacity, .5s transform;

    &.Animate {
        opacity: 1;
        transform: translateY(0);
    }

    @include mobile {
        white-space: break-spaces;
    }
}

.Events__Animate {
    margin-top: 60px;
    transform: translateY(100px);
    opacity: 0;
    transition: .5s opacity, .5s transform;

    &.Animate {
        opacity: 1;
        transform: translateY(0);
    }

    @include mobile {
        margin-top: 40px;
    }
}

.Events__Advantages {
    display: flex;
    flex-wrap: wrap;
    padding-top: 80px;

    @include mobile {
        flex-direction: column;
        gap: 16px;
        margin: unset;
        padding: 40px 0 48px;
    }

    @include tablet {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 20px;
        margin: unset;
        padding: 40px 0 60px;
    }
}

.Events__Advantage {
    width: 50%;
    padding: 16px;
    transform: translateY(100px);
    opacity: 0;
    transition: .5s opacity, .5s transform;

    @include mobile {
        width: 100%;
        padding: unset;
    }

    @include tablet {
        width: 100%;
        padding: unset;
    }

    .Animate & {
        opacity: 1;
        transform: translateY(0);
    }

    &:nth-child(2) {
        transition: 1s opacity, 1s .2s transform;
    }

    &:nth-child(3) {
        transition: 1s opacity, 1s .4s transform;
    }

    &:nth-child(4) {
        transition: 1s opacity, 1s .6s transform;
    }
}

.Events__Paragraph {
    margin-top: 80px;
    line-height: 38px;
    @include text-colored($text-gradient-green);

    @include mobile {
        line-height: 24px;
        margin-top: 50px;
    }

    @include tablet {
        margin-top: 60px;
        line-height: 28px;
        font-size: 18px;
    }
}