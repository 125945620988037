@import '@/styles/mixins';
@import '@/styles/variables';

.ProgramSchedule {
    position: relative;
    margin-top: 60px;
    margin-bottom: 100px;
    z-index: 1;
}

.ProgramSchedule__Decoration-02,
.ProgramSchedule__Decoration-01 {
    position: absolute;
    top: -200px;
    z-index: -1;

    @include mobile {
        display: none;
    }
}

.ProgramSchedule__Decoration-01 {
    left: 0;
}

.ProgramSchedule__Decoration-02 {
    right: 0;
}