@import '@/styles/mixins';
@import '@/styles/variables';

.Navbar {
  max-width: 391px;
  width: 100%;
  flex-shrink: 1;
  padding-right: 48px;

  @include tablet {
    max-width: 100%;
    padding-right: 0;
  }

  @include mobile {
    max-width: 100%;
    padding-right: 0;
  }

}