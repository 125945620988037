@import '@/styles/mixins';
@import '@/styles/variables';

.PinnedMessage {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 16px;
    background-color: $gray-4;
    border-top: 1px solid $gray-5;

    @include fadeIn-fromTop-animation($duration: 0.5s);
}

.PinnedMessage__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

.PinnedMessage__Header-text {
    display: grid;
    grid-template-columns: 16px 1fr;
    align-items: center;
    gap: 6px;
    color: $text-green;
    font-size: 14px;
    font-family: $ff-semiBold;
}

.PinnedMessage__Icon {
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
        opacity: 0.7;
    }

    .PinnedMessage--show & {
        transform: rotate(180deg);
    }
}

.PinnedMessage__Text {
    width: fit-content;
    font-size: 14px;
    color: $text-black;
    line-height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
    cursor: pointer;

    .PinnedMessage--show & {
        -webkit-line-clamp: unset;
    }

    &:hover {
        opacity: 0.8;
    }
}