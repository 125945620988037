@import '@/styles/mixins';
@import '@/styles/variables';

.Avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 112px;
  height: 112px;
  border-radius: 50%;
  overflow: hidden;
  background-image: $box-gradient-green;

  @include mobile {
    width: 94px;
    height: 94px;
  }
}

.Avatar__Initial {
  font-size: 36px;
  font-family: $ff-semiBold;
  line-height: 36px;
  letter-spacing: -0.36px;
  color: $white;

  @include mobile {
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.28px;
  }
}

.Avatar__Image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  background-image: $text-gradient-green;
}
