@import '@/styles/mixins';
@import '@/styles/variables';

.NavbarList {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &:after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $gray-3;
  }

  @include mobile {
    padding-bottom: 0px;
    margin-bottom: 12px;
  }
}

.NavbarList__Item {
  @include tablet {
    margin-bottom: 12px;
  }

  @include mobile {
    margin-bottom: 12px;
  }
}

.NavbarList__Link {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-family: $ff-semiBold;
  line-height: 28px;
  text-decoration: none;
  color: $headline-dark;
  padding: 14px 16px;
  border-radius: 60px;
  cursor: pointer;

  &.active {
    background: rgba(33, 186, 114, 0.06);

    &:hover {
      background: rgba(33, 186, 114, 0.06)
    }
  }

  &:hover {
    background: rgba(33, 186, 114, 0.04)
  }

  &--disabled {
    opacity: .25;
  }

  &--no-event {
    pointer-events: none;
  }

  @include tablet {
    .NavbarList__LinkIconArrow {
      display: block;
    }
  }

  @include mobile {
    font-size: 14px;
    font-family: $ff-semiBold;
    line-height: 20px;
    padding: 6px 8px;

    .NavbarList__LinkIconArrow {
      display: flex;
    }

  }

  &--Logout {
    background-color: $white;
    border: none;
    cursor: pointer;
    color: $text-gray-light;
    transition: all .3s ease;

    &:hover {
      path {
        fill: #21BA72;
        transition: all .3s ease;
      }

      color: $headline-dark;
    }
  }

}

.NavbarList__LinkIcon {
  display: flex;
  align-items: center;

  @include mobile {
    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.NavbarList__LinkIconArrow {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  @include mobile {
    right: 8px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}