@import '@/styles/mixins';
@import '@/styles/variables';


.RegistrationSelectAsync,
.RegistrationSelect {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.RegistrationSelectAsync__Label,
.RegistrationSelect__Label {
    color: $text-gray;
    font-size: 14px;
    font-weight: 700;

    &::first-letter {
        text-transform: capitalize;
    }
}

.RegistrationSelectAsync__control,
.RegistrationSelect__control {
    &:hover {
        border-color: #DFDFDF !important;
        background-color: rgb(255, 255, 255, 0.02) !important;
    }

    @include mobile {
        height: 56px !important;
    }

    .RegistrationSelect--Error &,
    .RegistrationSelectAsync--Error & {
        border-color: rgba(191, 18, 22, 0.95);
        background-color: rgba(223, 51, 55, 0.05);
    }
}

.RegistrationSelect__option {
    &:first-child {
        position: relative;
        margin-bottom: 16px;

        &::after {
            content: "";
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            border-bottom: 1px solid rgb(255, 255, 255, 0.2);
        }
    }

    &:hover {
        background-color: rgba(84, 94, 102, 0.2) !important;
    }
}

.RegistrationSelectAsync__option {
    &:hover {
        background-color: rgba(84, 94, 102, 0.2) !important;
    }
}

.RegistrationSelect__indicator {
    transition: 0.3s !important;

    .RegistrationSelect__control--menu-is-open & {
        transform: rotate(180deg);
    }
}

.RegistrationSelectAsync__menu-list,
.RegistrationSelect__menu-list {
    padding-right: 12px;
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.4) transparent;
}

.RegistrationSelectAsync__menu-list::-webkit-scrollbar,
.RegistrationSelect__menu-list::-webkit-scrollbar {
    width: 3px;
}

.RegistrationSelectAsync__menu-list::-webkit-scrollbar-thumb,
.RegistrationSelect__menu-list::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 48px;
}

.RegistrationSelectAsync__menu-list::-webkit-scrollbar-track,
.RegistrationSelect__menu-list::-webkit-scrollbar-track {
    background-color: transparent;
}