@import '@/styles/mixins';
@import '@/styles/variables';

.ToSignIn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 16px;
}

.ToSignIn__Text {
    font-size: 14px;
    color: $text-gray;
}

.ToSignIn__Link {
    font-size: 14px;
    font-family: $ff-semiBold;
    color: $text-white;
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 1px solid $gray-1;

    &:hover {
        opacity: 0.7;
    }
}