@import '@/styles/mixins';
@import '@/styles/variables';

.ModerationControls__Wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}

.ModerationControls {
    display: flex;
    align-items: center;
    border-radius: 12px;
    height: 33px;
    overflow: hidden;
}

.ModerationControls__Btn {
    padding: 8px 10px 8px 12px;
    height: 100%;
    font-size: 14px;
    flex-grow: 1;
    flex-shrink: 0;
    border: unset;
    outline: unset;
    background-color: $white;
    cursor: pointer;
    user-select: none;

    &--red {
        padding: 8px 12px 8px 10px;
        color: #F4454A;
    }

    &--rejected {
        padding: 8px 12px 8px 10px;
        background-color: #F4454A;
        color: $text-white;
        pointer-events: none;
        opacity: 0.8;
    }

    &--approved {
        padding: 8px 12px 8px 10px;
        background-color: #22a19a;
        color: $text-white;
        pointer-events: none;
        opacity: 0.8;
    }

    &--pin {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 8px 12px;
        border-radius: 12px;
    }

    &--ban {
        border-radius: 12px;
        color: $text-red;

        &:hover {
            opacity: 0.8;
        }
    }

    &--banned {
        background-color: #EE5D61 !important;
        color: $text-white;
        pointer-events: none;
    }

    &--pinned {
        background-color: #22a19a;
        color: $text-white;

        &>svg path {
            fill: white;
        }

        &:hover {
            background-color: #22a19a !important;
            opacity: 0.6;
        }
    }

    &:not(:last-of-type) {
        border-right: 1px solid #F0F0F0;
    }

    &:hover {
        background-color: rgba(244, 244, 244, 0.85);
    }

    &:active {
        transform: scale(0.95);
    }
}

.ModerationControls__Likes {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 3px;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 16px;
    background-color: $white;
    color: $text-green;
    font-size: 12px;
    user-select: none;
}