@import '@/styles/mixins';
@import '@/styles/variables';

.SliderBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 1px solid #C3CFCC;
    background: transparent;
    outline: none;
    cursor: pointer;
    color: #080808;

    @include mobile {
        width: 26px;
        height: 26px;

        &>svg {
            width: 9px;
        }
    }

    @include tablet {
        width: 50px;
        height: 50px;
    }

    &:hover {
        opacity: 0.7;
    }

    &:active {
        scale: 0.95;
    }

    &.swiper-button-disabled {
        opacity: 0.4;
        pointer-events: none;
    }
}