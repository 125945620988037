@import '@/styles/mixins';
@import '@/styles/variables';

.SocialMenuBtn {
  position: relative;
}

.SocialMenuBtn__Btn {
  display: inline-flex;
  align-items: center;
  color: #fff;
  padding: 12px 20px;
  height: 48px;
  width: 208px;
  border-radius: 48px;
  cursor: pointer;
  background: radial-gradient(rgba(84, 94, 102, 0), rgba(84, 94, 102, 0.12));
  box-shadow:
    inset 6px 0 12px rgba(#fff, 8%),
    inset 0 6px 8px rgba(#fff, 2%),
    inset -6px 0 8px rgba(#fff, 4%),
    inset 0 -6px 12px rgba(#fff, 8%);

  @include mobile {
    padding: 10px 12px;
    width: 152px;
    height: 40px;
  }

  @include tablet {
    padding: 12px 20px;
  }

  &--Open {
    .SocialMenuBtn__Icon {
      transform: rotate(0);
    }
  }
}

.SocialMenuBtn__Icon {
  transform: rotate(-180deg);
  transition: transform .2s .2s;
  pointer-events: none;
}

.SocialMenuBtn__Text {
  font-family: $ff-semiBold;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  padding: 0 8px;
  pointer-events: none;
  user-select: none;

  @include mobile {
    white-space: nowrap;
  }
}

.SocialMenuBtn__Modal {
  width: 303px;
  padding: 14px 20px;
  border-radius: 20px;
  position: absolute;
  top: calc(100% + 25px);
  right: 0;
  background-color: rgba(8, 8, 8, 0.94);
  z-index: 3;

  &>:last-child {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid rgba(217, 217, 217, .12)
  }
}