@import '@/styles/mixins';
@import '@/styles/variables';

.Footer__Top {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 4fr;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 40px;

    @include mobile {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        gap: unset;
    }

    @include tablet {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        gap: unset;
    }
}

.Footer__Top-links {
    display: flex;
    flex-direction: column;
    gap: 28px;
    list-style: none;
    white-space: nowrap;

    @include mobile {
        grid-column: 1 / 3;
        gap: 20px;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #0E3E4D;
        font-size: 14px;
    }

    @include tablet {
        grid-column: 1 / 3;
        flex-direction: row;
        gap: 52px;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid #0E3E4D;
    }
}

.Footer__Top-link {
    text-decoration: unset;
    color: $text-white;

    &:hover {
        opacity: 0.7;
    }
}

.Footer__Top-social {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include mobile {
        flex-direction: column;
        gap: 24px;
    }

    @include tablet {
        justify-content: flex-start;
        gap: 52px;
    }
}

.Footer__Top-section-title {
    font-size: 16px !important;
    letter-spacing: unset !important;
    line-height: unset !important;
    text-align: left;
    margin-bottom: 8px;

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
    }

    @include tablet {
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
    }
}

.Footer__Bottom {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 120px;
    padding: 40px 0;
    border-top: 1px solid #0E3E4D;

    & .Logo {
        opacity: 1;
        transform: unset;
        max-width: 280px;
        padding: unset;
    }

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        padding: 24px 0;
    }

    @include tablet {
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        padding: 32px 0;
    }
}

.Footer__Bottom-Items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 28px;
    list-style: none;

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        &> :first-child {
            order: 4;
        }
    }

    @include tablet {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        & .Footer__Bottom-copy-write {
            order: 3;
            user-select: none;
        }
    }
}

.Footer__Bottom-copy-write,
.Footer__Bottom-Item {
    text-decoration: unset;
    font-size: 16px;
    color: #DFDFDF;
    white-space: nowrap;

    a {
        color: inherit;
        font-size: inherit;
        text-decoration: inherit;
    }

    @include mobile {
        font-size: 14px;
    }
}

.Footer__Bottom-Item {
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.Footer__SocialItem {
    padding-top: 16px;

    &:hover {
        opacity: 0.7;
    }
}

.Footer__To-top {
    position: absolute;
    @include content-box($theme: 'dark', $radius: 12px, $padding: 16px);
    gap: 16px;
    width: 152px;
    height: 56px;
    font-size: 20px;
    font-family: $ff-semiBold;
    bottom: -20px;
    right: 0;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }

    @include mobile {
        top: 0;
        bottom: unset;
        width: 115px;
        height: 40px;
        font-size: 16px;
        gap: 10px;
    }

    @include tablet {
        top: 32px;
        bottom: unset;
    }
}
