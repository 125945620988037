@import '@/styles/mixins';
@import '@/styles/variables';

.CallToAction {
    margin-bottom: 120px;

    @include mobile {
        margin-bottom: 56px;
    }

    @include tablet {
        margin-bottom: 100px;
    }
}

.CallToAction__wrapper {
    position: relative;
    z-index: 1;
}

.CallToAction__Decoration02 {
    position: absolute;
    bottom: -140px;
    right: 0;
    filter: blur(55px);
    z-index: -1;

    @include mobile {
        bottom: -30px;
        filter: blur(10px);
        width: 100%;
    }
}

.CallToAction__Title-Wrappe {
    position: relative;
}

.CallToAction__Decoration01 {
    position: absolute;
    bottom: -330px;
    left: -30px;
    width: 400px;

    @include mobile {
        display: none;
    }

    @include tablet {
        bottom: -200px;
        left: -45px;
        width: 270px;
    }
}

.CallToAction__Title {
    white-space: break-spaces;

    @include mobile {
        white-space: unset;
        font-size: 27px !important;
        line-height: 34px !important;
    }

    @include tablet {
        font-size: 63px !important;
        line-height: 70px !important;
    }

    @include custom-screen($min: 992px, $max: 1200px) {
        font-size: 80px !important;
        line-height: 90px !important;
    }
}

.CallToAction__Title-gradient {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;

    &::after {
        top: 3px;
        bottom: -3px;
        border-radius: 24px;
        transform: rotate(358deg);
        z-index: -1;

        @include mobile {
            top: 3px;
            bottom: 3px;
            border-radius: 10px;
        }
    }
}

.CallToAction__Button {
    margin: 90px auto 0;
    width: fit-content;
    
    @include mobile {
        margin-top: 20px;
        width: 100%;
        height: 56px;
        padding: 10px;
        font-size: 16px;

        &>.Button__Icon {
            width: 24px;
            height: 24px;
        }
    }

    @include tablet {
        margin-top: 36px;
    }
}