@import '@/styles/mixins';
@import '@/styles/variables';


.PlanHeader {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 24px;

    @include mobile {
        gap: 8px;
        padding: 0 16px;
    }
}

.PlanHeader__Title {
    font-size: 28px;
    font-family: $ff-semiBold;

    &::first-letter {
        text-transform: capitalize;
    }

    @include mobile {
        font-size: 18px;
        white-space: nowrap;
    }

    &--dark {
        color: $text-white;
    }
}

.PlanHeader__Badge {
    display: none;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    height: 28px;
    border-radius: 20px;
    background-image: $box-gradient-green;
    color: $text-white;
    font-size: 12px;
    font-family: $ff-semiBold;
    text-transform: uppercase;
    white-space: nowrap;
    
    &--active {
        display: flex;
    }

    @include mobile {
        font-size: 10px;
        padding: 0 8px;
    }
}

.PlanHeader__Open-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgba(223, 223, 223, 1);
    cursor: pointer;

    @include mobile {
        width: 32px;
        height: 32px;
    }

    &:hover {
        opacity: 0.8;
    }

    .PlanHeader--dark & {
        border-color: rgba(255, 255, 255, 0.08);
    }
}

.PlanHeader__Open-icon {
    transition: 0.6s;

    .szh-accordion__item--status-entered & {
        transform: rotate(180deg);
    }
}