@import '@/styles/mixins';
@import '@/styles/variables';


.ProfileLayout {
  padding-top: 115px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;

  @include tablet {
    padding-top: 96px;
  }

  @include mobile {
    padding-top: 96px;
  }
}

.ProfileLayout__Panel {
  max-width: 1342px;
  margin: 0 auto;

  color: $headline-dark;
  padding-top: 48px;
  padding-bottom: 56px;
  background-color: $white;
  overflow: hidden;
  border-radius: 50px;

  @include tablet {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 32px;
    padding-bottom: 0;
  }

  @include mobile {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 25px;
    padding-bottom: 0;
  }
}

.ProfileLayout__PanelContainer {
  display: flex;

  @include tablet {
    display: block;
  }

  @include mobile {
    display: block;
  }
}

.ProfileLayout__Content {
  padding-left: 48px;
  position: relative;
  max-width: 805px;
  width: 100%;

  @include tablet {
    display: none;
    padding-left: 0;
    background-color: #FAFAFA;
    padding-bottom: 32px;
    max-width: 100%;

    &--visible {

      display: block;
    }
  }

  @include mobile {
    display: none;
    padding-left: 0;
    background-color: #FAFAFA;
    max-width: 100%;

    &--visible {

      display: block;
    }
  }
}





.ProfileLayout__NavbarBlock {
  position: relative;
  width: 392px;

  &::after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #F0F0F0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @include tablet {
    display: none;
    width: 100%;

    &--visible {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 32px;
      display: block;
    }

    &::after {
      display: none;
    }
  }

  @include mobile {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 27px;
    padding-top: 21px;
    width: 100%;
    display: none;

    &::after {
      display: none;
    }

    &--visible {
      display: block;
    }
  }

}

.ProfileLayout__ButtonPanelClose {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #E6E6E6;
  display: none;
  position: absolute;

  align-items: center;
  justify-content: center;
  right: 24px;
  top: 0px;
  z-index: 3;

  @include tablet {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 32px;

    &--Content {
      top: 108px;
    }
  }

  @include mobile {
    svg {
      width: 19px;
      height: 19px;
    }

    display: flex;
    top: 16px;
    width: 32px;
    height: 32px;
    right: 16px;

    &--Content {
      top: 80px;
    }
  }
}

.ProfileLayout__ProfileInfo {
  display: none;
  align-items: center;
  margin-bottom: 40px;

  @include tablet {
    display: flex;
  }

  @include mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
  }
}

.ProfileLayout__ProfileInfoName {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 24px;

  @include mobile {
    margin-left: 0;
    margin-top: 8px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
}

.ProfileLayout__NavbarTitle {
  text-align: left;
  margin: 0;
  margin-bottom: 16px;
  line-height: 42px;
  padding-left: 14px;
  font-size: 26px;

  @include tablet {
    text-align: left;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 48px;
    padding-top: 32px;
  }

  @include mobile {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-align: left;
  }
}

.ProfileLayout__Container {
  max-width: 1250px;

  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }

  @include mobile {
    padding-left: 0;
    padding-right: 0;
  }
}
