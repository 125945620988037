@import '@/styles/mixins';
@import '@/styles/variables';


.ProgramContainer__Wrapper {
    padding-right: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    @include custom-scrollbar;

    & .SessionCard {
        padding: unset;
        border-radius: unset;
    }

}

.ProgramContainer__Popup-box {
    .Popup__Box {
        @include mobile {
            left: 8px;
            right: 8px;
            width: calc(100% - 16px);
            padding: 32px 16px 14px 16px;
            border-radius: 24px;
        }
    }

    & .Popup__CloseBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fffffff0;
        border-radius: 50%;

        @include mobile {
            width: 40px;
            height: 40px;
            top: 16px;
            right: 16px;
        }
    }
}

.ProgramContainer__Popup-content {
    padding: unset;

    & .SessionHeader__Info {
        flex-direction: column;
        align-items: flex-start;
        gap: 28px;
    }

    & .SessionHeader__Reference {
        position: relative;
        width: fit-content;

        &::after {
            content: "*";
            position: absolute;
            right: -15px;
            top: -5px;
            font-size: 16px;
            font-family: $ff-thin;
        }
    }

    & .SessionSpeakers__wapper {
        grid-template-columns: 1fr;
    }
}

.ProgramContainer__Popup-text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    background-color: #FAFAFA;
    font-size: 20px;
    line-height: 28px;
    color: $text-black;
    border-radius: 20px;

    @include mobile {
        padding: 16px;
    }
}

.ProgramContainer__Popup-text-main {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & ul {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-left: 30px;
    }
}

.ProgramContainer__Reference {
    color: #12e47d;
}