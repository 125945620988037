@import "@/styles/_variables.scss";
@import "@/styles/_mixins.scss";

.PhotoCard__Popup {
    & .Popup__Box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 20px;
        aspect-ratio: 3 / 2;
        background: transparent;

        @include mobile {
            height: fit-content;
        }

        @include tablet {
            height: fit-content;
        }

        // @include desktop {
        //     width: fit-content;
        // }
    }

    & .Popup__CloseBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        top: 40px;
        right: 40px;
        background-color: $white;
        box-shadow: 0 0px 16px 2px #686868;

        @include mobile {
            top: -35px;
            right: 20px;
            width: 25px;
            height: 25px;
            box-shadow: unset;
        }

        @include tablet {
            top: -10px;
            right: 20px;
            width: 25px;
            height: 25px;
            box-shadow: unset;
        }

        & path {
            fill: $black;
        }

        & rect {
            fill: $white;
        }
    }
}

.PhotoCard__Img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.PhotoCard__Popup-Img-Container {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
}