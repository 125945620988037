@import '@/styles/mixins';
@import '@/styles/variables';

.Invitation {
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
    z-index: 1;

    @include mobile {
        padding-top: 56px;
        padding-bottom: 56px;
    }

    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.Invitation__Decoration01 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;

    @include mobile {
        left: 0;
        right: unset;
        width: 490px;
    }

    @include tablet {
        left: 0;
        right: unset;
        width: 800px;
    }
}

.Invitation__Decoration02 {
    position: absolute;
    top: calc(100% - 320px);
    right: 0;
    width: 100%;
    height: 890px;
    z-index: -1;

    @include mobile {
        top: calc(100% - 100px);
        height: 330px;
        opacity: 0.7;
    }
}

.Invitation__container {
    @include mobile {
        padding: 0 8px;
    }

    @include tablet {
        padding: 0 12px;
    }
}

.Invitation__Headline {
    margin-bottom: 80px;
    font-size: 86px !important;
    line-height: 96px !important;

    @include mobile {
        margin-bottom: 32px;
        font-size: 30px !important;
        line-height: 40px !important;
    }

    @include tablet {
        margin-left: auto;
        margin-right: auto;
        font-size: 64px !important;
        line-height: 70px !important;
    }
}

.Invitation__Info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 32px;
    margin-bottom: 60px;

    @include mobile {
        gap: 12px;
        margin-bottom: 12px;
    }

    @include tablet {
        gap: 20px;
    }
}

.Invitation__Card {
    @include content-box($theme: 'light-green', $radius: 48px, $padding: 48px);
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: $text-black;

    @include mobile {
        padding: 24px 20px;
    }

    @include tablet {
        padding: 32px;
    }
}

.Invitation__Trophy-icon {
    position: absolute;
    top: -60px;
    right: -15px;
    width: 200px;
    z-index: 2;

    @include mobile {
        top: -40px;
        right: 0px;
        width: 110px;
    }

    @include tablet {
        top: -70px;
        width: 160px;
    }
}

.Invitation__ThumbUp-icon {
    position: absolute;
    bottom: -150px;
    right: 0;
    width: 170px;
    z-index: 2;

    @include mobile {
        display: none;
    }

    @include tablet {
        bottom: -80px;
        right: 20px;
        width: 110px;
    }
}

.Invitation__Title {
    font-family: $ff-semiBold;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 26px;
    white-space: break-spaces;

    @include mobile {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 12px;
        white-space: unset;
        max-width: 260px;
    }

    @include tablet {
        font-size: 24px;
        line-height: 32px;
    }
}

.Invitation__Text {
    font-family: $ff-regular;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 16px;

    @include mobile {
        font-size: 14px;
        line-height: 22px;
        white-space: break-spaces;
    }

    @include tablet {
        font-size: 16px;
        line-height: 22px;
    }
}

.Invitation__Text-gradient {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

    &::after {
        z-index: -1;
    }
}

.Invitation__List {
    padding: unset;
    padding-left: 24px;

    @include mobile {
        padding-left: 20px;
    }

    @include tablet {
        padding-left: 20px;
    }
}

.Invitation__List-item {
    margin-bottom: 7px;
    list-style: none;
    position: relative;
    font-family: $ff-regular;
    font-size: inherit;
    line-height: inherit;

    &::before {
        content: "";
        position: absolute;
        left: -24px;
        width: 15px;
        height: 9px;
        border-bottom: 3px solid #000;
        opacity: 0.2;

        @include mobile {
            width: 11px;
            left: -20px;
            border-width: 2px;
        }

        @include tablet {
            width: 13px;
            left: -20px;
            border-width: 2px;
        }
    }
}

.Invitation__Button {
    position: relative;
    margin: 0 auto;
    justify-content: center;
    height: 86px;
    width: 523px;
    font-family: $ff-bold;
    font-size: 20px;
    z-index: 2;

    @include mobile {
        justify-content: space-between;
        width: 100%;
        height: 84px;
        font-size: 16px;
        line-height: 22px;
        border-radius: 24px;
        white-space: break-spaces;

        &>.Button__Text {
            text-align: left;
        }
    }
}