@import '@/styles/variables';
@import '@/styles/mixins';

.Refund__Form {
  @include tablet {
    background-color: $white;
    border-radius: 40px 40px 0 0;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 32px;
  }

  @include mobile {
    background-color: $white;
    border-radius: 40px 40px 0 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 24px;
  }

}

.Refund__FormWrap {
  max-width: 380px;

  @include tablet {
    max-width: 420px;
  }

  @include mobile {
    max-width: 100%;
  }
}


.Refund__Message {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
.Refund__MessageWarn {
  background-color: #fff5eb;
  font-size: 14px;
  font-style: normal;

  line-height: 20px;
  padding: 12px;
  display: flex;
  margin-bottom: 24px;
  border-radius: 20px;

  b {
    font-weight: bold;
  }

  @include tablet {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.Refund__MessageIconWrap {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.Refund__Tooltip {
  max-width: max-content;

  @include mobile {
    max-width: 100%;
  }
}

.Refund__Button {
  margin-top: 32px;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;

  &.Button--disabled {
    background: linear-gradient(103deg, #26BDED -4.75%, #31DEB6 46.35%, rgba(0, 235, 94, 0.99) 100%);
    opacity: 0.34;
    filter: none;
  }

  @include mobile {
    width: 100%;
    display: block;
    margin-top: 92px;
  }
}
