@import '@/styles/mixins';
@import '@/styles/variables';

.Popup {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 110;
}

.Popup__Box {
    position: fixed;
    bottom: 0;
    left: 0;
    min-height: 10%;
    width: 100%;
    padding: 24px;
    background-color: #fff;
    border-radius: 24px 24px 0 0;
    z-index: 111;

    .Popup--right & {
        top: 16px;
        bottom: 16px;
        right: 8px;
        left: unset;
        width: 748px;
        border-radius: 48px;
        padding: 52px 40px 32px 40px;
    }

    .Popup--center & {
        top: 50%;
        left: 50%;
        bottom: 0;
        right: 0;
        translate: -50% -50%;
        width: fit-content;
        height: fit-content;
        border-radius: 48px;
        padding: 52px 40px 32px 40px;
    }
}

.Popup__CloseBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
    
    &:active {
        scale: 0.97;
    }

    .Popup--right & {
        top: 24px;
        right: 24px;
    }
}