@import '@/styles/mixins';
@import '@/styles/variables';

.AuthorizationFormHeader {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 52px;

    @include mobile {
        gap: 8px;
    }

    @include tablet {
        margin-top: 120px;
        margin-bottom: 48px;
    }
}

.AuthorizationFormHeader__Logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    order: -1;
}

.AuthorizationFormHeader__Logo-shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.AuthorizationFormHeader__Title {
    font-size: 28px;
    line-height: 34px;
    color: $text-white;
    font-family: $ff-semiBold;
    white-space: break-spaces;
    text-align: center;
    z-index: 2;

    @include mobile {
        font-size: 26px;
    }
}

.AuthorizationFormHeader__Subtitle {
    font-size: 20px;
    line-height: 24px;
    color: $text-gray-light;
    text-align: center;
    z-index: 2;

    &--top {
        order: -1;
    }

    @include mobile {
        font-size: 16px;
    }
}