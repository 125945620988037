
@import '@/styles/mixins';
@import '@/styles/variables';

.PlanDescription {
    font-size: 16px;
    line-height: 20px;
    white-space: break-spaces;
    padding: 16px 24px 32px 24px;

    @include mobile {
        font-size: 14px;
        padding: 16px 16px 32px 16px;
    }

    &--light {
        color: $text-black;
    }

    &--dark {
        color: #F0F0F0;
    }
}