@import '@/styles/mixins';
@import '@/styles/variables';


.StreamProgram {
    margin-top: 32px;

    @include mobile {
        margin-top: 28px;
    }
}

.StreamProgram__Title {
    font-size: 40px !important;
    text-align: left;

    @include mobile {
        font-size: 20px !important;
        line-height: 28px !important;
        margin-bottom: 24px;
    }

    @include tablet {
        font-size: 36px !important;
    }
}