@import '@/styles/mixins';
@import '@/styles/variables';

.SessionSpeakers {
    padding-top: 28px;
    margin-top: 28px;
    border-top: 1px solid #F0F0F0;
}

.SessionSpeakers__wapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    align-items: center;
    gap: 40px;
    margin-top: 16px;

    .SessionSpeakers--vertical & {
        gap: 24px;
    }
}