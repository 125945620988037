@import '@/styles/mixins';
@import '@/styles/variables';

.Widget {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: transform .5s .1s;

  @include mobile {
    border-radius: 104px;
  }

  @include tablet {
    border-radius: 270px;
  }

  &--dark {
    background: $box-gradient-black;
    box-shadow: 6px 0px 20px 0px rgba(255, 255, 255, 0.20) inset, 0px 6px 6px 0px rgba(255, 255, 255, 0.02) inset, -6px 0px 6px 0px rgba(255, 255, 255, 0.04) inset, 0px -6px 12px 0px rgba(255, 255, 255, 0.06) inset;
    backdrop-filter: blur(48px);
  }

  &--light {
    background-color: $white;
    box-shadow: 6px 0px 20px 0px rgba(2, 41, 68, 0.40) inset, 0px 6px 8px 0px rgba(2, 41, 68, 0.08) inset, -6px 0px 8px 0px rgba(2, 41, 68, 0.12) inset, 0px -6px 12px 0px rgba(2, 41, 68, 0.32) inset;
  }

  &--lightGreen {
    background: $box-gradient-light-green;
    box-shadow: 6px 0px 12px 0px rgba(49, 222, 182, 0.08) inset, 0px 6px 6px 0px rgba(49, 222, 182, 0.02) inset, -6px 0px 6px 0px rgba(49, 222, 182, 0.04) inset, 0px -6px 12px 0px rgba(49, 222, 182, 0.08) inset;
  }

  &--green {
    background: $box-gradient-green;
    box-shadow: 6px 0px 12px 0px rgba(255, 255, 255, 0.32) inset, 0px 6px 6px 0px rgba(255, 255, 255, 0.32) inset, -6px 0px 6px 0px rgba(255, 255, 255, 0.32) inset, 0px -6px 12px 0px rgba(255, 255, 255, 0.32) inset;
  }
}

.Widget__Inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 60%;

  @include mobile {
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    justify-items: center;
    align-items: start;
    max-width: unset;
    padding: 10px;
  }

  @include tablet {
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    justify-items: center;
    align-items: start;
    max-width: unset;
    padding: 20px;
  }
}

.Widget__Icon {
  margin: -30px 0 0;

  @include mobile {
    align-self: end;
    margin: unset;
    width: 64px;
    height: 64px;

    &>svg {
      width: 100%;
      height: 100%;
    }
  }

  @include tablet {
    align-self: end;
    margin: unset;
    width: 80px;
    height: 80px;

    &>svg {
      width: 100%;
      height: 100%;
    }
  }
}

.Widget__Title {
  font-family: $ff-semiBold;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 8px;

  @include mobile {
    font-size: 16px;
    line-height: 22px;
    margin: unset;
  }

  @include tablet {
    font-size: 18px;
    line-height: 22px;
    margin: unset;
  }

  &--Colored {
    background-image: linear-gradient(rgba(38, 189, 237, 1), rgba(49, 222, 182, 1), rgba(0, 235, 94, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .Widget--light & {
    color: rgba(8, 8, 8, 1);
  }

  .Widget--green & {
    color: #fff;
  }

  .Widget--lightGreen & {
    color: #080808;
  }
}

.Widget__Description {
  font-family: $ff-regular;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 8px;

  .Widget--dark & {
    color: #fff;
    opacity: .4;
  }

  @include mobile {
    font-size: 12px;
    line-height: 16px;
    margin: unset;
  }

  @include tablet {
    margin: unset;
  }

  .Widget--light & {
    color: #717171;
    opacity: 1;
  }

  .Widget--lightGreen & {
    color: #080808;
    opacity: .6;
  }

  .Widget--green & {
    color: #fff;
    opacity: 1;
  }
}