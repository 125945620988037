@import '@/styles/mixins';
@import '@/styles/variables';

.PastConferenceVideo {
  position: relative;
  padding-top: 12px;
  z-index: 1;
}

.PastConferenceVideo__container {
  @include mobile {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.PastConferenceVideo__Decoration01 {
  position: absolute;
  bottom: -360px;
  right: 0;
  z-index: -1;
}

.PastConferenceVideo__Player {
  height: 644px;
  width: 100%;
  margin: 0 0 120px 0;

  @include mobile {
    height: unset;
    width: unset;
    margin: 0 0 48px 0;
    aspect-ratio: 16 / 9;

    &>.Poster {
      border-radius: 32px;
    }
  }

  @include tablet {
    height: unset;
    width: unset;
    margin: 0 0 80px 0;
    aspect-ratio: 16 / 9
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.PastConferenceVideo__Poster {
  cursor: pointer;
  position: absolute;
  pointer-events: none;
}

.PastConferenceVideo__iframe {
  width: 100%;
  height: 100%;
}

.PastConferenceVideo__Footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include mobile {
    justify-content: center;
  }
}

.PastConferenceVideo__Title {
  text-align: left;
  white-space: break-spaces;

  @include mobile {
    text-align: center;
    font-size: 18px !important;
    line-height: 26px !important;
    white-space: unset;
    max-width: 190px;
  }
}

.PastConferenceVideo__length {
  font-family: $ff-regular;
  font-size: 36px;
  line-height: 44px;
  opacity: 0.4;

  @include mobile {
    display: none;
  }

  @include tablet {
    font-size: 24px;
    line-height: 22px;
  }
}