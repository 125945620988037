.PopupImageUpload {

  .Popup__Box {
    width: 100%;
    border-radius: 0;
    background-color: transparent;
    padding: 0 8px;
    bottom: 8px;

  }

  .Popup__CloseBtn {
    display: none;
  }
}

.PopupImageUpload__Block {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 28px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 40px;
    height: 4px;
    border-radius: 20px;
    background: #D9D9D9;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
  }
}

.PopupImageUpload__Title {
  margin-bottom: 24px;
}

.PopupImageUpload__ButtonTouchDevice {
  position: relative;
  width: 100%;
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  margin-bottom: 22px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    margin-left: 10px;
    font-weight: 600;
  }
}

.PopupImageUpload__IconUploadWrap--Arrow {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}