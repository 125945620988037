@import '@/styles/mixins';
@import '@/styles/variables';

.Stages {
    position: relative;
    margin-bottom: 100px;
    z-index: 2;

    @include mobile {
        margin-bottom: 48px;
    }

    @include tablet {
        margin-bottom: 60px;
    }
}

.Stages__Decoration01 {
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.8;
    z-index: -1;

    @include mobile {
        display: none;
    }
}

.Stages__Title {
    margin-bottom: 140px;

    @include mobile {
        font-size: 32px !important;
        line-height: 34px !important;
        margin-bottom: 40px;
    }

    @include tablet {
        font-size: 64px !important;
        line-height: 70px !important;
        margin-bottom: 100px;
    }
}

.Stages__Wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    gap: 32px;

    @include mobile {
        grid-template-columns: unset;
        justify-content: unset;
        gap: 40px;
    }

    @include tablet {
        gap: 10px;
    }
}