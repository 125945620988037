.TariffStatus {
  display: flex;
  padding: 16px;
  border-radius: 20px;
  gap: 14px;
  background: rgba(#A17EC0, 0.16);
}

.TariffStatus__Icon {
  display: flex;
  flex-shrink: 0;
}

.TariffStatus__Content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  font-size: 14px;
  line-height: 20px;
}
