.UnderLiner{
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 0;
    height: 47%;
    bottom: -37%;
    z-index: 1;
    background-image: url('./underline.svg');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    transition: width .5s .5s;
  }
  &.Static {
    &:after {
      width: 100%;
    }
  }
  &.Animate {
    &:after {
      width: 100%;
      transition: width .5s 2s;
    }
  }
  .Animate &{
    &:after {
      width: 100%;
      transition: width .5s 2s;
    }
  }
}