@import '@/styles/mixins';
@import '@/styles/variables';

.OnlineHubList {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 60px;

    @include tablet {
        margin-bottom: 100px;
    }
    @include mobile {
        margin-bottom: 78px;
    }
}

.OnlineHubList__Item {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    border-radius: 48px;
    background: radial-gradient(169.31% 171.92% at 100% 0%, rgba(84, 94, 102, 0.00) 0%, rgba(84, 94, 102, 0.32) 100%);
    box-shadow: 6px 0px 28px 0px rgba(255, 255, 255, 0.08) inset, 0px 6px 8px 0px rgba(255, 255, 255, 0.02) inset, -6px 0px 8px 0px rgba(255, 255, 255, 0.02) inset, 0px -6px 8px 0px rgba(255, 255, 255, 0.08) inset;
    backdrop-filter: blur(48px);

    &:last-child {
        margin-bottom: 0;
    }

    &--ItemsStart {
        align-items: start;
    }

    @include tablet {
        flex-direction: column;
        align-items: start;
    }
    @include mobile {
        padding: 32px;
        flex-direction: column;
        align-items: start;
        border-radius: 32px;
        margin-bottom: 16px;
    }

}

.OnlineHubList__ItemTitle {
    max-width: 355px;
    width: 100%;
    font-size: 36px;
    font-style: normal;
    font-family: $ff-bold;
    line-height: 54px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(103deg, #26BDED -4.75%, #31DEB6 46.35%, rgba(0, 235, 94, 0.99) 100%);

    @include tablet {
       
        max-width: 100%;
        margin-bottom: 32px;
        font-size: 32px;
        line-height: 54px;

    }

    @include mobile {
        font-size: 20px;
        line-height: normal;
        margin-bottom: 32px;
        padding-right: 30px;
    }
}


.OnlineHubList__ItemText {
    font-size: 24px;
    font-style: normal;
    font-family: $ff-light;
    line-height: 36px;
    max-width: 521px;

    @include tablet {

        font-size: 20px;
        line-height: 30px; 

    }

    @include mobile {
        max-width: 264px;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
    }
}

.OnlineHubList__ItemTextDekorLine {
    position: relative;
    display: inline-block;
    line-height: normal;

    &::after {
        content: "";
        position: relative;
        display: block;
        width: 100%;
        height: 9px;
        bottom: 0px;
        background-position: center;
        background-repeat: no-repeat;
    }

    &--First::after {
        background-image: url(./assets/line-item-first.svg);
    }

    &--Second::after {
        background-image: url(./assets/line-item-second.svg);
    }
    &--FirstMobile1::after {
        background-image: url(./assets/line-item-first-mobile-1.svg);
    }
    &--FirstMobile2::after {
        background-image: url(./assets/line-item-first-mobile-2.svg);
    }

    @include mobile {
        height: 5px;
    }
}

