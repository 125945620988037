@import '@/styles/mixins';
@import '@/styles/variables';

.FullTimeParticipationPlan {
    overflow: hidden;
}

.FullTimeParticipationPlan__Accommodation {
    padding: 0px 24px;

    @include mobile {
        padding: 0px 16px;
    }
}

.FullTimeParticipationPlan__Info {
    margin-bottom: 32px;
    margin-right: 22px;
    margin-left: 22px;
}