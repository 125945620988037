@import '@/styles/mixins';
@import '@/styles/variables';

.BackwordBtn {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 22px;
    text-decoration: none;
    color: $text-white;
    width: fit-content;

    &:hover {
        opacity: 0.7;
    }

    @include mobile {
        font-size: 16px;
    }
}

.BackwordBtn__Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 1px solid $gray-0;
    flex-shrink: 0;

    @include mobile {
        width: 32px;
        height: 32px;
    }
}