@import '@/styles/mixins';
@import '@/styles/variables';

.FormStepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 45px;
    width: 396px;
    margin: 0 auto 24px auto;

    @include mobile {
        gap: 25px;
        width: 100%;
    }
}

.FormStepper__step {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid $gray-0;
    font-size: 14px;
    font-family: $ff-semiBold;
    color: $text-gray;
    user-select: none;
    transition: 0.3s;

    &:not(:last-of-type)::after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(100% + 8px);
        transform: translateY(-50%);
        width: 30px;
        border-bottom: 1px solid $gray-0;

        @include mobile {
            width: 15px;
        }
    }

    &--active {
        background-color: $gray-0;
        color: $text-white;
    }
}

.FormStepper__icon {
    opacity: 0;
    @include popIn-animation($duration: 0.5s, $delay: 0.2s)
}