@import '@/styles/mixins';
@import '@/styles/variables';

.ModerationStatus {
    font-size: 12px;
    color: $text-gray-light;

    &--rejected {
        color: $text-red;
    }
}