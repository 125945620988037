@import '@/styles/mixins';
@import '@/styles/variables';

.App {
  position: relative;
  background-color: $background;
  color: $text-white;
  overflow-anchor: none;
  overflow: hidden;
  z-index: 0;
}

@font-face {
  font-family: "SBSansDisplay-Regular";
  font-style: normal;
  font-weight: normal;
  /* normal | 300 | 400 | 600 | bold | etc */
  src: url("../fonts/SBSansDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SBSansDisplay";
  font-style: normal;
  font-weight: normal;
  /* normal | 300 | 400 | 600 | bold | etc */
  src: url("../fonts/SBSansDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SBSansDisplay-Bold";
  font-style: normal;
  font-weight: bold;
  /* normal | 300 | 400 | 600 | bold | etc */
  src: url("../fonts/SBSansDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SBSansDisplay";
  font-style: normal;
  font-weight: bold;
  /* normal | 300 | 400 | 600 | bold | etc */
  src: url("../fonts/SBSansDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SBSansDisplay-Light";
  font-style: normal;
  font-weight: 300;
  /* normal | 300 | 400 | 600 | bold | etc */
  src: url("../fonts/SBSansDisplay-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SBSansDisplay";
  font-style: normal;
  font-weight: 300;
  /* normal | 300 | 400 | 600 | bold | etc */
  src: url("../fonts/SBSansDisplay-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SBSansDisplay-SemiBold";
  font-style: normal;
  font-weight: 600;
  /* normal | 300 | 400 | 600 | bold | etc */
  src: url("../fonts/SBSansDisplay-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "SBSansDisplay";
  font-style: normal;
  font-weight: 600;
  /* normal | 300 | 400 | 600 | bold | etc */
  src: url("../fonts/SBSansDisplay-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "SBSansDisplay";
  font-style: normal;
  font-weight: 500;
  /* normal | 300 | 400 | 600 | bold | etc */
  src: url("../fonts/SBSansDisplay-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "SBSansDisplay-Thin";
  font-style: normal;
  font-weight: 100;
  /* normal | 300 | 400 | 600 | bold | etc */
  src: url("../fonts/SBSansDisplay-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "SBSansDisplay";
  font-style: normal;
  font-weight: 100;
  /* normal | 300 | 400 | 600 | bold | etc */
  src: url("../fonts/SBSansDisplay-Thin.ttf") format("truetype");
}
