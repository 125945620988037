@import "@styles/mixins";

.ProfilePaymentInfo {
  margin-bottom: 20px;
}

.ProfilePaymentInfo__Content {
  display: flex;
  gap: 15px;

  @include mobile {
    flex-direction: column;
  }
}

.ProfilePaymentInfo__Btn {
  display: inline-flex;
}
