@import '@/styles/mixins';
@import '@/styles/variables';

.Profile__Radio {
  color: $text-black;
  gap: 8px;

  .Radio__Description--active {
    color: inherit;
  }
}

.Profile__Input {

  .RegistrationInput__Label, .PhoneInput__Label, .RegistrationSelect__Label, .RegistrationAsyncSelect__Label {
    font-weight: 400;
  }

  .RegistrationInput__Input, .PhoneInput__Input {
    background: #FAFAFA;
    color: $black;
  }
  .PhoneInput__Input:not(.PhoneInput__Input--Error),
  .RegistrationInput__Input:not(.RegistrationInput__Input--Error) {
    border: 1px solid $gray-3;
  }

  .RegistrationSelect__control,
  .RegistrationSelectAsync__control  {
    background: #FAFAFA;
    border: 1px solid $gray-3;
    color: $black;
  }

  .RegistrationSelect__single-value,
  .RegistrationSelectAsync__single-value,
  .RegistrationSelect__input-container,
  .RegistrationSelectAsync__input-container {
    color: $black;
  }
}

.Profile__TitleLabel {
  margin-bottom: 33px;

  &--Email {
    margin-bottom: 10px;
  }

  @include mobile {
    margin-bottom: 16px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}

.Profile__FormWrapBlock {
  margin-bottom: 40px;
  max-width: 380px;

  &--Head {
    max-width: 100%;
  }

  @include tablet {
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: #fff;
    border-radius: 40px;
    margin-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    max-width: 100%;
  }

  @include mobile {
    padding-top: 20px;
    padding-bottom: 24px;
    background-color: #fff;
    border-radius: 26px;
    margin-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 100%;
  }
}

.Profile__ButtonSave {

  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;

  &.Button--disabled {
    filter: none;
    opacity: 0.34;
  }

  @include mobile {
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.ProfileBlockCheckbox {
  margin-bottom: 18px;
}

.Profile__InputWrap {
  position: relative;
  margin-bottom: 24px;
}

.Profile__ErrMessage {
  position: absolute;
  bottom: 8px;
}

.Profile__Tooltip {

  max-width: 261px;

  &::before {
    bottom: calc(100% + 10px);

  }

  &::after {
    bottom: auto;
    top: -10px;
  }

  @include tablet {
    max-width: 261px;
    margin-left: 24px;
  }

  @include mobile {
    max-width: 100%;
    margin-left: 0px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
  }
}


.Profile__FormRowBlock {
  margin-bottom: 40px;

  @include tablet {
    margin-bottom: 0;
  }

  @include mobile {
    margin-bottom: 0;
  }
}

.Profile__FormEdit {
  @include tablet {
    max-width: 100%;
  }
}
