@import '@/styles/mixins';
@import '@/styles/variables';

.Testimonials {
    @include layer(#fff);
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
    z-index: 2;

    @include mobile {
        padding-top: 56px;
        padding-bottom: 32px;
    }

    @include tablet {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.Testimonials__Wrapper {
    position: relative;

    @include mobile {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.Testimonials__Decoration01 {
    position: absolute;
    top: 0px;
    right: 150px;
    z-index: -1;

    @include mobile {
        right: 0;
        width: 600px;
    }
}

.Testimonials__Decoration02 {
    position: absolute;
    top: -40px;
    left: 50px;
    filter: blur(70px);
    z-index: -1;

    @include mobile {
        top: 0px;
        left: 0px;
        width: 430px;
        filter: unset;
    }

    @include tablet {
        display: none;
    }
}

.Testimonials__PruffmeIcon {
    position: absolute;
    top: -75px;
    left: 375px;

    @include mobile {
        top: -30px;
        left: -20px;
        width: 110px;
    }

    @include tablet {
        top: -65px;
        left: 220px;
        width: 130px;
    }

    @include custom-screen($min: 992px, $max: 1200px) {
        top: -85px;
        left: 270px;
    }
}

.Testimonials__HypermethodIcon {
    position: absolute;
    top: 165px;
    left: 60px;

    @include mobile {
        top: 20px;
        right: -40px;
        width: 120px;
        left: unset;
    }

    @include tablet {
        top: 110px;
        left: 10px;
        width: 150px;
    }

    @include custom-screen($min: 992px, $max: 1200px) {
        top: 145px;
        left: -10px;
    }
}

.Testimonials__ImperIcon {
    position: absolute;
    top: -70px;
    right: 120px;

    @include mobile {
        display: none;
    }

    @include custom-screen($min: 992px, $max: 1200px) {
        right: 15px;
    }

    @include tablet {
        top: -60px;
        right: 35px;
        width: 120px;
    }
}

.Testimonials__Title {
    margin-bottom: 80px;
    white-space: break-spaces;

    @include mobile {
        font-size: 32px !important;
        line-height: 34px !important;
        margin-bottom: 40px;
    }

    @include tablet {
        font-size: 60px !important;
        line-height: 70px !important;
        margin-bottom: 60px;
    }

    @include custom-screen($min: 992px, $max: 1200px) {
        font-size: 80px !important;
        line-height: 90px !important;
    }
}

.Testimonials__Slider {
    position: relative;

    &>.swiper {
        overflow: unset;
    }

    @include mobile {
        margin-bottom: 32px;
    }
}

.Testimonials__Slider-Next,
.Testimonials__Slider-Prev {
    position: absolute;
    top: 50%;
    cursor: pointer;
    user-select: none;
    z-index: 3;

    &:hover {
        filter: contrast(0.5) brightness(1.5);
    }

    &.swiper-button-disabled {
        visibility: hidden;
    }

    @include tablet {
        &>svg {
            width: 54px;
            height: 54px;
        }
    }
}

.Testimonials__Slider-Prev {
    rotate: 180deg;
    left: 20px;
    transform: translateY(50%);
}

.Testimonials__Slider-Next {
    right: 20px;
    transform: translateY(-50%);
}