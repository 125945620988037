@import '@/styles/mixins';
@import '@/styles/variables';

.PaymentToast {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 400px;

    @include mobile {
        width: 100%;
    }
}

.PaymentToast__Icon-container {
    position: relative;
    width: 80px;
    height: 80px;
    margin-top: 24px;
    margin-bottom: 14px;
}

.PaymentToast__Icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.PaymentToast__Shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.PaymentToast__Title {
    font-size: 24px;
    font-family: $ff-semiBold;
    white-space: break-spaces;
    text-align: center;
    line-height: 30px;
}

.PaymentToast__Text-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.PaymentToast__Text {
    font-size: 14px;
    color: #F0F0F0;
    text-align: center;
    font-family: $ff-regular;
    white-space: break-spaces;
    line-height: 20px;

    &--green {
        color: #21BA72;
    }

    &--gray {
        color: #B4B4B4;
    }

    @include mobile {
        white-space: unset;
    }
}

.PaymentToast__Btn {
    height: 48px;
    margin-top: 40px;
}

.Payment__CloseBtn {
    position: absolute;
    top: 20px;
    right: 20px;
}

.PaymentToast__Emoji {
    transform: translate(4px, 4px);
}