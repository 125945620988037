@import '@/styles/mixins';
@import '@/styles/variables';

.Tooltip {
    position: relative;

    @include tablet {
        margin-top: auto;
    }
}

.Tooltip__Body {
    position: absolute;
    padding: 8px 16px;
    border-radius: 12px;
    width: max-content;
    background-color: #373737;
    color: white;
    font-size: 12px;
    z-index: 1;
    white-space: pre;
    visibility: hidden;

    &::after {
        content: "";
        position: absolute;
        border-width: 12px;
        border-style: solid;
    }

    .Tooltip--top & {
        bottom: calc(100% + 20px);
        left: 50%;
        transform: translateX(-50%);

        &::after {
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border-color: #373737 transparent transparent transparent;
        }
    }

    .Tooltip--bottom & {
        top: calc(100% + 20px);
        left: 50%;
        transform: translateX(-50%);

        &::after {
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            border-color: transparent transparent #373737 transparent;
        }
    }

    .Tooltip--left & {
        right: calc(100% + 20px);
        top: 50%;
        transform: translateY(-50%);

        &::after {
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            border-color: transparent transparent transparent #373737;
        }
    }

    .Tooltip--right & {
        left: calc(100% + 20px);
        top: 50%;
        transform: translateY(-50%);

        &::after {
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
            border-color: transparent #373737 transparent transparent;
        }
    }

    .Tooltip--disabled & {
        visibility: hidden !important;
    }

    .Tooltip:hover & {
        visibility: visible;
    }
}
