@import '@/styles/mixins';
@import '@/styles/variables';

.Goals {
    position: relative;
    margin-bottom: 120px;
    opacity: 0;
    z-index: 2;
    @include fadeIn-animation($duration: 1s);
    animation-delay: 0.2s;
    @include tablet {
        margin-bottom: 80px;
    }
    @include mobile {
        margin-bottom: 60px;
    }
}

.Goals__Info {
    @include content-box('dark', 48px, 48px);
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @include mobile {
        padding: 24px;
        border-radius: 32px;
        padding-bottom: 15px;
    }

    @include tablet {
        padding: 24px;
        border-radius: 40px;
    }
}

.Goals__IntroList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    @include tablet {
        grid-template-columns: repeat(1, 1fr);
    }
    @include mobile {
        grid-template-columns: repeat(1, 1fr);
    }
}

.Goals__Title {
    margin: unset;
    margin-bottom: 24px;
    font-family: $ff-semiBold;
    font-size: 16px;
    text-transform: uppercase;
    opacity: 0.4;

    @include mobile {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 12px;
    }
}

.Goals__Description {
    font-family: $ff-semiBold;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 12px;

    @include mobile {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 0px;
    }

    @include tablet {
        font-size: 16px;
        line-height: 22px;
    }
}

.Goals__Description-gradient {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

    &::after {
        z-index: -1;
    }
}

.Goals__List {
    padding: unset;
    padding-left: 24px;

    @include mobile {
        padding-left: 20px;
    }

    @include tablet {
        padding-left: 20px;
    }
}

.Goals__List-item {
    margin-bottom: 7px;
    list-style: none;
    position: relative;
    font-family: $ff-semiBold;
    font-size: 20px;
    line-height: 26px;

    @include mobile {
        font-size: 14px;
        line-height: 22px;
    }

    @include tablet {
        font-size: 16px;
        line-height: 22px;
    }

    &::before {
        content: "";
        position: absolute;
        left: -24px;
        width: 15px;
        height: 13px;
        border-bottom: 3px solid #fff;
        opacity: 0.4;

        @include mobile {
            width: 11px;
            height: 10px;
            left: -20px;
            border-width: 2px;
        }

        @include tablet {
            width: 13px;
            height: 10px;
            left: -20px;
            border-width: 2px;
        }
    }
}

.Goals__Action {
    @include content-box('gradient-green', 48px, 48px);
    flex-direction: column;
    transition: 0.3s;
    text-decoration: none;

    @include mobile {
        flex-direction: row;
        border-radius: 32px;
        padding: unset;
        gap: 8px;

        &>svg {
            width: 24px;
            height: 24px;
        }
    }

    @include tablet {
        padding: 24px;
        border-radius: 40px;
    }

    &:hover {
        border-radius: 50%;
    }
}

.Goals__link {
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    line-height: 26px;
    white-space: break-spaces;

    @include mobile {
        font-size: 16px;
        line-height: 26px;
        white-space: unset;
    }
}