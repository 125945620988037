@import '@/styles/mixins';
@import '@/styles/variables';


.PopupRefreshPassword__Text-Timer {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $gray-3;
  margin-top: auto;

  span {
    border-bottom: 2px solid currentColor;
    padding-bottom: 3px;
  }

  &--Clickable {
    color: $black;
    cursor: pointer;
  }
}

.PopupRefreshPassword__Image {
  max-width: 192px;
  top: 6px;
  position: absolute;
  left: 53%;
  transform: translateX(-50%);

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.PopupRefreshPassword__Close {
  position: absolute;
  right: 20px;
  top: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  background-color: transparent;
  border: 1px solid $gray-3;
  border-radius: 50%;
  cursor: pointer;

  @include mobile {
    width: 32px;
    height: 32px;

    svg {
      width: 19px;
      height: 19px;
    }
  }
}
