@import '@/styles/mixins';
@import '@/styles/variables';

.SocialItem {
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    padding-top: 12px;
    text-decoration: none;
}

.SocialItem__Logo {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: radial-gradient(rgba(84, 94, 102, 0), rgba(84, 94, 102, 0.32));
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &>img {
        width: 100%;
    }
}

.SocialItem__Description {
    padding-left: 12px;
}

.SocialItem__Text {
    color: #fff;
    font-size: 14px;
    line-height: 20px;

    &--dark {
        color: $text-black;
    }
}

.SocialItem__Link {
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, .4);
    text-decoration: none;

    &--dark {
        color: $text-black;
        opacity: 0.4;
    }
}