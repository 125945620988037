@import '@/styles/mixins';
@import '@/styles/variables';

.PopupRefreshPassword {

  .Popup__Box {
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: 0;

    overflow-y: auto;
  }

  .Popup__CloseBtn {
    display: none;
  }

}

.PopupRefreshPassword__Wrap {
  min-height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  padding-left: 10px;

  @include mobile {

    align-items: end;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.PopupRefreshPassword__Content {
  position: relative;
  border-radius: 26px;
  max-width: 500px;
  width: 100%;
  min-height: 390px;
  background-color: #fff;
  padding: 32px;
  display: flex;
  flex-direction: column;

  @include mobile {
    border-radius: 24px 24px 0px 0px;
    padding: 24px;
    padding-bottom: 32px;
    min-height: 378px;

    &::before {
      content: "";
      display: block;
      width: 40px;
      height: 4px;
      border-radius: 20px;
      background: #D9D9D9;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 10px;
    }
  }
}