@import '@/styles/mixins';
@import '@/styles/variables';

.OnlineHubDekor__Left,
.OnlineHubDekor__Right {
        position: absolute;
        z-index: -1;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        pointer-events: none;

}

.OnlineHubDekor__Right {
    width: 320px;
    height: 322px;
    right: -140px;
    top: -46px;
    background-image: url(./assets/decor-right.png);

    @include tablet {
        width: 240px;
        height: 240px;
        right:calc(50% - 460px);
        top: 23px;
    }
    @include mobile {
        width: 180px;
        height: 180px;
        right:-71px;
        top: -123px;
    }
}

.OnlineHubDekor__Left {
    width: 200px;
    height: 200px;
    left: 10px;
    bottom: -127px;
    background-image: url(./assets/decor-left.png);

    @include tablet {
        width: 160px;
        height: 160px;
        left:calc(50% - 370px);
        bottom: -123px;
    }
    @include mobile {
        width: 100px;
        height: 100px;
        left: -60px;
        bottom: 115px;
    }
}
