@import '@/styles/mixins';
@import '@/styles/variables';

.DownloadCertificate {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-radius: 26px;
    height: 200px;
    width: 370px;
    background-color: #FAFAFA;

    @include mobile {
        width: 100%;
        height: 160px;
    }

    @include tablet {
        width: 100%;
        height: 190px;
    }
}

.DownloadCertificate__Body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.DownloadCertificate__Btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    background-color: $white;
    border: none;
    outline: none;
    cursor: pointer;

    @include desktop {
        &:hover {
            opacity: 0.6;
        }
    }
}

.DownloadCertificate__Text {
    font-size: 20px;

    @include mobile {
        font-size: 16px;
    }

    @include tablet {
        font-size: 18px;
    }
}