@import '@/styles/variables';

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  outline: none;
  border: none;
  background-color: #fff;
  box-shadow: 0px 10px 60px 0px rgb(2 41 68 / 10%);
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  user-select: none;

  &:active:enabled {
    scale: 0.99;
  }

  &--lg {
    padding: 25px 50px;
    border-radius: 60px;
    font-size: 20px;
    line-height: 26px;
  }

  &--sm {
    padding: 8px 40px;
    border-radius: 48px;
    font-size: 14px;
    line-height: 24px;
  }

  &--isWide {
    width: 100%;
  }

  &--white {
    color: rgba(8, 8, 8, 1);

    &:hover:enabled {
      box-shadow: 0px 10px 60px 0px rgba(2, 41, 68, 0.15), inset 10px -6px 20px rgb(135 142 139 / 20%);
    }
  }

  &--gradient {
    font-size: 16px;
    padding: 8px 20px;
    color: #fff;
    background: linear-gradient(110deg, #26BDED 0%, #31DEB6 48.78%, rgba(0, 235, 94, 0.99) 100%);
    box-shadow: 6px 0px 6px 0px rgba(255, 255, 255, 0.24) inset, 0px 6px 6px 0px rgba(255, 255, 255, 0.16) inset, -6px 0px 6px 0px rgba(255, 255, 255, 0.24) inset, 0px -6px 6px 0px rgba(255, 255, 255, 0.32) inset;

    &:hover:enabled {
      box-shadow: 6px 0px 20px 0px #FFF inset, 0px 6px 6px 0px rgba(255, 255, 255, 0.16) inset, -6px 0px 6px 0px rgba(255, 255, 255, 0.24) inset, 0px -6px 6px 0px rgba(255, 255, 255, 0.32) inset;
    }
  }

  &--transparent {
    color: #ffffff;
    box-shadow:
      inset 6px 0 80px 12px rgba(255, 255, 255, 8%),
      inset 0 6px 8px rgba(255, 255, 255, 2%),
      inset -6px 0 8px rgba(255, 255, 255, 4%),
      inset 0 -6px 12px rgba(255, 255, 255, 8%);
    background: radial-gradient(rgba(84, 94, 102, 0), rgba(84, 94, 102, 0.48));

    &:hover:enabled {
      box-shadow: 0px 10px 60px 0px rgba(2, 41, 68, 0.15), inset 10px -6px 20px rgb(135 142 139 / 20%);
    }
  }


  &--bordered {
    color: #ffffff;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.08);

    &:hover:enabled {
      border: 1px solid rgba(255, 255, 255, 1);
      box-shadow: none;
      background: none;
    }
  }

  &--disabled {
    filter: brightness(0.5);
  }

  &--colored-borders {
    position: relative;
    background-color: rgb(250, 250, 250);
    color: #080808;
    background-clip: padding-box;
    overflow: visible;
    box-shadow: unset;
    border: 4px solid transparent;

    &::before {
      content: "";
      position: absolute;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
      border-radius: inherit;
      background-image: $box-gradient-green;
      z-index: -1;
    }

    &:active:enabled {
      color: $text-white;
    }

    &:hover {
      color: $text-white;
      opacity: 0.9;
    }
  }
}

.Button__Text {
  font-family: $ff-semiBold;
  text-align: center;
  pointer-events: none;
}

.Button__Icon {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
